import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './ProfileEmpty.module.scss'

import { ReactComponent as UserIcon } from '@assets/icons/user_edit.svg'

export default function ProfileEmpty() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <UserIcon />
      </div>
      <p className={styles.block__text}>{t('profile.empty')}</p>
      <button className="button" onClick={() => navigate('/edit-profile')}>{t('profile.edit_profile')}</button>
    </div>
  )
}
