import { useState, useRef, useEffect } from 'react'
import { store } from '../../store'
import styles from './EditProfileScreen.module.scss'
import SecondaryLayout from '../../layouts/SecondaryLayout/SecondaryLayout'
import PageHeader from '../../components/PageHeader/PageHeader'
import PersonalDetails from '../../components/PersonalDetails/PersonalDetails'
import { useSelector } from 'react-redux'
import { ACEEPTED_IMAGE_TYPES, IMAGE_URL } from '../../constants'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { ReactComponent as DefaultImage } from '@assets/icons/default_image.svg'
import { ReactComponent as DefaultAvatar } from '@assets/icons/default_avatar.svg'

import ChangeImage from '../../components/modals/ChangeBackground/ChangeImage'
import axiosInstance from '../../api/config'
import isValidImage from '../../utils/image'
import Spinner from '../../components/Spinner/Spinner'
import useNotification from '../../hooks/useNotification'
import ToastsContainer from '../../components/Toasts/ToastsContainer/ToastsContainer'

export default function EditProfileScreen() {
  const [isOpenBackground, setIsOpenBackground] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const { dispatch } = store
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useNotification()
  const { profile } = useSelector((state: any) => state.profile)

  const handleAddImage = () => {
    inputRef.current?.click()
  }

  const handleFile = async (e: any) => {
    const file = e.target.files[0]

    if (!file) return

    const isValid = await isValidImage(file)

    if (isValid) {
      const image = URL.createObjectURL(file)

      setUploadedImage(image)
    } else {
      setIsOpenBackground(false)
      notification.imageError()

      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  const setImageModal = (value: string) => {
    setIsOpenBackground(true)
    setTypeModal(value)
  }

  const setBackgroundImage = async () => {
    const body = {
      image: '',
    }

    if (uploadedImage && inputRef?.current?.files) {
      try {
        let image = inputRef?.current?.files[0]
        const formData = new FormData()

        formData.append('image', image)

        const { data } = await axiosInstance.post(
          '/api/upload/file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (data?.data?.filename) {
          body.image = data?.data?.filename
        }
      } catch (e: any) {}
    }

    try {
      if (typeModal === 'background') {
        await axiosInstance.patch('api/users/profile/background', body)
      } else {
        await axiosInstance.patch('api/users/profile/avatar', body)
      }
      setIsOpenBackground(false)

      dispatch({ type: 'profile/getProfile' })
    } catch (e: any) {}
  }

  const takePhoto = async () => {
    await dispatch({ type: 'profile/updatePhotoType', payload: typeModal })
    navigate('/take-photo')
  }

  useEffect(() => {
    if (uploadedImage) {
      setBackgroundImage()
    }
  }, [uploadedImage])

  useEffect(() => {
    if (profile) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }, [profile])

  return (
    <div>
      <ToastsContainer bottom={116} />
      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <SecondaryLayout
          colored={true}
          header={
            <PageHeader
              edit={true}
              colored={true}
              title={t('editProfileScreen.title')}
            />
          }
        >
          <div
            className={styles.background}
            onClick={() => setImageModal('background')}
          >
            {profile?.background && (
              <img
                className={styles.background__image}
                src={`${IMAGE_URL}/${profile?.background}`}
                alt="background"
              />
            )}
            {profile?.background && (
              <div className={styles.background__cover}></div>
            )}
            <div className={styles.background__top}>
              <DefaultImage />
              <div className={styles.background__add}>
                {profile?.background
                  ? t('editProfileScreen.change_image')
                  : t('editProfileScreen.add_background')}
              </div>
            </div>
          </div>

          <div className={styles.editProfile}>
            <div
              className={styles.editProfile__avatar}
              onClick={() => setImageModal('avatar')}
            >
              <div className={styles.editProfile__avatarImage}>
                {profile?.avatar && (
                  <img
                    className={styles.editProfile__avatarImageCover}
                    src={`${IMAGE_URL}/${profile?.avatar}`}
                    alt="avatar"
                  />
                )}
                {profile?.avatar && (
                  <div className={styles.editProfile__cover}></div>
                )}
                <DefaultAvatar className={styles.editProfile__default} />
              </div>
            </div>
            <div className={styles.editProfile__details}>
              {t('editProfileScreen.personal_details')}
            </div>

            <PersonalDetails />
          </div>

          <ChangeImage
            onOpenCamera={takePhoto}
            type={typeModal}
            onAddImage={handleAddImage}
            open={isOpenBackground}
            onClose={() => setIsOpenBackground(false)}
          />

          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            accept={ACEEPTED_IMAGE_TYPES.join(',')}
            onChange={handleFile}
          />
        </SecondaryLayout>
      )}
    </div>
  )
}
