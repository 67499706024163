import styles from './ImageErrorToast.module.scss'

import { ReactComponent as GalleryError } from '@assets/icons/gallery_error.svg'

export default function ImageErrorToast() {
  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <GalleryError className={styles.block__icon} />
      </div>
      <div className={styles.block__message}>
        JPG, PNG, WebP, HEIF, min. size 320х320, up to 5mb
      </div>
    </div>
  )
}
