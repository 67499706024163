import { useTranslation } from 'react-i18next'
import styles from './StoreAbout.module.scss'
import { IMapItem } from '../../../types/map.interface'

interface IProps {
  data: IMapItem
}

export default function StoreAbout({ data }: IProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <p className={styles.block__title}>{t('map.ranking.about')}</p>
      <p className={styles.block__text}>
        {data.googleInfo?.editorial_summary?.overview || '-'}
      </p>
    </div>
  )
}
