import styles from './ForgotPasswordCodeForm.module.scss'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import Input from '../../../Input/Input'
import axiosInstance from '../../../../api/config'
import ReCAPTCHA from 'react-google-recaptcha'
const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18next.t('validation.required')).trim(),
})

interface IProps {
  ref?: any
  nextStep: () => void
  email: string
}

interface IForgotPasswordBody {
  code: string
  onlyCode: boolean
}

const EXPIRED_TIME = 60 * 5

const ForgotPasswordCodeForm = forwardRef(
  ({ nextStep, email }: IProps, ref) => {
    const { t } = useTranslation()
    const [isCanResend, setIsCanResend] = useState<boolean>(false)
    const [isPending, setIsPending] = useState(false)
    const [serverError, setServerError] = useState('')

    const recaptchaRef = useRef<any>(null)

    const expiryTimestamp = new Date()
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRED_TIME)

    const { seconds, minutes, restart } = useTimer({
      expiryTimestamp,
      onExpire: () => setIsCanResend(true),
    })

    const formik = useFormik({
      initialValues: {
        code: '',
      },
      validationSchema: CodeSchema,
      onSubmit: async (values: any) => {
        if (isPending) return

        await submitForm(values)
      },
    })

    const submitForm = async (values: any) => {
      setIsPending(true)
      setServerError('')

      try {
        let token = ''

        if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
          recaptchaRef.current?.reset()
          token = await recaptchaRef.current?.executeAsync()
        }

        const body: IForgotPasswordBody = {
          code: values.code.trim().replaceAll(/\s/g,''),
          onlyCode: true,
        }

        await axiosInstance.post(`/api/auth/forgotten/verify`, body, {
          headers: {
            recaptcha: token,
          },
        })

        setIsPending(false)
        nextStep()
      } catch (e: any) {
        setServerError(e?.response?.data?.data?.message || '')
        setIsPending(false)
      }
    }

    const numberWithLeadingZero = (x: number) => {
      return x < 10 && x >= 0 ? '0' + x : x
    }

    const resendCode = async () => {
      try {
        recaptchaRef.current?.reset()
        const token = await recaptchaRef.current?.executeAsync()

        await axiosInstance.post(
          '/api/auth/forgotten',
          {
            email,
          },
          {
            headers: {
              recaptcha: token,
            },
          },
        )

        const expiryTimestamp = new Date()
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRED_TIME)

        restart(expiryTimestamp)
        setIsCanResend(false)
      } catch (e: any) {}
    }

    return (
      <form
        ref={ref as any}
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.form__row}>
          <Input
            id="code"
            name="code"
            type="text"
            placeholder={t('verifyEmail.code_placeholder')}
            label={t('verifyEmail.code_label')}
            value={formik.values.code.trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.code}
            isPreventScroll={true}
            isError={formik.touched.code && Boolean(formik.errors.code)}
          />
        </div>
        <div className={styles.form__flex}>
          <div className={styles.form__errorContainer}>
            {serverError && <p className="form__error">{serverError}</p>}
          </div>
          {isCanResend ? (
            <div className={styles.form__timerContainer}>
              <div className={styles.form__message}>
                {t('verifyEmail.message')}
              </div>
              <button
                className={styles.form__value}
                type="button"
                onClick={resendCode}
              >
                {t('verifyEmail.resend')}
              </button>
            </div>
          ) : (
            <div className={styles.form__timerContainer}>
              <div className={styles.form__message}>
                {t('verifyEmail.send_again')}
              </div>
              <div
                className={styles.form__value}
              >{`${numberWithLeadingZero(minutes)}:${numberWithLeadingZero(seconds)}`}</div>
            </div>
          )}
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        />
      </form>
    )
  },
)

export default ForgotPasswordCodeForm
