import { Link, useNavigate } from 'react-router-dom'
import styles from './LoginForm.module.scss'
import Input from '../../Input/Input'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import { PASSWORD_REGEX } from '../../../constants/validation'
import { store } from '../../../store'
import axiosInstance from '../../../api/config'

import ReCAPTCHA from 'react-google-recaptcha'

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t('validation.invalid_email'))
    .required(i18next.t('validation.required'))
    .trim(),
  password: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
})

interface IProps {
  ref?: any
}

interface IBodyLogin {
  email: string
  password: string
  twoFactorToken?: string
  rememberMe?: boolean
}

const LoginForm = forwardRef(({}: IProps, ref) => {
  const { t } = useTranslation()
  const { dispatch } = store
  const navigate = useNavigate()
  const recaptchaRef = useRef<any>(null)

  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)

    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body: IBodyLogin = {
        email: values.email.trim().replaceAll(/\s/g,''),
        password: values.password.trim().replaceAll(/\s/g,''),
        rememberMe: true,
      }
      const response = await axiosInstance.post('/api/auth/login', body, {
        headers: {
          recaptcha: token,
        },
      })

      setIsPending(false)
      dispatch({ type: 'auth/login', payload: response.data.data.token })
      navigate('/home')
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form__row}>
        <Input
          id="email"
          name="email"
          type="text"
          placeholder={t('loginScreen.email_placeholder')}
          label={t('loginScreen.email_label')}
          value={formik.values.email.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          isPreventScroll={true}
          isError={formik.touched.email && Boolean(formik.errors.email)}
        />
      </div>
      <div className={styles.form__row}>
        <Input
          id="password"
          name="password"
          placeholder={t('loginScreen.password_placeholder')}
          label={t('loginScreen.password_label')}
          type="password"
          value={formik.values.password.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.password}
          isPreventScroll={true}
          isError={formik.touched.password && Boolean(formik.errors.password)}
        />
        <div className={styles.form__flex}>
          <div className={styles.form__errorContainer}>
            {serverError && <p className="form__error">{serverError}</p>}
          </div>
          <Link to="/forgot-password" className={styles.form__forgot}>
            {t('loginScreen.forgot_password')}
          </Link>
        </div>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      />

      <button style={{ display: 'none' }} type="submit">
        {t('loginScreen.login')}
      </button>
    </form>
  )
})

export default LoginForm
