import Avatar from '../../Avatar/Avatar'
import styles from './FeedPost.module.scss'

import { ReactComponent as DotsIcon } from '@assets/icons/dots.svg'
import { ReactComponent as LikeIcon } from '@assets/icons/like.svg'
import { ReactComponent as FilledLikeIcon } from '@assets/icons/like_filled.svg'

import { ReactComponent as CommentIcon } from '@assets/icons/comment.svg'
import { IFeedPost } from '../../../types/feed.interface'
import dayjs from 'dayjs'
import { useState } from 'react'
import ConfirmModal from '../../ConfirmModal/ConfirmModal'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IMAGE_URL } from '../../../constants'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../api/config'

interface IProps {
  post: IFeedPost
  onDelete: (id: string) => void
  isLoadingImage?: boolean
  isProfile?: boolean
}

export default function FeedPost({
  post,
  onDelete,
  isLoadingImage,
  isProfile = false,
}: IProps) {
  const { profile } = useSelector((state: any) => state.profile)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isLiked, setIsLiked] = useState(post?.isLiked)
  const [likes, setLikes] = useState(post?.likes)
  const [isPending, setIsPending] = useState(false)

  const isMyPost = profile?._id === post?.owner?._id

  const goToComments = () => {
    const container = !isProfile
      ? document.querySelector('#scrollableDiv')
      : document.querySelector('#layout_with_navigation')

    navigate('/post/' + post._id + '/comments', {
      state: {
        redirectTo: location.pathname,
        scrollTop: container?.scrollTop,
      },
    })
  }

  const toggleLike = async () => {
    if (isPending) return

    setIsPending(true)
    try {
      setIsLiked((count) => (count ? 0 : 1))

      const { data } = await axiosInstance.post(`/api/posts/${post._id}/like`)
      setLikes(data.data)
      setIsPending(false)
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const deletePost = async () => {
    setIsPending(true)
    try {
      await axiosInstance.delete(`/api/posts/${post._id}`)
      setIsOpenDeleteModal(false)
      setIsPending(false)
      onDelete(post._id)
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const transformText = (text: string) => {
    const paragraphs = text.split('\n').map((line, index) => {
      if (line) {
        return <p style={{ whiteSpace: 'pre-wrap' }} key={index}>{line}</p>
      } else {
        return <br />
      }
    });

    return paragraphs;
  }

  return (
    <div className={styles.block}>
      <div className={styles.block__header}>
        <Link
          to={`/profile/${post?.owner?._id}`}
          className={styles.block__userInfo}
        >
          <Avatar
            isLoading={isLoadingImage}
            url={
              post?.owner?.avatar ? `${IMAGE_URL}/${post?.owner?.avatar}` : ''
            }
            size={40}
          />
          <div>
            <p className={styles.block__username}>{post?.owner?.name || ''}</p>
            <p className={styles.block__time}>
              {dayjs(post.createdAt).format('MMM DD, YYYY HH:mm a')}
            </p>
          </div>
        </Link>
        {isMyPost && (
          <button
            className={styles.block__headerButton}
            type="button"
            onClick={() => setIsOpenDeleteModal(true)}
          >
            <DotsIcon />
          </button>
        )}
      </div>

      <div className={styles.block__content}>{transformText(post.text)}</div>
      {post.image && (
        <div className={styles.block__imgContainer}>
          <img src={`${IMAGE_URL}/${post.image}`} alt="Img" />
        </div>
      )}

      <div className={styles.block__footer}>
        <button
          className={styles.block__footerButton}
          type="button"
          onClick={toggleLike}
        >
          {!!isLiked ? (
            <FilledLikeIcon className={styles.block__footerButtonIcon} />
          ) : (
            <LikeIcon className={styles.block__footerButtonIcon} />
          )}
          <span>{likes}</span>
        </button>
        <button className={styles.block__footerButton} onClick={goToComments}>
          <CommentIcon className={styles.block__footerButtonIcon} />
          <span>{post.comments}</span>
        </button>
      </div>

      {isMyPost && (
        <ConfirmModal
          isOpen={isOpenDeleteModal}
          title={t('feed.delete_post_title')}
          cancelButtonTitle={t('feed.delete_post_cancel')}
          confirmButtonTitle={t('feed.delete_post_confirm')}
          onCancel={() => setIsOpenDeleteModal(false)}
          onConfirm={deletePost}
        />
      )}
    </div>
  )
}
