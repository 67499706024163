import styles from "../Auth/VerifyEmailForm/VerifyEmailForm.module.scss"
import Input from "../Input/Input"
import { useTranslation } from "react-i18next"
import {useTimer} from "react-timer-hook";

interface IProps {
  serverError?: string
  isCanResend?: boolean
  resendCode?: () => void
  formik: any
  seconds: number
  minutes: number
  setIsCanResend: (value: boolean) => void
}

export default function CodeVerify({ serverError, isCanResend, resendCode, setIsCanResend, formik, seconds, minutes }: IProps) {
  const { t } = useTranslation()

  const numberWithLeadingZero = (x: number) => {
    return x < 10 && x >= 0 ? '0' + x : x
  }

  return (
    <div>
      <div className={styles.form__row}>
        <Input
          id="code"
          name="code"
          type="text"
          placeholder={t('verifyEmail.code_placeholder')}
          label={t('verifyEmail.code_label')}
          value={formik.values.code.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.code}
          isPreventScroll={true}
          isError={formik.touched.code && Boolean(formik.errors.code)}
        />
      </div>

      <div className={styles.form__flex}>
        <div className={styles.form__errorContainer}>
          {serverError && <p className="form__error">{serverError}</p>}
        </div>

        {isCanResend ? (
          <div className={styles.form__timerContainer}>
            <div className={styles.form__message}>
              {t('verifyEmail.message')}
            </div>
            <button
              className={styles.form__value}
              type="button"
              onClick={resendCode}
            >
              {t('verifyEmail.resend')}
            </button>
          </div>
        ) : (
          <div className={styles.form__timerContainer}>
            <div className={styles.form__message}>
              {t('verifyEmail.send_again')}
            </div>
            <div
              className={styles.form__value}
            >{`${numberWithLeadingZero(minutes)}:${numberWithLeadingZero(seconds)}`}</div>
          </div>
        )}
      </div>
    </div>
  )
}