import styles from './PromoRewards.module.scss';
import { ReactComponent as GoldMedal } from "@assets/icons/gold-medal.svg";
import { ReactComponent as SilverMedal } from "@assets/icons/silver-medal.svg";
import { ReactComponent as BronzeMedal } from "@assets/icons/bronze-medal.svg";
import { ReactComponent as USDCIcon } from '@assets/icons/usd-coin.svg'
import React from 'react';

interface IProps {
  rewards: { place: number; reward: number; }[]
}

export default function PromoRewards({ rewards }: IProps) {
  return (
    <div className={styles.block}>
      <div className={styles.block__title}>Rewards</div>
      <div className={styles.block__rewards}>
        {rewards?.map((item, index) => (
          <div className={styles.block__rewards__item}>
            <div className={styles.block__rewards__win}>
              <div className={styles.block__rewards__medal}>
                {index === 0
                  ? <GoldMedal />
                  : index === 1
                    ? <SilverMedal />
                    : <BronzeMedal />}
              </div>
              <div className={styles.block__rewards__place}>{
                item?.place === 1
                  ? '1st place'
                  : item?.place === 2
                    ? '2nd place'
                    : '3rd place'
              }</div>
            </div>
            <div className={styles.block__rewards__amount}>
              <USDCIcon />
              <span className={styles.block__rewards__number}>{item.reward}</span>
              <span className={styles.block__rewards__currency}>USDC</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}