import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { forwardRef, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import axiosInstance from "../../api/config";
import CodeVerify from "../CodeVerify/CodeVerify";
import i18next from "i18next"
import styles from "../Auth/VerifyEmailForm/VerifyEmailForm.module.scss";
import Input from "../Input/Input";

const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18next.t('validation.required')).trim(),
  email: Yup.string()
    .email(i18next.t('validation.invalid_email'))
    .required(i18next.t('validation.required'))
    .trim(),
})

interface IProps {
  ref?: any
  email: string
  changeStep: () => void
  getNewEmail: (email: string, code: string) => void
  confirmation: boolean
  changeEmail: () => void
}

interface IChangeEmailBody {
  email: string
}

const EXPIRY_TIME = 60

const ChangeEmailForm = forwardRef(({ email, changeStep, getNewEmail, confirmation, changeEmail }: IProps, ref) => {
  const { t } = useTranslation()

  const [isCanResend, setIsCanResend] = useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const expiryTimestamp = new Date()
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsCanResend(true),
  })

  const formik = useFormik({
    initialValues: {
      code: '',
      email: ''
    },
    validationSchema: CodeSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)

    try {
      const body: IChangeEmailBody = {
        email: values.email.trim().replaceAll(/\s/g,'')
      }

      await axiosInstance.post(`/api/auth/change-email/${values.code.replaceAll(/\s/g,'')}`, body)
      getNewEmail(values.email, values.code.replaceAll(/\s/g,''))
      changeStep()

      setIsPending(false)
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  const sendRequest = () => {
    const expiryTimestamp = new Date()
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

    restart(expiryTimestamp)

    changeEmail()
  }

  const resendCode = async () => {
    try {
      await axiosInstance.post('/api/users/change-email-request')
      const expiryTimestamp = new Date()
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

      restart(expiryTimestamp)
      setIsCanResend(false)
    } catch (e: any) {}
  }

  return (
    <>
      {confirmation ? (
        <div className={styles.button}>
          <button type="button" className="button" onClick={sendRequest}>{t('verifyEmail.send_code')}</button>
        </div>
      ) : (
        <form
          ref={ref as any}
          className={styles.form}
          onSubmit={formik.handleSubmit}
        >
          <CodeVerify
            resendCode={resendCode}
            isCanResend={isCanResend}
            serverError={serverError}
            setIsCanResend={setIsCanResend}
            formik={formik}
            seconds={seconds}
            minutes={minutes}
          />

          <div className={styles.form__email}>
            <Input
              id="email"
              name="email"
              type="text"
              placeholder={t('verifyEmail.enter_email')}
              label={t('verifyEmail.new_email')}
              value={formik.values.email.trim()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.email}
              isError={formik.touched.email && Boolean(formik.errors.email)}
            />
          </div>
        </form>
      )}
    </>
  )
})

export default ChangeEmailForm