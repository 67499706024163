import { createModel } from '@rematch/core'
import { RootModel } from '.'
import {ITransactions, ITransactionsEntities} from "../../types/transactions.interface";

interface ITransactionsState {
  transactionsList: ITransactionsEntities[] | []
  totalPages: number | null
}

export const transactions = createModel<RootModel>()({
  state: {
    transactionsList: [],
    totalPages: null
  } as ITransactionsState,
  reducers: {
    setTransactions(state, payload: ITransactions) {
      return {
        ...state,
        transactionsList: payload.page === '1' ? payload.entities : [...state.transactionsList, ...payload.entities],
        totalPages: payload.pages
      }
    }
  },
  effects: (dispatch) => ({
    getTransactions(data: ITransactions) {
      dispatch.transactions.setTransactions(data)
    }
  })
})