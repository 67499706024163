import * as Yup from "yup"
import i18next from "i18next"
import { forwardRef, useState } from 'react'
import {useTimer} from "react-timer-hook"
import {useFormik} from "formik"
import axiosInstance from "../../api/config"
import CodeVerify from "../CodeVerify/CodeVerify"
import styles from "../Auth/VerifyEmailForm/VerifyEmailForm.module.scss";

const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18next.t('validation.required')).trim(),
})

interface IProps {
  ref?: any
  changeStep: () => void
}

const EXPIRY_TIME = 60

const VerifyPhone = forwardRef(({ changeStep }: IProps, ref) => {
  const [isCanResend, setIsCanResend] = useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const expiryTimestamp = new Date()
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsCanResend(true),
  })

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: CodeSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)

    try {
      await axiosInstance.post('/api/users/phone/verify', { verifyCode: values.code.replaceAll(/\s/g,'') })
      changeStep()

      setIsPending(false)
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  const resendCode = async () => {
    try {
      await axiosInstance.post('/api/users/phone/verify')
      const expiryTimestamp = new Date()
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

      restart(expiryTimestamp)
      setIsCanResend(false)
    } catch (e: any) {}
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <CodeVerify
        resendCode={resendCode}
        isCanResend={isCanResend}
        serverError={serverError}
        setIsCanResend={setIsCanResend}
        formik={formik}
        seconds={seconds}
        minutes={minutes}
      />
    </form>
  )
})

export default VerifyPhone