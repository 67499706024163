import { ReactComponent as ArrowIcon } from '@assets/icons/chevron.svg'
import styles from './PageHeader.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Avatar from '../Avatar/Avatar'

interface IProps {
  title?: string
  colored?: boolean
  navigateOnBack?: boolean
  onClickBack?: () => void
  avatar?: string
  edit?: boolean
  titleLink?: string
  message?: boolean
  black?: boolean
}

export default function PageHeader({
  title,
  colored = false,
  edit = false,
  navigateOnBack = true,
  onClickBack = () => {},
  avatar,
  titleLink,
  message = false,
  black = false
}: IProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const { profile } = useSelector((state: any) => state.profile)

  const back = () => {
    if (!navigateOnBack) {
      onClickBack()

      return
    }

    if (location.key === 'default') {
      navigate('/')
    } else if (edit) {
      navigate(`/profile/${profile?._id}`)
    } else {
      navigate(-1)
    }
  }

  const navigateByLink = () => {
    if (titleLink) {
      navigate(titleLink)
    }
  }

  return (
    <div className={`${styles.block} ${colored && styles.block__colored} ${black && styles.block__black}`}>
      <button className={`${styles.block__button} ${black && styles.block__button__black}`} onClick={back}>
        <ArrowIcon />
      </button>
      <div className={`${styles.block__title} ${black && styles.block__title__black}`} onClick={navigateByLink}>
        {title && title?.length > 25 ? `${title.slice(0, 25)}...` : title}
      </div>
      {avatar && !message && (
        <div className={styles.block__avatarContainer} onClick={navigateByLink}>
          <Avatar
            url={avatar ? avatar : ''}
            size={40}
            className={styles.block__avatar}
            color={colored ? 'white' : 'cream'}
          />
        </div>
      )}
      {message && (
        <div className={styles.block__avatarContainer} onClick={navigateByLink}>
          <Avatar
            url={avatar ? avatar : ''}
            size={40}
            className={styles.block__avatar}
            color={colored ? 'white' : 'cream'}
          />
        </div>
      )}
    </div>
  )
}
