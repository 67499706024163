import { createModel } from '@rematch/core'
import { IFeedPost, IPagination } from '../../types/feed.interface'
import { IFeedState } from './feed'

interface IPostsState {
  users: Record<string, IFeedState>
}

export const posts = createModel()({
  state: {
    users: {},
  } as IPostsState,
  reducers: {
    setPosts(
      state,
      payload: {
        id: string
        posts: IFeedPost[]
        pagination: IPagination
      },
    ) {
      return {
        ...state,
        users: {
          ...state.users,
          [payload.id]: {
            posts: payload.posts,
            pagination: payload.pagination,
          },
        },
      }
    },
  },
  effects: () => ({}),
})
