import { useTranslation } from 'react-i18next'
import styles from './MapPlaceCard.module.scss'

import Avatar from '../../Avatar/Avatar'
import { IMapItem } from '../../../types/map.interface'
import { IMAGE_URL } from '../../../constants'
import { formatDistance } from '../../../utils/geo'
import { Link } from 'react-router-dom'

interface IProps {
  data: IMapItem
  forList?: boolean
}

export default function MapPlaceCard({ data, forList = false }: IProps) {
  const { t } = useTranslation()

  return (
    <Link
      to={`/store/${data._id}`}
      className={`${styles.card} ${forList ? styles['card--list'] : ''}`}
    >
      <div className={styles.card__header}>
        <div className={styles.card__avatarContainer}>
          <Avatar url={data?.avatar ? `${IMAGE_URL}/${data?.avatar}` : ''} size={48} />
        </div>
        <div className={styles.card__info}>
          <div className={styles.card__nameContainer}>
            <p className={styles.card__name}>{data?.company || ''}</p>
            {forList && (
              <p className={styles.card__distance}>
                {formatDistance(data?.distance?.value)}
              </p>
            )}
          </div>
          <p className={styles.card__description}>
            {data?.googleInfo?.vicinity ||
              data?.googleInfo?.formatted_address ||
              `${data?.country}, ${data?.city}`}
          </p>
        </div>
      </div>
      <div className={styles.card__body}>
        <div className={styles.card__item}>
          <div className={styles.card__label}>{t('map.sommeliers')}</div>
          <div className={styles.card__value}>
            {data?.countSommelliers || 0}
          </div>
        </div>
      </div>
    </Link>
  )
}
