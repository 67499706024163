import { useTranslation } from 'react-i18next'
import PageHeader from '../../../components/PageHeader/PageHeader'
import SecondaryLayout from '../../../layouts/SecondaryLayout/SecondaryLayout'
import styles from './PromoRules.module.scss'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../../api/config";
import Spinner from "../../../components/Spinner/Spinner";
import { store } from "../../../store";

export default function PromoRules() {
  const [isLoading, setIsLoading] = useState(true)

  const { t } = useTranslation()
  const { promoDetails } = useSelector((state: any) => state.promo);
  const { profile } = useSelector((state: any) => state.profile)
  const { id } = useParams()
  const { dispatch } = store

  const fetchPromoDetails = async () => {
    try {
      const { data } =
        await axiosInstance.get(`/api/promos/${id}`,{
          params: {
            userId: profile?._id,
          },
        })

      dispatch({ type: 'promo/setPromoDetails', payload: data?.data })

      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (id && profile?._id) {
      fetchPromoDetails()
    }
  }, [id, profile?._id])
  
  return (
    <SecondaryLayout black={true} header={<PageHeader black={true} title={t('promo.promo_rules')} />}>
      <div className={`${styles.page__content} ${isLoading && styles.page__content__loading}`}>
        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner color="white" />
          </div>
        ) : (
          <div>
            <p className={styles.page__title}>{promoDetails?.title}</p>
            <div className={styles.page__text}>
              <div dangerouslySetInnerHTML={{ __html: promoDetails?.description }} />
            </div>
          </div>
        )}
      </div>
    </SecondaryLayout>
  )
}
