import { useEffect, useState } from 'react'
import PromoItem from '../../../components/Promo/PromoItem/PromoItem'
import { IPromo } from '../../../types/promo.interface'

import styles from './PromoList.module.scss'
import { RootState, store } from "../../../store"
import axiosInstance from "../../../api/config";
import { useSelector } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from "react-router-dom";

export default function PromoList() {
  const [isLoading, setIsLoading] = useState(false)

  const { dispatch } = store
  const location = useLocation()

  const { pagination, promos } = useSelector((state: RootState) => state.promo)

  const fetchPromo = async (page: number = 1) => {
    if (page === 1) {
      setIsLoading(true)
    }

    try {
      const { data } =
        await axiosInstance.get('/api/promos', {
          params: {
            page,
          },
        });

      dispatch({
        type: 'promo/setPromo',
        payload: {
          promos:
            page === 1
              ? data?.data[0]?.entities || []
              : [...promos, ...(data?.data[0]?.entities || [])],
          pagination: {
            page: data?.data[0]?.page || 1,
            pages: data?.data[0]?.pages || 1,
            countItems: data?.data[0]?.countItems || 0,
          },
        },
      })

      setIsLoading(false)
    } catch(e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    fetchPromo(+pagination.page + 1)
  }

  useEffect(() => {
    if (location.state?.scrollTop && promos?.length) {
      const container = document.querySelector('#scrollableDiv')

      setTimeout(() => {
        container?.scrollTo({
          top: location.state?.scrollTop,
        })
      }, 200)
    }
  }, [])

  useEffect(() => {
    fetchPromo()
  }, [])

  return (
    <div id="scrollableDiv" className={styles.block__list}>
      {isLoading ? (
        <div className={styles.block__list__spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {promos?.length > 0 ? (
            <InfiniteScroll
              dataLength={promos.length}
              next={loadMore}
              hasMore={true}
              loader={<></>}
              className={styles.page__feedContainer}
              scrollableTarget="scrollableDiv"
            >
              {promos?.map((promo: IPromo) => (
                <PromoItem
                  key={promo._id}
                  id={promo?._id}
                  description={promo?.shortDecsription}
                  reward={promo?.reward}
                  currency={promo?.currency}
                  startDate={promo?.startDate}
                  endDate={promo?.endDate}
                  resultDate={promo?.resultDate}
                  title={promo?.title}
                  sponsor={promo?.sponsor}
                  winners={promo?.winners}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  )
}
