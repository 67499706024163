import dayjs from 'dayjs'
import styles from './ChatMessage.module.scss'
import {
  EMessageType,
  IChatUser,
  IConversation,
} from '../../../types/chat.interface'
import { useIsInViewport } from '../../../hooks/useIsInViewport'
import { useEffect, useRef } from 'react'
import { IMAGE_URL } from '../../../constants'
import Spinner from '../../Spinner/Spinner'
import Avatar from "../../Avatar/Avatar";

interface IProps {
  message: IConversation
  me: IChatUser | null
  loading?: boolean
  onMessageInView: (message: IConversation) => void
  isLoadingImage?: boolean
}

export default function ChatMessage({
  message,
  me,
  onMessageInView,
  loading = false,
  isLoadingImage
}: IProps) {
  const ref = useRef(null)
  const isInViewport = useIsInViewport(ref)

  useEffect(() => {
    if (isInViewport) {
      onMessageInView(message)
    }
  }, [isInViewport])

  const transformText = (text: string) => {
    const paragraphs = text.split('\n').map((line, index) => {
      if (line) {
        return <p style={{ whiteSpace: 'pre-wrap' }} key={index}>{line}</p>
      } else {
        return <br />
      }
    });

    return paragraphs;
  }

  return (
    <div
      ref={ref}
      className={`
        ${styles.block} 
        ${(message?.user?.id || message?.user_id) === me?.id ? styles['block--owner'] : ''}
        ${message?.message?.type === EMessageType.AR_MESSAGE ? styles['block--image'] : ''}
      `}
    >
      {loading && message?.message?.type === EMessageType.TEXT_MESSAGE && (
        <div className={`${styles.block__loader}`}>
          <Spinner color="white" size={20} />
        </div>
      )}

      {message.message.type === EMessageType.TEXT_MESSAGE && (
        <div className={styles.block__content}>
          {transformText(message?.message?.text)}
        </div>
      )}

      {message?.message?.type === EMessageType.AR_MESSAGE && (
        <div className={styles.block__image}>
          <img src={`${IMAGE_URL}/${message?.message?.text || ''}`} alt="" />
        </div>
      )}

      <div className={styles.block__date}>
        {dayjs(message.updated_at).format('HH:mm')}
      </div>
    </div>
  )
}
