import { ReactNode, useState } from 'react'
import styles from './Checkbox.module.scss'

import { ReactComponent as CheckboxIcon } from '@assets/icons/checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from '@assets/icons/checkbox_checked.svg'

interface IProps {
  children?: ReactNode
  checked?: boolean
  onChange?: (isChecked: boolean) => void
}

export default function Checkbox({ children, checked, onChange }: IProps) {
  const defaultChecked = checked ? checked : false
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handleOnChange = () => {
    setIsChecked((prev) => !prev)

    if (onChange) {
      onChange(!isChecked)
    }
  }

  return (
    <div className={styles.checkbox}>
      <label className={styles.checkbox__label}>
        <div
          className={`${styles.checkbox__wrapper} ${isChecked ? styles['checkbox__wrapper--active'] : ''}`}
        >
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleOnChange}
            className={`${styles.checkbox__input} ${isChecked ? styles['checkbox__input--active'] : ''}`}
          />

          {isChecked ? (
            <CheckboxCheckedIcon className={styles.checkbox__icon} />
          ) : (
            <CheckboxIcon className={styles.checkbox__icon} />
          )}
        </div>
        <div>{children}</div>
      </label>
    </div>
  )
}
