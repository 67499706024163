import { useEffect, useState } from 'react'
import CommentItem from '../../components/Comments/CommentItem/CommentItem'
import CommentsEmpty from '../../components/Comments/CommentsEmpty/CommentsEmpty'
import CommentsFooter from '../../components/Comments/CommentsFooter/CommentsFooter'
import PageHeader from '../../components/PageHeader/PageHeader'
import { IFeedPost } from '../../types/feed.interface'

import styles from './CommentsScreen.module.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../api/config'
import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../../components/Spinner/Spinner'
import { useSelector } from 'react-redux'
import { isIOS, isMobile } from 'react-device-detect'

export default function CommentsScreen() {
  const { t } = useTranslation()
  const { profile } = useSelector((state: any) => state.profile)
  const [posts, setPosts] = useState<IFeedPost[]>([])
  const [isPending, setIsPending] = useState(false)
  const [pagination, setPagination] = useState({
    countItems: 0,
    page: 1,
    pages: 1,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    fetchComments()
  }, [])

  const sendComment = async (text: string) => {
    setIsPending(true)

    try {
      await axiosInstance.post(`/api/posts/${id}/comments`, {
        text,
      })
      setIsPending(false)
      fetchComments(1)

      window.scrollTo(0, 0)
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const fetchComments = async (page: number = 1) => {
    try {
      const { data } = await axiosInstance.get(`/api/posts/${id}/comments`, {
        params: {
          page,
        },
      })

      setPosts(
        page === 1
          ? data?.data?.entities || []
          : [...posts, ...(data?.data?.entities || [])],
      )

      setPagination({
        page: +data?.data?.page || 1,
        pages: +data?.data?.pages || 1,
        countItems: +data?.data?.countItems || 0,
      })

      setIsLoading(false)
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    fetchComments(+pagination.page + 1)
  }

  const handleDeleteComment = (id: string) => {
    setPosts(posts.filter((post) => post._id !== id))
  }

  const goBack = () => {
    if (location.state?.redirectTo) {
      navigate(location.state.redirectTo, {
        state: {
          isBack: true,
          scrollTop: location.state?.scrollTop,
        },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <div className={`${styles.page} ${isIOS && isMobile ? styles['page--ios'] : ''}`}>
      <div className={styles.page__header}>
        <PageHeader
          title={t('comments.title')}
          navigateOnBack={false}
          onClickBack={goBack}
        />
      </div>

      <div id="scrollableDiv" className={styles.page__content}>
        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {posts.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={posts.length}
                  next={loadMore}
                  hasMore={+pagination.page < +pagination.pages}
                  loader={<></>}
                  className={styles.page__feedContainer}
                  scrollableTarget="scrollableDiv"
                >
                  {posts.map((post: IFeedPost) => (
                    <CommentItem
                      isLoadingImage={isLoadingImage}
                      post={post}
                      key={post._id}
                      onDelete={handleDeleteComment}
                      postId={id as string}
                      profileId={profile?._id}
                    />
                  ))}
                </InfiniteScroll>
              </>
            ) : (
              <CommentsEmpty />
            )}
          </>
        )}
      </div>
      <div className={styles.page__footer}>
        <CommentsFooter onSend={sendComment} disabled={isPending} />
      </div>
    </div>
  )
}
