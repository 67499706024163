import styles from './CreatePostScreen.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import PostFooter from '../../components/Post/PostFooter/PostFooter'
import SecondaryLayout from '../../layouts/SecondaryLayout/SecondaryLayout'
import { useRef, useState } from 'react'

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../api/config'
import { ICreatePostBody } from '../../types/feed.interface'
import isValidImage from '../../utils/image'
import { ACEEPTED_IMAGE_TYPES } from '../../constants'
import useNotification from '../../hooks/useNotification'
import ToastsContainer from '../../components/Toasts/ToastsContainer/ToastsContainer'

export default function CreatePostScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const inputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [text, setText] = useState('')
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [isPending, setIsPending] = useState(false)

  const notification = useNotification()

  const handlePost = async () => {
    if ((!text && !uploadedImage) || isPending) return

    setIsPending(true)

    const body: ICreatePostBody = {
      text,
    }

    if (uploadedImage && inputRef?.current?.files) {
      try {
        let image = inputRef?.current?.files[0]
        const formData = new FormData()

        formData.append('image', image)

        const { data } = await axiosInstance.post(
          '/api/upload/file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (data?.data?.filename) {
          body.image = data?.data?.filename
        }
      } catch (e: any) {}
    }

    try {
      await axiosInstance.post('/api/posts', body)

      setIsPending(false)

      if (location?.state?.redirectTo) {
        navigate(location?.state?.redirectTo)
      } else {
        navigate('/home')
      }
    } catch (e: any) {
      let errorMessage = e?.response?.data?.message

      if (e?.response?.data?.data?.message === 'Profile is not filled.') {
        errorMessage = t('feed.not_filled_profile')
      }

      notification.error(errorMessage)
      setIsPending(false)
    }
  }

  const handleAddImage = () => {
    inputRef.current?.click()
  }

  const handleFile = async (e: any) => {
    const file = e.target.files[0]

    if (!file) return

    const isValid = await isValidImage(file)

    if (isValid) {
      const image = URL.createObjectURL(file)

      setUploadedImage(image)
    } else {
      notification.imageError()

      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  const removeImg = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setUploadedImage(null)
  }

  return (
    <SecondaryLayout
      header={<PageHeader title={t('feed.create_post_title')} />}
      footer={
        <PostFooter
          onPost={handlePost}
          onAddImage={handleAddImage}
          isPending={isPending}
          isImage={!!uploadedImage}
          isDisabled={!text && !uploadedImage}
        />
      }
    >
      <ToastsContainer bottom={100} />
      <div className={styles.page__content}>
        <textarea
          ref={textareaRef}
          className={styles.page__input}
          placeholder={t('feed.input_placeholder')}
          maxLength={4000}
          minLength={1}
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>

        {uploadedImage && (
          <div className={styles.page__imgContainer}>
            <button
              className={styles.page__removeImgButton}
              type="button"
              onClick={removeImg}
            >
              <CloseIcon />
            </button>
            <img src={uploadedImage} alt="" />
          </div>
        )}

        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          accept={ACEEPTED_IMAGE_TYPES.join(',')}
          onChange={handleFile}
        />
      </div>
    </SecondaryLayout>
  )
}
