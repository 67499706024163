import { useTranslation } from 'react-i18next'
import ContentContainer from '../ContentContainer/ContentContainer'
import styles from './ReceiptConfirm.module.scss'

interface IProps {
  isFirstPhoto: boolean
  photo: string
  onConfirm: () => void
  onAddAnother: () => void
  onBack: () => void
}

export default function ReceiptConfirm({
  onAddAnother,
  onConfirm,
  isFirstPhoto,
  photo,
  onBack,
}: IProps) {
  const { t } = useTranslation()

  return (
    <ContentContainer title={t('scan.receipt.title')} onBack={onBack}>
      <div className={styles.block}>
        <div className={styles.block__content}>
          <p className={styles.block__title}>
            {t('scan.receipt.confirm_title')}
          </p>

          <div className={styles.block__imgContainer}>
            <img className={styles.block__img} src={photo} alt="Receipt" />
          </div>
          {isFirstPhoto && (
            <p className={styles.block__message}>
              {t('scan.receipt.confirm_message')}
            </p>
          )}
        </div>

        <div className={styles.block__buttons}>
          {isFirstPhoto && (
            <button
              className={`button button--outline-white ${styles.block__button}`}
              type="button"
              onClick={onAddAnother}
            >
              {t('scan.receipt.add_another')}
            </button>
          )}

          <button
            className={`button button--white ${styles.block__button}`}
            type="button"
            onClick={onConfirm}
          >
            {t('scan.receipt.confirm')}
          </button>
        </div>
      </div>
    </ContentContainer>
  )
}
