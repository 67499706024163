import { useSelector } from 'react-redux'
import { IPromoWinners, IRewardsList } from '../../../types/promo.interface'
import PromoRankingItem from '../PromoRankingItem/PromoRankingItem'
import { useEffect, useState } from 'react'
import styles from '../PromoRankingItem/PromoRankingItem.module.scss'


interface IProps {
  winners?: IPromoWinners[]
  rewards?: IRewardsList[]
  count: number
  isParticipate?: boolean
}

export default function PromoRanking({ winners, rewards, count, isParticipate }: IProps) {
  const { profile } = useSelector((state: any) => state.profile)
  const [isMeWin, setIsMeWin] = useState<boolean>(false)

  useEffect(() => {
    if (winners && profile?._id) {
      const isMe = winners?.some((item: IPromoWinners) => item?.userId === profile?._id);
      setIsMeWin(isMe);
    }
  }, [winners, profile?._id]);

  if (!profile) {
    return null
  }

  return (
    <div className={styles.ranking}>
      <div className={styles.ranking__title}>Winners</div>
      {winners?.map((user: IPromoWinners, index) => (
        <PromoRankingItem
          avatar={user?.avatar}
          country={user?.country}
          index={index + 1}
          key={user.userId}
          id={user?.userId}
          name={user?.name}
          email={user?.email}
          credits={user?.credits}
          rewards={rewards}
          viewable={user?.viewable}
        />
      ))}
      {profile && !isMeWin && isParticipate && (
        <PromoRankingItem
          avatar={profile?.avatar}
          country={profile?.workPlace?.country}
          id={profile?._id}
          name={profile?.name}
          email={profile?.email}
          credits={profile?.balance?.marketing?.credits}
          index=''
        />
      )}
      <div className={styles.ranking__participants}>
        <div className={styles.ranking__participants__title}>Total number of participants</div>
        <div className={styles.ranking__participants__count}>{count}</div>
      </div>
    </div>
  )
}
