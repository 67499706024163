import { useEffect, useState } from 'react'
import styles from './MapComponent.module.scss'
import {
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'

import { ReactComponent as GeoIcon } from '@assets/icons/geo.svg'
import { ReactComponent as PinIcon } from '@assets/icons/pin.svg'

import {
  EMarkerType,
  IMapItem,
  IMapResponse,
  IPosition,
} from '../../../types/map.interface'
import { getGeoPosition } from '../../../utils/geo'

import Spinner from '../../Spinner/Spinner'
import MapPlaceCard from '../MapPlaceCard/MapPlaceCard'
import MapSommelierCard from '../MapSommelierCard/MapSommelierCard'
import PlaceMarker from '../PlaceMarker/PlaceMarker'
import SommelierMarker from '../SommelierMarker/SommerlierMarker'
import axiosInstance from '../../../api/config'
import { useSelector } from 'react-redux'
import GeoRestrictedModal from '../GeoRestrictedModal/GeoRestrictedModal'
import { store } from '../../../store'

const WORLD_BOUNDS = {
  north: 85, // North Pole
  south: -85, // South Pole
  west: -180, // Western edge of the world
  east: 180, // Eastern edge of the world
}

export default function MapComponent() {
  const [zoom, setZoom] = useState(5)
  const [isGeoPending, setIsGeoPending] = useState(false)
  const [userPosition, setUserPosition] = useState<{
    lat: number
    lng: number
  } | null>(null)
  const [selectedItem, setSelectedItem] = useState<IMapItem | null>(null)
  const [mapItems, setMapItems] = useState<IMapItem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const { isEnabled: isGeoEnabled } = useSelector((state: any) => state.geo)
  const { dispatch } = store
  const map = useMap()

  useEffect(() => {
    fetchMarkers()
  }, [])

  useEffect(() => {
    if (!map) return

    getMYGeo()
  }, [map])

  const fetchMarkers = async () => {
    try {
      const { data } = await axiosInstance.post<IMapResponse>('/api/maps', {
        distance: 10000000000000,
      })

      setMapItems(data?.data || [])

      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {}
  }

  const getMYGeo = async () => {
    if (userPosition) {
      dispatch({ type: 'geo/setIsEnabled', payload: true })
      setPositionChanges(userPosition)

      return
    }

    setIsGeoPending(true)

    try {
      const pos: IPosition = await getGeoPosition()

      if (pos) {
        dispatch({ type: 'geo/setIsEnabled', payload: true })
        setPositionChanges(pos)
      }

      setIsGeoPending(false)

      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    } catch {
      dispatch({ type: 'geo/setIsEnabled', payload: false })
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }

  const setPositionChanges = (pos: IPosition) => {
    map?.setCenter(pos)
    setZoom(15)
    setUserPosition(pos)
  }

  const selectItem = async (item: IMapItem) => {
    if (item._id === selectedItem?._id) {
      setSelectedItem(null)

      return
    }

    if (item.type === EMarkerType.STORE) {
      const { data } = await axiosInstance.get(`/api/maps/${item._id}`)

      setSelectedItem(data?.data || item)
    } else {
      setSelectedItem(item)
    }
  }

  const closeItem = () => {
    setSelectedItem(null)
  }

  if (!isGeoEnabled)
    return (
      <div className={styles.map}>
        <GeoRestrictedModal />
      </div>
    )

  return (
    <div className={styles.map}>
      {isLoading && (
        <div className={styles.map__overlay}>
          <Spinner size={40} />
        </div>
      )}
      <Map
        mapId="main_map"
        zoom={zoom}
        disableDefaultUI={true}
        defaultCenter={{ lat: 40.7, lng: -74 }}
        onBoundsChanged={closeItem}
        minZoom={1}
        restriction={{
          latLngBounds: WORLD_BOUNDS,
          strictBounds: true,
        }}
        onZoomChanged={() => setZoom(map?.getZoom() || 15)}
        clickableIcons={false}
      >
        {userPosition && (
          <AdvancedMarker
            className={styles.map__userMarker}
            position={userPosition}
          >
            {<PinIcon />}
          </AdvancedMarker>
        )}

        {mapItems.map((item: IMapItem) => {
          if (item.type === EMarkerType.SOMMELIER) {
            return (
              <AdvancedMarker
                key={item._id}
                className={styles.map__userMarker}
                position={{ lat: item.location[0], lng: item.location[1] }}
                onClick={() => selectItem(item)}
              >
                <SommelierMarker isLoadingImage={isLoadingImage} avatar={item.avatar} />
              </AdvancedMarker>
            )
          } else {
            return (
              <AdvancedMarker
                key={item._id}
                className={styles.map__userMarker}
                position={{ lat: item.location[0], lng: item.location[1] }}
                onClick={() => selectItem(item)}
              >
                <PlaceMarker
                  avatar={item.avatar}
                  count={item.countSommelliers}
                />
              </AdvancedMarker>
            )
          }
        })}
      </Map>

      <div className={styles.map__container}>
        <button className={styles.map__geoButton} onClick={getMYGeo}>
          {isGeoPending ? <Spinner size={20} color="white" /> : <GeoIcon />}
        </button>
        {!!selectedItem && (
          <>
            {selectedItem.type === EMarkerType.SOMMELIER ? (
              <MapSommelierCard data={selectedItem} />
            ) : (
              <MapPlaceCard data={selectedItem} />
            )}
          </>
        )}
      </div>
    </div>
  )
}
