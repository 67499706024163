import { useEffect, useState } from 'react'
import styles from './Avatar.module.scss'
import AppSkeleton from '../Skeleton/Skeleton'

import { ReactComponent as UserIcon } from '@assets/icons/user.svg'

interface IProps {
  url: string
  size?: number
  className?: string
  color?: 'cream' | 'white'
  disabled?: boolean
  isLoading?: boolean
  border?: boolean
  viewable?: boolean
}

export default function Avatar({
  url = '',
  size = 40,
  className = '',
  color = 'cream',
  disabled = false,
  isLoading = false,
  border = false,
  viewable = true
}: IProps) {
  const [isError, setIsError] = useState(false)
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  const [isLoadingDefault, setIsLoadingDefault] = useState(true)

  useEffect(() => {
    setIsError(false)
  }, [url])

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingDefault(false)
    }, 1000)
  }, [])

  const loadImages = () => {
    setTimeout(() => {
      setIsImgLoaded(true)
    }, 1000)
  }

  return (
    <div
      className={`${styles.block} ${disabled && styles.block__disabled} ${styles[`block--${color}`]} ${className || ''} ${border && styles.block__border}`}
      style={{ width: size, height: size }}
    >
      {!isImgLoaded && !isError && url && (
        <AppSkeleton width={size} height={size} />
      )}
      {url && !isError && (
        <img
          className={`${styles.block__img} ${!viewable && styles.block__img__blur}`}
          src={url}
          alt=""
          onError={() => setIsError(true)}
          onLoad={() => loadImages()}
          style={{ display: isImgLoaded ? 'block' : 'none' }}
        />
      )}
      {!url && isLoadingDefault ? (
        <AppSkeleton width={size} height={size} />
      ) : !url && !isLoadingDefault ? (
        <UserIcon className={`${styles.block__icon} ${!viewable && styles.block__icon__blur}`} />
      ) : null}
    </div>
  )
}
