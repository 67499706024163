import { useTranslation } from 'react-i18next'
import NavTabs from '../../../components/NavTabs/NavTabs'
import WithNavigation from '../../../layouts/WithNavigation/WithNavigation'
import { Outlet } from 'react-router-dom'

export default function MapScreen() {
  const { t } = useTranslation()

  const TABS = [
    {
      title: t('tabs.map'),
      to: '/map',
    },
    {
      title: t('tabs.ranking'),
      to: '/map/ranking',
    },
  ]

  return (
    <WithNavigation>
      <NavTabs tabs={TABS} />
      <Outlet />
    </WithNavigation>
  )
}
