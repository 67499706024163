import styles from './Transaction.module.scss'
import { ITransactionsEntities } from '../../types/transactions.interface'
import { useTranslation } from 'react-i18next'

import { ReactComponent as WithdrawIcon } from '@assets/icons/top_right_arrow.svg'
import { ReactComponent as TransactionIcon } from '@assets/icons/bottom_left_arrow.svg'
import dayjs from 'dayjs'

interface IProps {
  data: ITransactionsEntities
}

interface Types {
  WITHDRAW: string
  SIGN_UP_BONUS: string
  REFERRAL_CREDIT: string
  SALES_CREDIT: string
  PROFILE_CREDIT: string
  ADDRESS_CREDIT: string
  REFERRAL_REGISTER: string
  REPLENISH: string
  PROFILE_FILLED: string
  ADDRESS_FILLED: string
  PROMO_REWARD: string
  RECEIVE_NFT: string
}

export default function Transaction({ data }: IProps) {
  const { t } = useTranslation()

  const transactionsType: Types = {
    WITHDRAW: t('transactions.withdraw'),
    SIGN_UP_BONUS: t('transactions.sign_up_bonus'),
    REFERRAL_CREDIT: t('transactions.referral_credit'),
    SALES_CREDIT: t('transactions.sales_credit'),
    REPLENISH: t('transactions.replenish'),
    PROFILE_CREDIT: t('transactions.profile_credit'),
    ADDRESS_CREDIT: t('transactions.address_credit'),
    PROMO_REWARD: t('transactions.promo_reward'),
    RECEIVE_NFT: t('transactions.receive_nft'),
    REFERRAL_REGISTER: t('notifications.ref_registration'),
    PROFILE_FILLED: t('notifications.profile_filled'),
    ADDRESS_FILLED: t('notifications.address_filled'),
  }

  return (
    <div className={styles.transaction}>
      <div className={styles.transaction__type}>
        <div
          className={`${styles.transaction__arrow} ${data?.type !== 'WITHDRAW' && styles.transaction__arrow__top}`}
        >
          {data?.type === 'WITHDRAW' ? <WithdrawIcon /> : <TransactionIcon />}
        </div>
        <div>
          <div className={styles.transaction__title}>
            {transactionsType[data?.type as keyof Types]}
          </div>
          <div className={styles.transaction__data}>
            {dayjs(data?.createdAt).format('MMM DD, YYYY HH:mm')}
          </div>
        </div>
      </div>
      <div className={styles.transaction__amount}>
        <div className={styles.transaction__currency}>
          <span>{data?.currency}</span>
          <span>
            {data?.amount.toLocaleString('es-US', {
              minimumFractionDigits: data?.currency === 'WP' ? 2 : 4,
              maximumFractionDigits: data?.currency === 'WP' ? 2 : 4,
            })}
          </span>
        </div>
        <div className={styles.transaction__data}>
          $
          {data?.amountUSD.toLocaleString('es-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </div>
  )
}
