import { useRef, useState, useEffect } from 'react'
import styles from './ChatFooter.module.scss'

import { ReactComponent as SendIcon } from '@assets/icons/send.svg'
import { ReactComponent as GalleryIcon } from '@assets/icons/gallery_plus.svg'

import { useTranslation } from 'react-i18next'
import { EMessageType } from '../../../types/chat.interface'
import { ChatImageViewer } from '../ChatImageViewer/ChatImageViewer'
import { uploadImage } from '../../../utils/upload'
import isValidImage from '../../../utils/image'
import { ACEEPTED_IMAGE_TYPES } from '../../../constants'
import { createRange } from '../../../utils/inputs'
import useNotification from '../../../hooks/useNotification'
import { isIOS, isMobile } from 'react-device-detect'

interface IProps {
  onSend: (text: string, type: EMessageType) => void
  disabled?: boolean
  getFocus: (focus: boolean) => void
}

export default function ChatFooter({ onSend, disabled, getFocus }: IProps) {
  const { t } = useTranslation()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const notification = useNotification()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 100)
  }

  useEffect(() => {
    getFocus(isFocused)
  }, [isFocused])

  const setPosition = (targetPosition: any) => {
    const range = createRange(textareaRef.current, targetPosition)
    const selection = window.getSelection()

    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  const handleInputChange = (e: any) => {
    if (e.target.innerText && e.target.innerText?.length > 1000) {
      e.preventDefault()

      setInputValue(e.target.innerText.slice(0, 1000))
      if (textareaRef.current) {
        textareaRef.current.innerText = e.target.innerText.slice(0, 1000)
        setPosition(e.target.innerText?.length)
      }
    } else {
      setInputValue(e.target.innerText || '')
    }
  }

  const sendMessage = () => {
    onSend(inputValue, EMessageType.TEXT_MESSAGE)
    setInputValue('')

    if (textareaRef.current) {
      textareaRef.current.textContent = ''
    }
  }

  const sendImage = async () => {
    if (fileInputRef.current?.files) {
      try {
        let image = fileInputRef?.current?.files[0]

        const filename = await uploadImage(image)

        if (filename) {
          onSend(filename, EMessageType.AR_MESSAGE)

          cancelUploadImage()
        }
      } catch (e: any) {
        cancelUploadImage()
      }
    }
  }

  const handleFile = async (e: any) => {
    const file = e.target.files[0]

    if (!file) return

    const isValid = await isValidImage(file)

    if (isValid) {
      const image = URL.createObjectURL(file)

      setUploadedImage(image)
    } else {
      notification.imageError()

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleUploadImage = () => {
    fileInputRef.current?.click()
  }

  const cancelUploadImage = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = ''
    }

    setUploadedImage(null)
  }

  return (
    <div
      className={`${styles.block} ${isFocused ? styles['block--focus'] : ''} ${isIOS && isMobile && isFocused ? styles['block--ios'] : ''}`}
    >
      <span
        ref={textareaRef}
        className={styles.block__input}
        placeholder={t('chat.message')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={handleInputChange}
        contentEditable
      />

      {!isFocused && !inputValue.length && (
        <button
          className={styles.block__galleryButton}
          type="button"
          onClick={handleUploadImage}
        >
          <GalleryIcon />
        </button>
      )}

      <button
        className={`${styles.block__sendButton}`}
        disabled={!inputValue.length || disabled}
        type="button"
        onClick={sendMessage}
      >
        <SendIcon />
      </button>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept={ACEEPTED_IMAGE_TYPES.join(',')}
        onChange={handleFile}
      />

      {uploadedImage && (
        <ChatImageViewer
          image={uploadedImage}
          onClose={cancelUploadImage}
          onSend={sendImage}
        />
      )}
    </div>
  )
}
