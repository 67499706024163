export default async function isValidImage(file: File) {
  if (!file) return false
  if (!file.type.includes('image') || file.type.includes('gif')) return false
  if (file.size > 5 * 1024 * 1024) return false

  const sizes: { width: number; height: number } = await getImageSize(file)

  if (sizes.width < 320 || sizes.height < 320) return false

  return true
}

async function getImageSize(
  file: File,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()

    reader.onload = function (e) {
      var img = new Image()

      img.onload = function () {
        //@ts-ignore
        var width = this.width
        //@ts-ignore
        var height = this.height

        resolve({ width, height })
      }

      img.onerror = function () {
        reject({ width: 0, height: 0 })
      }

      //@ts-ignore
      img.src = e.target.result
    }

    reader.onerror = function () {
      reject({ width: 0, height: 0 })
    }

    reader.readAsDataURL(file)
  })
}
