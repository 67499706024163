import { ReactComponent as BackIcon } from '@assets/icons/chevron.svg'
import styles from './ScanBackButton.module.scss'

interface IProps {
  onBack: () => void
}

export default function ScanBackButton({ onBack }: IProps) {
  return (
    <button className={styles.button} onClick={onBack}>
      <BackIcon className={styles.button__icon} />
    </button>
  )
}
