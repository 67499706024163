import { useParams } from 'react-router-dom'
import StoreAbout from '../../components/Store/StoreAbout/StoreAbout'
import StoreHeader from '../../components/Store/StoreHeader/StoreHeader'
import styles from './StoreScreen.module.scss'
import axiosInstance from '../../api/config'
import { IMapItem, IMapItemResponse } from '../../types/map.interface'
import { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { useMapsLibrary } from '@vis.gl/react-google-maps'

export default function StoreScreen() {
  const { id } = useParams()
  const [item, setItem] = useState<IMapItem | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const placesLib = useMapsLibrary('places')

  useEffect(() => {
    fetchPlace()
  }, [placesLib])

  const getPlace = async (data: IMapItem) => {
    if (!placesLib || !data?.googleInfo?.place_id) {
      setItem({ ...data })
      setIsLoading(false)
      return
    }

    const service = new placesLib.PlacesService(
      document.getElementById('places_container') as HTMLDivElement,
    )

    if (data?.googleInfo?.place_id) {
      service.getDetails(
        {
          placeId: data?.googleInfo?.place_id,
        },
        (response: any) => {
          setItem({ ...data, googleInfo: response })
          setIsLoading(false)
        },
      )
    }
  }

  const fetchPlace = async () => {
    try {
      const { data } = await axiosInstance.get<IMapItemResponse>(
        `/api/maps/${id}`,
      )

      await getPlace(data.data)
    } catch (e: any) {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.page}>
      {isLoading ? (
        <div className={styles.page__loader}>
          <Spinner />
        </div>
      ) : (
        <>
          {!!item ? (
            <>
              <StoreHeader data={item} />
              {!!item?.googleInfo?.editorial_summary?.overview && (
                <StoreAbout data={item} />
              )}
            </>
          ) : null}
        </>
      )}

      <div id="places_container"></div>
    </div>
  )
}
