import styles from './SommelierLevel.module.scss'

import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'

interface IProps {
  icon: any
  name: string
  range: string
}

export default function SommelierLevel({ icon, name, range }: IProps) {
  const { t } = useTranslation()

  const { profile } = useSelector((state: any) => state.profile)

  return (
    <div className={styles.sommelier}>
      <div className={styles.sommelier__wrapper}>
        <div className={styles.sommelier__header}>
          {icon}
          <span className={styles.sommelier__name}>{name}</span>
        </div>
        {name === profile?.level && <div className={styles.sommelier__level}>{t('sommelierLevel.my_level')}</div>}
      </div>
      <div className={styles.sommelier__description}>{t('sommelierLevel.description')}</div>
      <div className={styles.sommelier__range}>{range}</div>
    </div>
  )
}