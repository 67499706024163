import styles from './FeedInput.module.scss'

import { ReactComponent as GalleryIcon } from '@assets/icons/gallery_plus.svg'
import { useSelector } from 'react-redux'
import { IMAGE_URL } from '../../../constants'
import Avatar from '../../Avatar/Avatar'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useNotification from '../../../hooks/useNotification'

interface IProps {
  placeholder: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onClick?: () => void
}

export default function FeedInput({
  placeholder,
  onChange = (e: any) => {},
  onBlur = (e: any) => {},
  onFocus = (e: any) => {},
  onClick = () => {},
}: IProps) {
  const { t } = useTranslation()
  const { profile } = useSelector((state: any) => state.profile)
  const [isLoading, setIsLoading] = useState(true)
  const notification = useNotification()

  const handleClick = () => {
    if (
      !profile?.name ||
      !profile?.phone ||
      !profile?.yearsExpirience ||
      !profile?.description
    ) {
      notification.error(t('feed.not_filled_profile'))
      return
    }

    onClick()
  }

  useEffect(() => {
    if (profile) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [profile])

  return (
    <div className={`${styles.block} `}>
      <div className={styles.block__container} onClick={handleClick}>
        <div className={styles.block__avatarContainer}>
          <Avatar
            isLoading={isLoading}
            url={profile?.avatar ? `${IMAGE_URL}/${profile?.avatar}` : ''}
            size={28}
          />
        </div>
        <input
          type="text"
          className={styles.block__input}
          placeholder={placeholder}
          autoComplete="one-time-code"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={true}
        />
        <div className={styles.block__iconContainer}>
          <GalleryIcon className={styles.block__icon} />
        </div>
      </div>
    </div>
  )
}
