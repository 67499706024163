export const IMAGE_URL = `${process.env.REACT_APP_API_URL}/api/upload/files`

export const ACEEPTED_IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'image/heif',
  'image/heic',
]
