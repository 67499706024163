import { useTranslation } from 'react-i18next'
import useNotification from '../../../hooks/useNotification'
import Invite from '../../Invite/Invite'
import ModalContainer from '../../ModalContainer/ModalContainer'

import { ReactComponent as CopiedIcon } from '@assets/icons/copy_success.svg'

interface IProps {
  open: boolean
  onClose: () => void
}

export default function InviteModal({ open, onClose }: IProps) {
  const { t } = useTranslation()
  const notification = useNotification()

  const handleCopy = () => {
    onClose()
    notification.info(t('common.link_copied'), <CopiedIcon />)
  }

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div>
        <Invite option={true} onCopy={handleCopy} />
      </div>
    </ModalContainer>
  )
}
