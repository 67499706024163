import { useTranslation } from 'react-i18next'
import { EFollowType } from '../../../types/profile.interface'
import { ReactComponent as CoffeeIcon } from '@assets/icons/coffee.svg'

import styles from './FollowersEmpty.module.scss'

interface IProps {
  type: EFollowType
}

export default function FollowersEmpty({ type }: IProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <CoffeeIcon className={styles.block__icon} />
      </div>
      <p className={styles.block__title}>{t('feed.empty_message')}</p>
    </div>
  )
}
