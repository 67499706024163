import { useState, useEffect } from 'react'
import styles from './DashboardChart.module.scss'
import { useTranslation } from "react-i18next";

import { ReactComponent as Arrow } from "@assets/icons/arrow.svg";

import ModalContainer from "../ModalContainer/ModalContainer";
import LineChart from "../LineChart/LineChart";

interface IProps {
  title: string
  getData: (typeTitle: string, type: number, range: string) => void
  keys: string[]
  values: number[]
  types?: string
}

export default function DashboardChart({ title, getData, keys, values, types = 'wines' }: IProps) {
  const { t } = useTranslation()

  const [openTypeModal, setOpenTypeModal] = useState(false)
  const [openRangeModal, setOpenRangeModal] = useState(false)
  const [typeTitle, setTypeTitle] = useState(t('dashboard.all_uppercase'))
  const [rangeTitle, setRangeTitle] = useState(t('dashboard.7_days'))
  const [type, setType] = useState(0)
  const [range, setRange] = useState('weekly')

  const typesRange = [
    {
      option: t('dashboard.all'),
      title: t('dashboard.all_uppercase'),
      color: 'all',
      type: 0
    },
    {
      option: t('dashboard.red'),
      title: t('dashboard.red'),
      color: 'red',
      type: 1
    },
    {
      option: t('dashboard.rose'),
      title: t('dashboard.rose'),
      color: 'rose',
      type: 4
    },
    {
      option: t('dashboard.white'),
      title: t('dashboard.white'),
      color: 'white',
      type: 2
    },
    {
      option: t('dashboard.sweet'),
      title: t('dashboard.sweet'),
      color: 'sweet',
      type: 7
    },
    {
      option: t('dashboard.sparkling'),
      title: t('dashboard.sparkling'),
      color: 'sparkling',
      type: 3
    },
    {
      option: t('dashboard.fortified'),
      title: t('dashboard.fortified'),
      color: 'fortified',
      type: 24
    },
  ]

  const dateRange = [
    {
      option: t('dashboard.7_days'),
      range: 'weekly'
    },
    {
      option: t('dashboard.30_days'),
      range: 'monthly'
    },
    {
      option: t('dashboard.90_days'),
      range: 'quarterly'
    },
    {
      option: t('dashboard.365_days'),
      range: 'yearly'
    },
    {
      option: t('dashboard.all_time'),
      range: 'all_time'
    }
  ]

  const setTypesOfWine = (title: string, type: number) => {
    setTypeTitle(title);
    setType(type);
    setOpenTypeModal(false);
  }

  const setRangeChart = (title: string, range: string) => {
    setRangeTitle(title);
    setRange(range);
    setOpenRangeModal(false);
  }

  useEffect(() => {
    getData(typeTitle, type, range)
  }, [type, range])

  return (
    <div className={styles.chart}>
      <div className={styles.chart__header}>
        <div className={styles.chart__title}>{title}</div>
        <div className={styles.chart__filter}>
          <div onClick={() => setOpenTypeModal(true)} className={styles['chart__filter--title']}>{typeTitle} <Arrow className={styles.chart__arrow}/></div>
          <div onClick={() => setOpenRangeModal(true)} className={`${styles['chart__filter--title']} ${styles['chart__filter--width']}`}>{rangeTitle} <Arrow className={styles.chart__arrow}/></div>
        </div>
      </div>

      <LineChart keys={keys} values={values} types={types} />

      {openTypeModal && (
        <ModalContainer isOpen={openTypeModal} onClose={() => setOpenTypeModal(false)}>
          <div>
            <div className={styles.chart__modal}>{t('dashboard.select_type')}</div>
            {typesRange.map((item) => (
              <div onClick={() => setTypesOfWine(item.title, item?.type)} className={styles.chart__options} key={item.type}>
                <div className={`${styles[`chart--${item.color}`]} ${styles.chart__round}`}></div>
                {item.option}
              </div>
            ))}
          </div>
        </ModalContainer>
      )}

      {openRangeModal && (
        <ModalContainer isOpen={openRangeModal} onClose={() => setOpenRangeModal(false)}>
          <div>
            <div className={styles.chart__modal}>{t('dashboard.select_period')}</div>
            {dateRange.map((item) => (
              <div onClick={() => setRangeChart(item.option, item.range)} className={styles.chart__options} key={item.range}>
                {item.option}
              </div>
            ))}
          </div>
        </ModalContainer>
      )}
    </div>
  )
}