import styles from './ErrorToast.module.scss'

import { ReactComponent as ErrorIcon } from '@assets/icons/close_notification.svg'

interface IProps {
  message: string
}

export default function ErrorToast({ message }: IProps) {
  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <ErrorIcon className={styles.block__icon} />
      </div>
      <div className={styles.block__message}>{message}</div>
    </div>
  )
}
