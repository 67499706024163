import { NavLink } from 'react-router-dom'
import styles from './NavTabs.module.scss'

interface IProps {
  tabs: {
    title: string
    to: string
  }[]
}

export default function NavTabs({ tabs }: IProps) {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <NavLink
          key={index}
          className={({ isActive }) =>
            `${
                isActive
                ? `${styles.tabs__tab} ${styles['tabs__tab--active']}`
                : styles.tabs__tab
            }`
          }
          replace
          to={tab.to}
          end
        >
          {tab.title}
        </NavLink>
      ))}
    </div>
  )
}
