import { IPosition } from '../types/map.interface'
const { countries, zones } = require('moment-timezone/data/meta/latest.json')

export const getGeoPosition = async () => {
  const pos: GeolocationPosition = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      maximumAge: 60000,
    })
  })

  const result: IPosition = {
    lng: pos.coords.longitude,
    lat: pos.coords.latitude,
  }

  return result
}

export const generateTimezoneToCountryMap = () => {
  const timeZoneToCountry: Record<string, string> = {}

  Object.keys(zones).forEach((z) => {
    timeZoneToCountry[z] = countries[zones[z].countries[0]].name
  })

  return timeZoneToCountry
}

export const formatDistance = (distance: number) => {
  if (distance < 0.1) {
    return `${(distance * 1000).toFixed(0)} m`
  }

  if (distance < 100) {
    return `${distance.toFixed(1)} km`
  } else {
    return `${Math.round(distance)} km`
  }
}
