import Cookies from 'universal-cookie'

const cookie = new Cookies(null)

export const setCookie = (name: string, value: string, options?: any) => {
  cookie.set(name, value, options)
}

export const getCookie = (name: string) => {
  return cookie.get(name)
}

export const removeCookie = (name: string) => {
  cookie.remove(name, {
    domain: window.location.hostname,
    path: '/',
  })
}
