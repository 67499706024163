export interface IProfileMainBalance {
  address: string
  balance: string | number
  balanceUSD: string
  currency: string
  decimals: number
}

export interface IProfileMarketingBalance {
  credits: number
  usdc: number
}

export interface IProfileBalance {
  main: IProfileMainBalance[]
  marketing: IProfileMarketingBalance
}

export interface IProfileFollowedStatus {
  isFollowed: boolean
  isFollowedSendRequest: boolean
}

export interface IProfileWorkPlace {
  country?: string
  city?: string
  company?: string
  geo?: number[]
}

export interface IProfile {
  avatar: string
  background: string
  balance: IProfileBalance
  countFollowers: number
  countFollowings: number
  createdAt: string
  email: string
  followedStatus: IProfileFollowedStatus
  level: string
  phoneConfirmed: boolean
  receiveNft: boolean
  showAchievements: boolean
  viewable: boolean
  workPlace: IProfileWorkPlace | null
  yearsExpirience: number
  _id: string
  refId: string
  referredUsers?: number
  name: string
  hasNotifications: boolean
  category?: ECategories | null
}

export interface IProfileResponse {
  data: IProfile
  status: boolean
}

export interface IAchievement {
  rank: number
  totalWineBottles: number
  totalAmount: number
}

export interface IAchievementResponse {
  data: IAchievement
  status: boolean
}

export interface ITotalStatistic {
  totalAmount: number
  rangeTotalAmount: number
}

export interface ITotalStatisticResponse {
  data: ITotalStatistic
  status: boolean
}

export interface IProfit {
  totalAmount: number
  date: string
}

export interface IProfitResponse {
  data: IProfit[]
  status: boolean
}

export interface IWinesSale {
  saleCount: number
  date: string
}

export interface IWinesSaleResponse {
  data: IWinesSale[]
  status: boolean
}

export enum EFollowType {
  FOLLOWERS = 'followers',
  FOLLOWINGS = 'followings',
  REQUESTS = 'requests',
  MY_REQUESTS = 'my_requests',
}

export interface IFollowParams {
  userId?: string
  page: number
  limit: number
  type: EFollowType
}

export interface IFollowResponse {
  data: {
    page: number
    pages: number
    countItems: number
    entities: any
  }
}

export interface IFollower {
  createdAt: string
  isFollowing?: 0 | 1
  _id: string
  user: {
    avatar: string
    email: string
    name: string
    _id: string
  }
}

export enum ECategories {
  SOMMELIER = 'sommelier',
  CAVISTE = 'caviste',
  WAITER = 'waiter',
  WINE_LOVER = 'wine_lover',
  PRODUCER = 'producer',
  WINEMAKER = 'winemaker',
  GRAPE_GROWER = 'grape_grower',
  EDUCATION = 'education',
  ASSOCIATIONS = 'associations',
  PRESS = 'press',
  INFLUENCER = 'influencer',
  DISTRIBUTOR = 'distributor',
  MARKETER = 'marketer',
  INVESTORS = 'investors',
  OTHERS = 'others',
}