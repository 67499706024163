import styles from "../ChangeEmailForm/ChangeEmail.module.scss";
import { ReactComponent as Success } from "@assets/icons/success.svg";
import { useTranslation } from "react-i18next";

export default function VerifyPhoneSuccess() {
  const { t } = useTranslation()

  return (
    <div className={styles.success}>
      <div className={styles.success__icon}>
        <Success />
      </div>
      <div className={styles.success__title}>{t('verifyEmail.success')}</div>
      <div className={styles.success__subtitle}>{t('verifyEmail.number_verified')}</div>
    </div>
  )
}