import axios from 'axios'
import { getCookie } from '../services/cookie'
import { store } from '../store'

const API_URL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = getCookie('token')

    if (token) {
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
        baseURL: config.baseURL,
      }
    }

    return config
  },
  (error: any) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  async (config: any) => {
    return {
      ...config,
      success: config.status === 200 || config.status === 201,
    }
  },
  async (error: any) => {
    const code =
      error?.response?.data?.statusCode || error?.response?.status || null

    if (code === 401 || code === 403) {
      const { dispatch } = store

      dispatch({ type: 'auth/logout' })
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
