import ProfileEmpty from '../../../components/Profile/ProfileEmpty/ProfileEmpty'

import styles from './ProfileAbout.module.scss'
import { useOutletContext, useParams } from 'react-router-dom'
import DashboardAchievement from '../../../components/DashboardAchievement/DashboardAchievement'
export default function ProfileAbout() {
  const [user, isMyProfile, achievement] = useOutletContext<any>()

  const transformText = (text: string) => {
    const paragraphs = text.split('\n').map((line, index) => {
      if (line) {
        return <p style={{ whiteSpace: 'pre-wrap' }} key={index}>{line}</p>
      } else {
        return <br />
      }
    });

    return paragraphs;
  }

  return (
    <div className={styles.page}>
      {isMyProfile() ? (
        <>
          {user?.description ? (
            <div className={styles.page__description}>{transformText(user?.description)}</div>
          ) : (
            <ProfileEmpty />
          )}
        </>
      ) : (
        <>
          {!!user?.description || !!achievement ? (
            <>
              <p className={styles.page__label}>About</p>
              {user?.description ? (
                <div className={styles.page__description}>
                  {user?.description}
                </div>
              ) : (
                <div className={styles.page__empty}>
                  There is nothing here yet
                </div>
              )}
              {!isMyProfile() && user?.showAchievements && (
                <DashboardAchievement
                  isPending={false}
                  visibleAchievement={user?.showAchievements}
                  achievement={achievement}
                  isMyProfile={false}
                />
              )}
            </>
          ) : (
            <p>There is nothing here yet</p>
          )}
        </>
      )}
    </div>
  )
}
