import io from 'socket.io-client'
import { getCookie } from './cookie'
import { EChatEvents, EMessageType } from '../types/chat.interface'

let socket: any = null

const checkSocket = () => {
  return socket && socket.connected ? true : false
}

const getToken = async () => {
  return await getCookie('token')
}

const initSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_CHAT_API_URL, {
      forceNew: true,
    })

    socket.on('connect', () => {
      console.log('Connected to server by socket!')
    })

    socket.on('error', (error: any) => {
      console.log('error!', error)
    })

    socket.on('disconnect', (reason: any) => {
      console.log('disconnect socket', reason)
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        console.log('Socket disconected by server')
        socket.connect()
      }
      console.log('Socket disconected')
    })
  } else if (socket.disconnected) {
    socket.open()
    console.log('>>> socket connection is opened', socket)
  }
}

const joinToChat = async (userId: string, cb = () => {}) => {
  const token = await getToken()

  return new Promise((resolve, reject) => {
    socket.emit(
      EChatEvents.JOIN_CHAT,
      {
        token,
        userId,
      },
      (res: any) => {
        if (res === true) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  }).catch((err) => {
    throw err
  })
}

const leftChat = async (userId: string, cb = () => {}) => {
  const token = await getToken()
  return new Promise((resolve, reject) => {
    socket.emit(
      EChatEvents.LEFT_CHAT,
      {
        token,
        userId,
      },
      (res: any) => {
        if (res === true) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  }).catch((err: any) => console.log(err))
}

const joinToChats = async (userId?: string, cb = (res: any) => {}) => {
  const token = await getToken()
  socket.emit(
    EChatEvents.JOIN_CHATS,
    {
      token,
      userId,
    },
    (res: any) => {
      cb(res)
    },
  )
}

const leftChats = async (userId?: string, cb = (res: any) => {}) => {
  const token = await getToken()
  socket.emit(
    EChatEvents.LEFT_CHATS,
    {
      token,
      userId,
    },
    (res: any) => {
      cb(res)
    },
  )
}

const sendMessageToChat = async (
  userId: string,
  text: string,
  type: EMessageType = EMessageType.TEXT_MESSAGE,
  cb = (res: any) => {},
) => {
  if (!text) {
    return
  }
  const token = await getToken()

  socket.emit(
    EChatEvents.NEW_MESSAGE_IN_CHAT,
    {
      token,
      userId,
      message: {
        type,
        text,
      },
    },
    (res: any) => {
      cb(res)
    },
  )
}

const setViewedMessageInChat = async (
  userId: string,
  messageId: string,
  cb = (res: any) => {},
) => {
  const token = await getToken()
  socket.emit(
    EChatEvents.VIEW_MESSAGE_IN_CHAT,
    {
      token,
      userId,
      conversationId: messageId,
    },
    (res: any) => {
      cb(res)
    },
  )
}

const setViewedARMessageInChat = async (
  userId: string,
  messageId: string,
  text: string,
  cb = (res: any) => {},
) => {
  const token = await getToken()
  socket.emit(
    EChatEvents.UPDATE_MESSAGE_IN_CHAT,
    {
      token,
      userId,
      conversationId: messageId,
      message: {
        type: EMessageType.TEXT_MESSAGE,
        text,
        isBoxWasViewed: true,
      },
    },
    (res: any) => {
      cb(res)
    },
  )
}

const subscriptionViewMessageInChat = (
  cb = (res: any) => {},
  unsubscribe?: string,
) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(EChatEvents.VIEW_MESSAGE_IN_CHAT, cb)
  } else {
    socket.on(EChatEvents.VIEW_MESSAGE_IN_CHAT, cb)
  }
}

const subscriptionNewMessageInChat = (
  cb = (res: any) => {},
  unsubscribe?: string,
) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(EChatEvents.NEW_MESSAGE_IN_CHAT, cb)
  } else {
    socket.on(EChatEvents.NEW_MESSAGE_IN_CHAT, cb)
  }
}

const subscriptionUpdatedInChats = (
  cb = (res: any) => {},
  unsubscribe?: string,
) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(EChatEvents.UPDATES_IN_CHATS, cb)
  } else {
    socket.on(EChatEvents.UPDATES_IN_CHATS, cb)
  }
}

const subscriptionUpdatedMessageInChat = (
  cb = () => {},
  unsubscribe?: string,
) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(EChatEvents.UPDATE_MESSAGE_IN_CHAT, cb)
  } else {
    socket.on(EChatEvents.UPDATE_MESSAGE_IN_CHAT, cb)
  }
}

const closeSocket = () => {
  if (socket && socket.connected) {
    socket.close()
  }
}

export const socketChat = {
  constants: EChatEvents,
  initSocket,
  closeSocket,
  checkSocket,
  leftChat,
  joinToChat,
  leftChats,
  joinToChats,
  sendMessageToChat,
  setViewedMessageInChat,
  setViewedARMessageInChat,
  subscriptionNewMessageInChat,
  subscriptionViewMessageInChat,
  subscriptionUpdatedInChats,
  subscriptionUpdatedMessageInChat,
}
