import { Outlet, useLocation, useParams } from 'react-router-dom'
import WithNavigation from '../../layouts/WithNavigation/WithNavigation'
import ProfileHeader from '../../components/Profile/ProfileHeader/ProfileHeader'
import ProfileTabs from '../../components/Profile/ProfileTabs/ProfileTabs'
import { useEffect, useState } from 'react'
import {
  IAchievement,
  IAchievementResponse,
  IProfile,
  IProfileResponse,
} from '../../types/profile.interface'
import { useSelector } from 'react-redux'
import axiosInstance from '../../api/config'
import Spinner from '../../components/Spinner/Spinner'
import { store } from '../../store'

import styles from './ProfileScreen.module.scss'
import ProfileHidden from '../../components/Profile/ProfileHidden/ProfileHidden'
import { socketBalance } from '../../services/socket_balance'
import { ISocketBalance } from '../../types/balance.interface'
import { ISocketNotification } from "../../types/notifications"
import { ReactComponent as BingIcon } from '@assets/icons/notification_bing.svg'
import useNotification from "../../hooks/useNotification"
import { socketWithdrawal } from "../../services/socket_withdrawal"

export default function ProfileScreen() {
  const { profile, achievement: profileAchievement } = useSelector(
    (state: any) => state.profile,
  )
  const [user, setUser] = useState<IProfile | null>(null)
  const [achievement, setAchievement] = useState<IAchievement | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const location = useLocation()
  const { dispatch } = store
  const notification = useNotification()

  const fetchAchivements = async () => {
    try {
      const { data } = await axiosInstance.get<IAchievementResponse>(
        `/api/users/achievements?userId=${id}`,
      )

      setAchievement(data.data)
    } catch (e: any) {}
  }
  useEffect(() => {
    setIsLoading(true)

    socketBalance.initSocket()
    socketWithdrawal.initSocket()
    signInForSocket()
    socketBalance.subscriptionReplenish(listenBalance)
    socketWithdrawal.subscriptionWithdrawal(listenTransaction)

    return () => {
      socketBalance.subscriptionReplenish(listenBalance, 'unsubscribe')
      socketWithdrawal.subscriptionWithdrawal(listenTransaction, 'unsubscribe')
    }
  }, [])

  const signInForSocket = async () => {
    try {
      await socketBalance.signIn()
    } catch (e: any) {
      console.error('signInForSocket', e)
    }
  }

  useEffect(() => {
    if (!location.pathname.includes('follow')) {
      setIsLoading(true)
    }

    if (profile) {
      if (id === profile?._id) {
        setUser({ ...profile })
        setIsLoading(false)
      } else {
        fetchUser()
      }
    }
  }, [profile?._id, id])

  useEffect(() => {
    dispatch({ type: 'profile/getProfile' })
    dispatch({ type: 'profile/getAchievement' })
  }, [])

  const listenBalance = (res: ISocketBalance) => {
    if (res.currency === 'USDC') {
      dispatch.profile.updateBalance(res.amount)
    }
  }

  const listenTransaction = (res: ISocketNotification) => {
    if (res.hash) {
      notification.info('Withdraw completed', <BingIcon />)
    }
  }

  const isMyProfile = () => {
    return id === profile?._id
  }

  const isHiddenProfile = () => {
    return (
      user &&
      profile &&
      !isMyProfile() &&
      !user?.viewable &&
      !user?.followedStatus.isFollowed
    )
  }

  const fetchUser = async () => {
    try {
      const { data } = await axiosInstance.get<IProfileResponse>(
        `/api/users/profile?userId=${id}`,
      )

      await fetchAchivements()
      setUser({ ...data.data })
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className={styles.page__loader}>
        <Spinner />
      </div>
    )
  }

  if (location.pathname.includes('follow')) {
    return <Outlet context={[user as IProfile, isMyProfile]} />
  }

  return (
    <WithNavigation>
      {!!user && (
        <ProfileHeader
          data={user}
          isAlternate={!isMyProfile()}
          isViewable={!isHiddenProfile()}
          wineBottles={
            isMyProfile()
              ? profileAchievement?.totalWineBottles
              : achievement?.totalWineBottles
          }
          refetchUser={fetchUser}
        />
      )}

      {isHiddenProfile() ? (
        <ProfileHidden />
      ) : (
        <>
          <ProfileTabs />
          <Outlet context={[user as IProfile, isMyProfile, achievement]} />
        </>
      )}
    </WithNavigation>
  )
}
