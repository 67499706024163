import Avatar from '../../Avatar/Avatar'
import styles from './MapSommelierCard.module.scss'

import { ReactComponent as CheckIcon } from '@assets/icons/tick_cirlce.svg'
import { useTranslation } from 'react-i18next'
import { IMapItem } from '../../../types/map.interface'
import { IMAGE_URL } from '../../../constants'
import { formatDistance } from '../../../utils/geo'
import { Link } from 'react-router-dom'

interface IProps {
  data: IMapItem
  forList?: boolean
  isLoadingImage?: boolean
}

export default function MapSommelierCard({ data, forList = false, isLoadingImage }: IProps) {
  const { t } = useTranslation()

  return (
    <Link
      to={`/profile/${data._id}`}
      className={`${styles.card} ${forList ? styles['card--list'] : ''}`}
    >
      <div className={styles.card__header}>
        <div className={styles.card__avatarContainer}>
          <Avatar isLoading={isLoadingImage} url={data?.avatar ? `${IMAGE_URL}/${data?.avatar}` : ''} size={48} />
          {data?.receiveNft && (
            <div className={styles.card__checkContainer}>
              <CheckIcon />
            </div>
          )}
        </div>
        <div className={styles.card__userInfo}>
          <div className={styles.card__nameContainer}>
            <p className={styles.card__name}>
              {data?.name || data?.email || ''}
            </p>
            {forList && (
              <p className={styles.card__distance}>
                {formatDistance(data?.distance?.value)}
              </p>
            )}
          </div>
          <p className={styles.card__description}>
            {t('map.sommelier_at')} {data?.tt?.company || ''}
          </p>
        </div>
      </div>
      <div className={styles.card__body}>
        <div className={styles.card__item}>
          <div className={styles.card__label}>{t('map.experience')}</div>
          <div className={styles.card__value}>{data?.yearsExpirience || 0}</div>
        </div>
        <div className={styles.card__item}>
          <div className={styles.card__label}>{t('map.followers')}</div>
          <div className={styles.card__value}>{data?.countFollowers || 0}</div>
        </div>
        <div className={styles.card__item}>
          <div className={styles.card__label}>{t('map.wine_list')}</div>
          <div className={styles.card__value}>{data?.wineBottles || 0}</div>
        </div>
        <div className={styles.card__item}>
          <div className={styles.card__label}>{t('map.level')}</div>
          <div className={styles.card__value}>{data?.level || '-'}</div>
        </div>
      </div>
    </Link>
  )
}
