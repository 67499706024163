import { useTranslation } from 'react-i18next'
import ContentContainer from '../ContentContainer/ContentContainer'
import styles from './ReceiptStart.module.scss'

import receiptExample from '@assets/images/receipt.png'

interface IProps {
  onTakePhoto: () => void
  onBack: () => void
}

export default function ReceiptStart({ onTakePhoto, onBack }: IProps) {
  const { t } = useTranslation()

  return (
    <ContentContainer title={t('scan.receipt.title')} onBack={onBack}>
      <div className={styles.block}>
        <div className={styles.block__content}>
          <p className={styles.block__title}>{t('scan.receipt.instruction')}</p>

          <div className={styles.block__imgContainer}>
            <img
              className={styles.block__img}
              src={receiptExample}
              alt="Receipt"
            />
          </div>
        </div>
        <div className={styles.block__buttons}>
          <button
            className="button button--white"
            type="button"
            onClick={onTakePhoto}
          >
            {t('scan.receipt.take_photo')}
          </button>
        </div>
      </div>
    </ContentContainer>
  )
}
