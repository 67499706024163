import AuthHeader from '../../../components/AuthHeader/AuthHeader'
import styles from './ForgotPassword.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import ForgotPasswordEmailForm from '../../../components/Auth/ForgotPassword/ForgotPasswordEmailForm/ForgotPasswordEmailForm'
import ForgotPasswordCodeForm from '../../../components/Auth/ForgotPassword/ForgotPasswordCodeForm/ForgotPasswrodCodeForm'
import ForgotPasswordSetPasswordForm from '../../../components/Auth/ForgotPassword/ForgotPasswordSetPasswordForm/ForgotPasswordSetPasswordForm'
import ForgotPasswordSuccess from '../../../components/Auth/ForgotPassword/ForgotPasswordSuccess/ForgotPasswordSuccess'
import { useNavigate } from 'react-router-dom'

enum ResetPasswordSteps {
  Email = 'email',
  Code = 'code',
  Password = 'password',
  Success = 'success',
}

export default function ForgotPasswordScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [step, setStep] = useState<ResetPasswordSteps>(ResetPasswordSteps.Email)
  const [email, setEmail] = useState<string>('')
  const [code, setCode] = useState<string>('')

  const emailFormRef = useRef<any>(null)
  const codeFormRef = useRef<any>(null)
  const passwordFormRef = useRef<any>(null)

  const stepBack = () => {
    if (step === ResetPasswordSteps.Email) {
      navigate(-1)
    }

    if (step === ResetPasswordSteps.Code) {
      setStep(ResetPasswordSteps.Email)
    }

    if (step === ResetPasswordSteps.Password) {
      setStep(ResetPasswordSteps.Code)
    }
  }

  const triggerForm = async () => {
    if (step === ResetPasswordSteps.Email) {
      emailFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )

      setEmail(emailFormRef.current?.email?.value || '')
    }

    if (step === ResetPasswordSteps.Code) {
      codeFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )

      setCode(codeFormRef.current?.code?.value || '')
    }

    if (step === ResetPasswordSteps.Password) {
      passwordFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )
    }

    if (step === ResetPasswordSteps.Success) {
      navigate('/login')
    }
  }

  const nextStep = () => {
    if (step === ResetPasswordSteps.Email) {
      setStep(ResetPasswordSteps.Code)
    }

    if (step === ResetPasswordSteps.Code) {
      setStep(ResetPasswordSteps.Password)
    }

    if (step === ResetPasswordSteps.Password) {
      setStep(ResetPasswordSteps.Success)
    }
  }

  const typography = () => {
    const result: any = {
      title: t('resetPasswordScreen.title_email'),
      description: t('resetPasswordScreen.description_email'),
      buttonTitle: t('resetPasswordScreen.reset_your_password'),
    }

    if (step === ResetPasswordSteps.Code) {
      result.title = t('resetPasswordScreen.title_code')
      result.description = t('resetPasswordScreen.description_code', {
        email: emailFormRef.current?.email?.value || '',
      })

      result.description = (
        <Trans
          i18nKey="verifyEmail.description"
          values={{ email }}
          components={{ bold: <b /> }}
        />
      )

      result.buttonTitle = t('resetPasswordScreen.confirm_email')
    }

    if (step === ResetPasswordSteps.Password) {
      result.title = t('resetPasswordScreen.title_password')
      result.description = t('resetPasswordScreen.description_password')
      result.buttonTitle = t('resetPasswordScreen.reset_your_password')
    }

    if (step === ResetPasswordSteps.Success) {
      result.title = t('resetPasswordScreen.title_success')
      result.description = t('resetPasswordScreen.description_success')
      result.buttonTitle = t('resetPasswordScreen.continue')
    }

    return result
  }

  return (
    <div className={`page ${styles.page}`}>
      {step !== ResetPasswordSteps.Success && (
        <AuthHeader
          title={typography().title}
          description={typography().description}
          navigateOnBack={false}
          onClickBack={stepBack}
        />
      )}

      {step === ResetPasswordSteps.Email && (
        <ForgotPasswordEmailForm ref={emailFormRef} nextStep={nextStep} />
      )}

      {step === ResetPasswordSteps.Code && (
        <ForgotPasswordCodeForm
          ref={codeFormRef}
          email={email}
          nextStep={nextStep}
        />
      )}

      {step === ResetPasswordSteps.Password && (
        <ForgotPasswordSetPasswordForm
          ref={passwordFormRef}
          code={code}
          nextStep={nextStep}
        />
      )}

      {step === ResetPasswordSteps.Success && <ForgotPasswordSuccess />}
      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={triggerForm}>
          {typography().buttonTitle}
        </button>
      </div>
    </div>
  )
}
