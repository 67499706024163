import styles from './ForgotPasswordEmailForm.module.scss'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import Input from '../../../Input/Input'
import axiosInstance from '../../../../api/config'
import ReCAPTCHA from 'react-google-recaptcha'

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t('validation.invalid_email'))
    .required(i18next.t('validation.required'))
    .trim(),
})

interface IProps {
  ref?: any
  nextStep: () => void
}

interface IForgotPasswordEmailBody {
  email: string
}

const ForgotPasswordEmailForm = forwardRef(({ nextStep }: IProps, ref) => {
  const { t } = useTranslation()
  const recaptchaRef = useRef<any>(null)

  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SigninSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setServerError('')
    setIsPending(true)

    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body: IForgotPasswordEmailBody = {
        email: values.email.trim().replaceAll(/\s/g,''),
      }
      await axiosInstance.post('/api/auth/forgotten', body, {
        headers: {
          recaptcha: token,
        },
      })

      setIsPending(false)
      nextStep()
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form__row}>
        <Input
          id="email"
          name="email"
          type="text"
          placeholder={t('loginScreen.email_placeholder')}
          label={t('loginScreen.email_label')}
          value={formik.values.email.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          isPreventScroll={true}
          isError={formik.touched.email && Boolean(formik.errors.email)}
        />
        <div className={styles.form__errorContainer}>
          {serverError && <p className="form__error">{serverError}</p>}
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      />
    </form>
  )
})

export default ForgotPasswordEmailForm
