import { ReactComponent as ArrowIcon } from '@assets/icons/chevron.svg'
import { ReactComponent as UserIcon } from '@assets/icons/user_tag.svg'

import styles from './StoreHeader.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IMapItem } from '../../../types/map.interface'
import { useState } from 'react'
import { IMAGE_URL } from '../../../constants'

interface IProps {
  data: IMapItem
}

export default function StoreHeader({ data }: IProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [photoCount, setPhotoCount] = useState(0)
  const [isImgError, setIsImgError] = useState(false)

  const photoUrl = () => {
    const photos = data?.googleInfo?.photos || []

    if (!photos.length) {
      return data?.avatar ? `${IMAGE_URL}/${data.avatar}` : ''
    }

    if (photos[photoCount].getUrl) {
      //@ts-ignore
      return photos[photoCount].getUrl() || ''
    }

    if (photos[photoCount].photo_reference) {
      return `https://maps.googleapis.com/maps/api/place/photo?photo_reference=${data.googleInfo?.photos[0].photo_reference}&maxwidth=4000&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
    }

    return ''
  }

  const handleImgError = () => {
    if (!data.googleInfo?.photos?.length) {
      setIsImgError(true)
      return
    }

    if (photoCount < data.googleInfo?.photos?.length - 1) {
      setPhotoCount((count) => count + 1)
    }
  }

  const back = () => {
    if (location.key === 'default') navigate('/')
    else navigate(-1)
  }

  const addressLine1 = () => {
    if (!data.googleInfo?.address_components?.length) {
      if (data?.city && data?.country) return `${data?.city}, ${data?.country}`
      else if (data?.city) return data?.city
      else if (data?.country) return data?.country
      else return ''
    }

    const city =
      data.googleInfo?.address_components?.find((c: any) =>
        c.types.includes('locality'),
      )?.long_name || ''

    const country =
      data.googleInfo?.address_components?.find((c: any) =>
        c.types.includes('country'),
      )?.long_name || ''

    if (city && country) return `${city}, ${country}`
    else if (city) return city
    else if (country) return country
    else return ''
  }

  const addressLine2 = () => {
    if (!data.googleInfo?.address_components?.length) return ''

    const streetNumber =
      data.googleInfo?.address_components?.find((c: any) =>
        c.types.includes('street_number'),
      )?.long_name || ''

    const street =
      data.googleInfo?.address_components?.find((c: any) =>
        c.types.includes('route'),
      )?.long_name || ''

    return `${streetNumber} ${street}`
  }

  const storeType = () => {
    return data.googleInfo?.types?.[0].replaceAll('_', ' ') || ''
  }

  return (
    <div className={styles.block}>
      <div className={styles.block__imgContainer}>
        {!!data.googleInfo?.photos.length && !isImgError && (
          <img
            src={photoUrl()}
            alt={data.googleInfo?.name || ''}
            className={styles.block__img}
            onError={handleImgError}
          />
        )}

        <div className={styles.block__gradient}></div>
      </div>
      <button className={styles.block__back} type="button" onClick={back}>
        <ArrowIcon />
      </button>

      <div className={styles.block__content}>
        <p className={styles.block__title}>
          {data?.googleInfo?.name || data?.company || ''}
        </p>
        <p className={styles.block__type}>{storeType()}</p>
        <p className={styles.block__address}>{addressLine1()}</p>
        <p className={styles.block__address}>{addressLine2()}</p>

        <div className={styles.block__sommeliersContainer}>
          <UserIcon />
          <p className={styles.block__sommeliers}>
            {t('map.ranking.sommeliers')}: {data?.countSommelliers || 0}
          </p>
        </div>
      </div>
    </div>
  )
}
