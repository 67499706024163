import { Toaster } from 'react-hot-toast'
import NavBar from '../../components/NavBar/NavBar'
import styles from './WithNavigation.module.scss'
import ToastsContainer from '../../components/Toasts/ToastsContainer/ToastsContainer'

interface IProps {
  children: React.ReactNode
  padding?: boolean
}

export default function WithNavigation({ children, padding = false }: IProps) {
  return (
    <div
      id="layout_with_navigation"
      className={`${styles.layout} ${padding && styles.layout__padding}`}
    >
      <div className={styles.layout__content}>
        {children}
        <ToastsContainer bottom={100} />
      </div>

      <div className={styles.layout__navigation}>
        <NavBar />
      </div>
    </div>
  )
}
