import styles from './NFT.module.scss'

interface IProps {
  title: string,
  description: string,
  img: string
}

export default function NFT({ title, description, img }: IProps) {
  return (
    <div className={styles.nft}>
      <div className={styles.nft__title}>{title}</div>
      <img className={styles.nft__image} src={img} alt={title} />
      <div className={styles.nft__description}>{description}</div>
    </div>
  )
}