import { useState } from 'react'
import styles from './DashboardBalance.module.scss'
import { useTranslation  } from 'react-i18next'

import { ReactComponent as WinePoint } from '@assets/icons/winepoint.svg'
import { ReactComponent as USDC } from '@assets/icons/usdc.svg'
import { IProfile } from "../../types/profile.interface";

import WithdrawModal from "../modals/WithdrawModal/WithdrawModal";
import AppSkeleton from "../Skeleton/Skeleton";

interface IProps {
  profile: IProfile
  isPending: boolean
}

export default function DashboardBalance({ profile, isPending }: IProps) {
  const { t } = useTranslation()

  const [openWithdraw, setOpenWithdraw] = useState(false)

  return (
    <div className={styles.balance}>
      <div className={styles.balance__title}>{t('dashboard.balances')}</div>
      <div className={`${styles.balance__block} ${styles.balance__block__bottom}`}>
        <WinePoint />
        <div className={styles['balance__block--body']}>
          <div>
            <div className={styles.balance__smallTitle}>{t('dashboard.wine_points')}</div>
            <div className={styles.balance__subtitle}>{t('dashboard.metawine')}</div>
          </div>
          <div className={styles.balance__smallTitle}>{
            isPending ? <AppSkeleton width={50} height={15} borderRadius={8}  /> : profile?.balance?.marketing?.credits.toLocaleString('es-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }</div>
        </div>
      </div>
      <div className={styles.balance__block}>
        <USDC />
        <div className={styles['balance__block--body']}>
          <div>
            <div className={styles.balance__smallTitle}>{t('dashboard.usdc')}</div>
            <div className={styles.balance__subtitle}>{t('dashboard.usdcoin')}</div>
          </div>
          <div className={styles.balance__smallTitle}>{
            isPending ? <AppSkeleton width={50} height={15} borderRadius={8} /> : ((Number(profile?.balance?.marketing?.usdc) + Number(profile?.balance?.main[1].balance)).toLocaleString('es-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }))
          }</div>
        </div>
      </div>
      <button className="button button--white" onClick={() => setOpenWithdraw(true)}>{t('dashboard.withdraw')}</button>

      <WithdrawModal open={openWithdraw} onClose={() => setOpenWithdraw(false)} />
    </div>
  )
}