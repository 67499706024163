import { useTranslation } from 'react-i18next'
import styles from './FeedEmpty.module.scss'
import { ReactComponent as CoffeeIcon } from '@assets/icons/coffee.svg'

export default function FeedEmpty() {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <CoffeeIcon className={styles.block__icon} />
      </div>
      <p className={styles.block__title}>{t('feed.empty_message')}</p>
    </div>
  )
}
