import Avatar from '../../Avatar/Avatar'
import styles from './CommentItem.module.scss'

import { ReactComponent as DotsIcon } from '@assets/icons/dots.svg'
import { IFeedPost } from '../../../types/feed.interface'
import dayjs from 'dayjs'
import { useState } from 'react'
import ConfirmModal from '../../ConfirmModal/ConfirmModal'
import { useTranslation } from 'react-i18next'
import { IMAGE_URL } from '../../../constants'
import axiosInstance from '../../../api/config'
import { Link } from 'react-router-dom'

interface IProps {
  post: IFeedPost
  onDelete: (id: string) => void
  postId: string
  profileId: string
  isLoadingImage?: boolean
}

export default function CommentItem({
  post,
  onDelete,
  postId,
  profileId,
  isLoadingImage
}: IProps) {
  const { t } = useTranslation()

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const deleteComment = () => {
    setIsOpenDeleteModal(false)
    setIsOpenConfirmationModal(true)
  }

  const transformText = (text: string) => {
    const paragraphs = text.split('\n').map((line, index) => {
      if (line) {
        return <p style={{ whiteSpace: 'pre-wrap' }} key={index}>{line}</p>
      } else {
        return <br />
      }
    });

    return paragraphs;
  }

  const confirmDeleteComment = async () => {
    if (isPending) return

    setIsPending(true)
    try {
      await axiosInstance.delete(`/api/posts/${postId}/comments/${post._id}`)
      setIsOpenConfirmationModal(false)
      setIsPending(false)
      onDelete(post._id)
    } catch (e: any) {
      setIsPending(false)
    }
  }

  return (
    <div className={styles.block}>
      <div className={styles.block__header}>
        <Link to={`/profile/${post?.owner?._id}`} className={styles.block__userInfo}>
          <Avatar isLoading={isLoadingImage} url={post?.owner?.avatar ? `${IMAGE_URL}/${post?.owner?.avatar}` : ''} size={40} />
          <div>
            <p className={styles.block__username}>{post.owner.name}</p>
            <p className={styles.block__time}>
              {dayjs(post.createdAt).format('MMM DD, YYYY HH:mm a')}
            </p>
          </div>
        </Link>
        {profileId === post?.owner?._id && (
          <button
            className={styles.block__headerButton}
            type="button"
            onClick={() => setIsOpenDeleteModal(true)}
          >
            <DotsIcon />
          </button>
        )}
      </div>

      <p className={styles.block__content}>{transformText(post.text)}</p>

      {profileId === post?.owner?._id && (
        <>
          <ConfirmModal
            isOpen={isOpenDeleteModal}
            title={t('comments.delete_comment_title')}
            cancelButtonTitle={t('comments.delete_comment_cancel')}
            confirmButtonTitle={t('comments.delete_comment_confirm')}
            onCancel={() => setIsOpenDeleteModal(false)}
            onConfirm={() => deleteComment()}
          />

          <ConfirmModal
            isOpen={isOpenConfirmationModal}
            title={t('comments.comment_deleted')}
            cancelButtonTitle={t('comments.undo_deletion')}
            confirmButtonTitle={t('comments.delete_comment_cancel')}
            onCancel={() => setIsOpenConfirmationModal(false)}
            onConfirm={() => confirmDeleteComment()}
          />
        </>
      )}
    </div>
  )
}
