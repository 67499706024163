import { useTranslation } from 'react-i18next'
import styles from './ChatEmpty.module.scss'
import { ReactComponent as MessageIcon } from '@assets/icons/message_add.svg'

export default function ChatEmpty() {
  const { t } = useTranslation()
  return (
    <div className={styles.block}>
      <div className={styles.block__content}>
        <div className={styles.block__iconContainer}>
          <MessageIcon />
        </div>
        <pre className={styles.block__title}>{t('chat.empty.title')}</pre>
        <p className={styles.block__description}>
          {t('chat.empty.description')}
        </p>
      </div>
    </div>
  )
}
