import { isIOS, isMobile } from 'react-device-detect'
import styles from './SecondaryLayout.module.scss'

interface IProps {
  header: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  colored?: boolean
  black?: boolean
  promo?: boolean
}

export default function SecondaryLayout({
  children,
  header,
  footer,
  colored = false,
  black = false,
  promo = false
}: IProps) {
  return (
    <div
      className={`${styles.layout} ${promo && styles['layout--with-footer__padding']} ${colored && styles.layout__colored} ${black && styles.layout__black} ${!!footer ? styles['layout--with-footer'] : ''}  ${!!footer && isIOS && isMobile ? styles['layout--ios'] : ''}`}
    >
      <div className={styles.layout__header}>{header}</div>
      <div className={styles.layout__content}>{children}</div>
      {!colored && !!footer && (
        <div className={`${styles.layout__footer} ${promo && styles.layout__footer__promo}`}>{footer}</div>
      )}
    </div>
  )
}
