import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface IProps {
  width?: string | number
  height?: string | number
  borderRadius?: string | number
  inline?: boolean
  duration?: boolean
  circle?: boolean
  count?: number
}

export default function AppSkeleton({
    width = 240,
    height = 48,
    borderRadius = 115,
    circle = false,
    count = 1,
    inline = false,
}: IProps) {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <Skeleton
      count={count}
      borderRadius={borderRadius}
      baseColor={'#CBC0B6'}
      highlightColor={'#ede8e1'}
      height={height}
      width={width}
      circle={circle}
      inline={inline}
    />
  )
}
