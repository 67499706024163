import styles from './PromoTasks.module.scss'
import { ReactComponent as PromoTask } from "@assets/icons/promo-task.svg";
import { ReactComponent as TaskDone } from "@assets/icons/task-done.svg";
import { ReactComponent as TaskFailed } from "@assets/icons/task-failed.svg";
import { ReactComponent as Success } from "@assets/icons/success-middle.svg";
import { IPromoConditions } from "../../../types/promo.interface";
import dayjs from "dayjs";

interface IProps {
  tasks: IPromoConditions[] | undefined
  joined: boolean | undefined
  reward: number
  announce: string
}

export default function PromoTasks({ tasks, joined, reward, announce }: IProps) {
  return (
    <div className={styles.block}>
      {!joined ? (
        <div>
          <div className={styles.block__title}>Tasks</div>
          <div className={styles.block__description}>Get a chance to win {reward} USDC you have to complete this tasks</div>
          {tasks?.map((item, index) => (
            <div className={styles.block__wrapper}>
              <div className={styles.block__name}>
                <div className={styles.block__round}>
                  <PromoTask />
                </div>
                <div className={styles.block__outline}>
                  <div className={styles.block__task}>Task {index + 1}</div>
                  <div className={styles.block__titles}>{item.text}</div>
                </div>
              </div>
              <div>
                {item.isDone ? <TaskDone /> : <TaskFailed />}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.block__joined}>
          <div className={styles.block__joined__background}>
            <Success />
          </div>
          <div className={styles.block__joined__title}>You have joined to this promo</div>
          <div className={styles.block__joined__announce}>The winners will be announced on{' '}
            {dayjs(announce).format('MMM DD, YYYY')}</div>
        </div>
      )}
    </div>
  )
}