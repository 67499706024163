import * as Yup from "yup"
import i18next from "i18next"
import { forwardRef, useState } from 'react'
import {useTimer} from "react-timer-hook";
import {useFormik} from "formik";
import axiosInstance from "../../api/config";
import styles from "../Auth/VerifyEmailForm/VerifyEmailForm.module.scss";
import CodeVerify from "../CodeVerify/CodeVerify";
import {setCookie} from "../../services/cookie";

const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18next.t('validation.required')).trim(),
})

interface IProps {
  ref?: any
  email: string
  code: string
  changeStep: () => void
}

const EXPIRY_TIME = 60

const ConfirmChangeEmail = forwardRef(({ email, changeStep, code }: IProps, ref) => {
  const [isCanResend, setIsCanResend] = useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const expiryTimestamp = new Date()
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsCanResend(true),
  })

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: CodeSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)

    try {
      const response = await axiosInstance.post(`/api/auth/change-email/${values.code.replaceAll(/\s/g,'')}/verify`)
      changeStep()

      setCookie('token', response.data?.data?.token, {
        domain: window.location.hostname,
        maxAge: 60 * 60 * 24 * 7,
        path: '/'
      })

      setIsPending(false)
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  const resendCode = async () => {
    try {
      const body = {
        email: email
      }

      await axiosInstance.post(`/api/auth/change-email/${code}`, body)
      const expiryTimestamp = new Date()
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRY_TIME)

      restart(expiryTimestamp)
      setIsCanResend(false)
    } catch (e: any) {}
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <CodeVerify
        resendCode={resendCode}
        isCanResend={isCanResend}
        serverError={serverError}
        setIsCanResend={setIsCanResend}
        formik={formik}
        seconds={seconds}
        minutes={minutes}
      />
    </form>
  )
})

export default ConfirmChangeEmail