import {IPromo, IPromoItem} from "../../../types/promo.interface"
import styles from './PromoHeader.module.scss'
import Avatar from "../../Avatar/Avatar";
import dayjs from "dayjs";
import {IMAGE_URL} from "../../../constants";

interface IProps {
  startDate: string
  endDate: string
  resultDate: string
  sponsor: { sponsorLogo: string, sponsorName: string }
  status: string
}

export default function PromoHeader({ startDate, endDate, resultDate, sponsor, status }: IProps) {
  const dateLabel = () => {
    if (status === 'soon') {
      return `Start Date: ${dayjs(startDate).format('MMM DD, YYYY')}`
    } else if (status === 'ongoing') {
      return `End Date: ${dayjs(endDate).format('MMM DD, YYYY')}`
    } else if (status === 'finalization') {
      return `Results: ${dayjs(resultDate).format('MMM DD, YYYY')}`
    } else {
      return `Ended: ${dayjs(endDate).format('MMM DD, YYYY')}`
    }
  }
  return (
    <div className={styles.block}>
      <div className={styles.block__avatar}>
        <Avatar border={true} url={sponsor?.sponsorLogo ? `${IMAGE_URL}/${sponsor?.sponsorLogo}` : ''} />
        <div className={styles.block__info}>
          <div className={styles.block__info__partner}>{sponsor?.sponsorName}</div>
          <div className={styles.block__info__date}>{dateLabel()}</div>
        </div>
      </div>
      <div className={styles.block__status}>
        <div className={`${styles.block__status__label} ${styles[`block__status__label__${status}`]}`}>{status.charAt(0).toUpperCase() + status.slice(1)}</div>
      </div>
    </div>
  )
}