import AuthHeader from '../../components/AuthHeader/AuthHeader'
import { Trans, useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SetNewPasswordForm from "../../components/SetNewPasswordForm/SetNewPasswordForm"
import ForgotPasswordSuccess from "../../components/Auth/ForgotPassword/ForgotPasswordSuccess/ForgotPasswordSuccess";
import styles from "../Auth/ForgotPassword/ForgotPassword.module.scss"

export default function ChangePassword() {
  const [step, setStep] = useState(0)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const passwordFormRef = useRef<any>(null)

  const triggerForm = async () => {
    passwordFormRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  return (
    <div className={`page ${styles.page}`}>
      {step === 0 && (
        <AuthHeader
          title={t('resetPasswordScreen.title_password')}
          description={
            <Trans
              i18nKey="resetPasswordScreen.description_password"
            />
          }
          navigateOnBack={false}
          onClickBack={() => navigate('/edit-profile')}
        />
      )}

      {step === 0 && <SetNewPasswordForm ref={passwordFormRef} nextStep={() => setStep(1)} />}
      {step === 1 && <ForgotPasswordSuccess change={true} />}

      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={step === 1 ? () => navigate('/login') : triggerForm}>
          {step === 0 ? t('resetPasswordScreen.change_password') : t('resetPasswordScreen.continue')}
        </button>
      </div>
    </div>
  )
}