export interface IPosition {
  lat: number
  lng: number
}

export enum EMarkerType {
  STORE = 'store',
  SOMMELIER = 'sommelier',
}

export interface IMapItem {
  _id: string
  email: string
  name: string
  avatar: string
  level: string
  yearsExpirience: number
  location: [number, number]
  type: EMarkerType
  tt: {
    country: string
    city: string
    company: string
  }
  countSommelliers: number
  countFollowers: number
  wineBottles: number
  city: string
  company: string
  placeId?: string
  country: string
  distance: {
    value: number
    type: string
  }
  receiveNft: boolean
  googleInfo?: {
    formatted_address?: string
    vicinity?: string
    name?: string
    types?: string[]
    address_components?: {
      long_name: string
      short_name: string
      types: string[]
    }[]
    editorial_summary?: {
      language: string
      overview: string
    }
    photos: {
      height: number
      html_attributions: string[]
      photo_reference?: string
      width: number
      getUrl?: () => string
    }[]
    place_id: string
  }
}

export interface IMapResponse {
  data: IMapItem[]
}

export interface IMapItemResponse {
  data: IMapItem
}
