import { createModel } from '@rematch/core'
import axiosInstance from '../../api/config'
import { RootModel } from '.'
import { IPagination, IPromo, IPromoDetails } from "../../types/promo.interface";

interface IPromoState {
  pagination: IPagination
  promos: IPromo[]
  promoDetails: IPromoDetails | null
}

export const promo = createModel<RootModel>()({
  state: {
    pagination: {
      countItems: 0,
      page: 1,
      pages: 1
    },
    promos: [],
    promoDetails: null
  } as IPromoState,
  reducers: {
    setPromo(state, payload: IPromo[]) {
      return {
        ...state,
        ...payload
      }
    },
    setPromoDetails(state, payload: any) {
      return {
        ...state,
        promoDetails: payload
      }
    }
  },
  effects: () => ({}),
})