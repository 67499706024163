import { useParams } from 'react-router-dom'
import FollowItem from '../FollowItem/FollowItem'
import styles from './ProfileFollowers.module.scss'
import axiosInstance from '../../../api/config'
import { useEffect, useState } from 'react'
import {
  EFollowType,
  IFollowParams,
  IFollowResponse,
  IFollower,
} from '../../../types/profile.interface'
import Spinner from '../../Spinner/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import FollowersEmpty from '../FollowersEmpty/FollowersEmpty'
import { useTranslation } from 'react-i18next'
import { store } from '../../../store'

interface IProps {
  type: EFollowType
  isMyProfile: boolean
}

export default function ProfileFollowers({ type, isMyProfile }: IProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const [items, setItems] = useState<any[]>([])
  const [requests, setRequests] = useState<any[]>([])
  const [requestsCount, setRequestsCount] = useState(0)
  const [myRequests, setMyRequests] = useState<any[]>([])
  const [myRequestsCount, setMyRequestsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const [pagination, setPagination] = useState({
    countItems: 0,
    page: 1,
    pages: 1,
  })

  const { dispatch } = store

  useEffect(() => {
    fetchItems()
  }, [type])

  const fetchItems = async (page: number = 1) => {
    if (!id) return

    if (page === 1) {
      setIsLoading(true)
    }

    setIsLoadingImage(true)

    try {
      const params: IFollowParams = {
        userId: id,
        page,
        limit: 10,
        type,
      }

      const { data } = await axiosInstance.get<IFollowResponse>(
        `api/users/follow/list`,
        {
          params,
        },
      )

      setItems(
        page === 1
          ? data?.data?.entities || []
          : [...items, ...(data?.data?.entities || [])],
      )

      setPagination({
        page: data?.data?.page || 1,
        pages: data?.data?.pages || 1,
        countItems: data?.data?.countItems || 0,
      })

      if (isMyProfile && page === 1) {
        await fetchMyRequests()
        await fetchRequests()
      }

      setIsLoading(false)
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const fetchRequests = async () => {
    if (!isMyProfile || !id) return

    try {
      const params: IFollowParams = {
        page: 1,
        limit: 1000,
        type: EFollowType.REQUESTS,
      }

      const { data } = await axiosInstance.get<IFollowResponse>(
        `api/users/follow/list`,
        {
          params,
        },
      )

      setRequests(data?.data?.entities || [])
      setRequestsCount(data?.data?.countItems || 0)
      setIsLoading(false)
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const fetchMyRequests = async () => {
    if (!isMyProfile) return

    try {
      const params: IFollowParams = {
        page: 1,
        limit: 100,
        type: EFollowType.MY_REQUESTS,
      }

      const { data } = await axiosInstance.get<IFollowResponse>(
        `api/users/follow/list`,
        {
          params,
        },
      )

      setMyRequests(data?.data?.entities || [])
      setMyRequestsCount(data?.data?.countItems || 0)
    } catch (e: any) {}
  }

  const loadMore = async () => {
    await fetchItems(+pagination.page + 1)
  }

  const handleUpdateItem = async (id: string, followType: EFollowType) => {
    if (followType === EFollowType.FOLLOWINGS && isMyProfile) {
      const newItems = items.filter((item) => item._id !== id)

      setItems(newItems)
      setPagination(() => {
        return {
          ...pagination,
          countItems: pagination.countItems - 1,
        }
      })
    }

    if (
      (followType === EFollowType.FOLLOWERS ||
        followType === EFollowType.FOLLOWINGS) &&
      !isMyProfile
    ) {
      const newItems = items.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            isFollowing: !!item.isFollowing ? 0 : 1,
          }
        }

        return item
      })

      setItems(newItems)
    }

    if (
      followType === EFollowType.REQUESTS ||
      followType === EFollowType.MY_REQUESTS
    ) {
      await fetchRequests()
      await fetchItems(1)
    }

    dispatch({ type: 'profile/getProfile' })
  }

  const handleRemoveItem = async (id: string) => {
    const newItems = items.filter((item) => item._id !== id)

    setItems(newItems)
    setPagination(() => {
      return {
        ...pagination,
        countItems: pagination.countItems - 1,
      }
    })
  }

  return (
    <div>
      {isLoading ? (
        <div className={styles.page__loader}>
          <Spinner />
        </div>
      ) : (
        <>
          {(type === EFollowType.FOLLOWERS &&
            (items.length > 0 || requests.length > 0)) ||
          (type === EFollowType.FOLLOWINGS && items.length) ||
          (type === EFollowType.FOLLOWINGS && myRequests.length) ? (
            <div className={styles.page__content}>
              {type === EFollowType.FOLLOWERS && requests.length > 0 && (
                <>
                  <p className={styles.page__label}>
                    {t('profile.requests')}: {requestsCount}
                  </p>
                  <div
                    className={`${styles.page__scrollContainer} ${styles['page__scrollContainer--requests']}`}
                  >
                    {requests.map((item: IFollower, index: number) => (
                      <FollowItem
                        isLoadingImage={isLoadingImage}
                        key={item._id}
                        type={EFollowType.REQUESTS}
                        data={item}
                        isMyProfile={isMyProfile}
                        onUpdate={() =>
                          handleUpdateItem(item._id, EFollowType.REQUESTS)
                        }
                      />
                    ))}
                  </div>
                </>
              )}

              {type === EFollowType.FOLLOWINGS && myRequests.length > 0 && (
                <>
                  <p className={styles.page__label}>
                    {t('profile.my_requests')}: {myRequestsCount}
                  </p>
                  <div
                    className={`${styles.page__scrollContainer} ${styles['page__scrollContainer--requests']}`}
                  >
                    {myRequests.map((item: IFollower, index: number) => (
                      <FollowItem
                        isLoadingImage={isLoadingImage}
                        key={item._id}
                        type={EFollowType.MY_REQUESTS}
                        data={item}
                        isMyProfile={isMyProfile}
                        onUpdate={() =>
                          handleUpdateItem(item._id, EFollowType.MY_REQUESTS)
                        }
                      />
                    ))}
                  </div>
                </>
              )}

              {items.length > 0 && (
                <>
                  <p className={styles.page__label}>
                    {type === EFollowType.FOLLOWERS
                      ? t('profile.followers')
                      : t('profile.followings')}
                    : {pagination.countItems}
                  </p>

                  <InfiniteScroll
                    dataLength={items.length}
                    next={loadMore}
                    hasMore={pagination.page < pagination.pages}
                    loader={<></>}
                    className={styles.page__scrollContainer}
                    scrollThreshold={0.5}
                    scrollableTarget='followersContainer'
                  >
                    {items.map((item: IFollower, index: number) => (
                      <FollowItem
                        isLoadingImage={isLoadingImage}
                        key={item._id}
                        type={type}
                        data={item}
                        isMyProfile={isMyProfile}
                        onUpdate={() => handleUpdateItem(item._id, type)}
                        onRemove={() => handleRemoveItem(item._id)}
                      />
                    ))}
                  </InfiniteScroll>
                </>
              )}
            </div>
          ) : (
            <FollowersEmpty type={type} />
          )}
        </>
      )}
    </div>
  )
}
