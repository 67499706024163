import styles from './TransactionDetails.module.scss'
import { useTranslation } from 'react-i18next'

import ModalContainer from '../../ModalContainer/ModalContainer'
import Transaction from '../../Transaction/Transaction'

import { ITransactionsEntities } from '../../../types/transactions.interface'

interface IProps {
  data: ITransactionsEntities
  open: boolean
  onClose: () => void
}

const TransactionHash = ({
  hash,
  type,
}: {
  hash: string
  type: string
}): any => {
  const { t } = useTranslation()

  const isHash = hash.startsWith('0x')

  if ((type === 'WITHDRAW' || type === 'REPLENISH') && hash) {
    return (
      <div className={styles.details__block}>
        <div className={styles.details__block__title}>
          {t('transactions.hash')}
        </div>
        <div className={styles.details__block__hash}>
          {isHash ? hash : 'Pending...'}
        </div>
      </div>
    )
  }

  return hash ? (
    <div className={styles.details__block}>
      <div className={styles.details__block__title}>
        {t('transactions.hash')}
      </div>
      <div className={styles.details__block__hash}>{hash}</div>
    </div>
  ) : null
}

export default function TransactionDetails({ data, open, onClose }: IProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div className={styles.details}>
        <div className={styles.details__title}>{t('transactions.details')}</div>
        <Transaction data={data} />
        <div className={styles.details__info}>
          <div className={styles.details__block}>
            <div className={styles.details__block__title}>
              {data?.type === 'WITHDRAW'
                ? t('transactions.sent')
                : t('transactions.received')}
            </div>
            <div className={styles.details__block__wallet}>
              {data?.type === 'WITHDRAW' ? data?.to : data?.from}
            </div>
          </div>
          <TransactionHash hash={data?.hash || ''} type={data?.type || ''} />
        </div>
      </div>
    </ModalContainer>
  )
}
