import { useTranslation } from 'react-i18next'
import ContentContainer from '../ContentContainer/ContentContainer'
import styles from './IdentifiedWine.module.scss'

import { IWineSearchInfo } from '../../../types/scan.interface'
import { prepareImageForDisplay } from '../../../utils/scan'

interface IProps {
  data: IWineSearchInfo
  onNext: () => void
  onTryAgain: () => void
  onBack: () => void
}

export default function IdentifiedWine({
  data,
  onNext,
  onTryAgain,
  onBack,
}: IProps) {
  const { t } = useTranslation()

  const imageLink = (): string => {
    const images = data?.image?.variations || {}

    let key = 'bottle_medium_square'

    if ('bottle_medium_square' in images) {
      key = 'bottle_medium_square'
    } else if ('medium_square' in images) {
      key = 'medium_square'
    } else if ('small_square' in images) {
      key = 'small_square'
    } else {
      key = Object.keys(images)[0]
    }

    return prepareImageForDisplay(images[key] || '')
  }

  return (
    <ContentContainer title={t('scan.identified_wine.title')} onBack={onBack}>
      <div className={styles.block}>
        <div className={styles.block__content}>
          <div className={styles.block__imgContainer}>
            <img className={styles.block__img} src={imageLink()} alt="" />
          </div>
          <p className={styles.block__title}>{data.name || '-'}</p>
          <p className={styles.block__year}>{data.year || '-'}</p>
          <div className={styles.block__divider}></div>
          <p className={styles.block__price}>
            Avg Price {data?.price?.currency?.prefix || ''}{' '}
            {new Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(data?.price?.amount || 0)}{' '}
            {data?.price?.currency?.suffix || ''} /{' '}
            {data?.price?.bottle_type?.volume_ml || 0}ml
          </p>
        </div>

        <div className={styles.block__buttons}>
          <button
            className="button button--white"
            type="button"
            onClick={onNext}
          >
            {t('scan.identified_wine.next')}
          </button>
          <button
            className="button button--outline-white"
            type="button"
            onClick={onTryAgain}
          >
            {t('scan.identified_wine.try_again')}
          </button>
        </div>
      </div>
    </ContentContainer>
  )
}
