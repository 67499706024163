import { useTranslation } from 'react-i18next'
import NavTabs from '../../../components/NavTabs/NavTabs'
import WithNavigation from '../../../layouts/WithNavigation/WithNavigation'

import styles from './PromoScreen.module.scss'
import PromoList from '../PromoList/PromoList'

export default function PromoScreen() {
  const { t } = useTranslation()

  const TABS = [
    {
      title: t('tabs.feed'),
      to: '/home',
    },
    {
      title: t('tabs.promo'),
      to: '/home/promo',
    },
  ]

  return (
    <WithNavigation>
      <div className={styles.page__header}>
        <NavTabs tabs={TABS} />
      </div>
      <div className={styles.page__content}>
        <PromoList />
      </div>
    </WithNavigation>
  )
}
