import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../../../components/PageHeader/PageHeader'
import PromoRanking from '../../../components/Promo/PromoRanking/PromoRanking'
import SecondaryLayout from '../../../layouts/SecondaryLayout/SecondaryLayout'
import styles from './PromoDetails.module.scss'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner/Spinner'
import PromoItem from "../../../components/Promo/PromoItem/PromoItem";
import PromoRewards from "../../../components/Promo/PromoRewards/PromoRewards";
import PromoTasks from "../../../components/Promo/PromoTasks/PromoTasks";
import axiosInstance from "../../../api/config";
import { store } from "../../../store";
import { isIOS, isMobile } from 'react-device-detect'

export default function PromoDetails() {
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState<'soon' | 'ongoing' | 'finalization' | 'ended'>('soon');

  const { profile } = useSelector((state: any) => state.profile)
  const { promoDetails } = useSelector((state: any) => state.promo)

  const { t } = useTranslation()
  const { id } = useParams()
  const { dispatch } = store

  const getStatus = (status: 'soon' | 'ongoing' | 'finalization' | 'ended') => {
    setStatus(status)
  }

  const fetchPromoDetails = async () => {
    try {
      const { data } =
        await axiosInstance.get(`/api/promos/${id}`,{
          params: {
            userId: profile?._id,
          },
        })

      dispatch({ type: 'promo/setPromoDetails', payload: data?.data })

      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (id && profile?._id) {
      fetchPromoDetails()
    }
  }, [id, profile?._id])

  return (
    <SecondaryLayout
      header={<PageHeader black={true} title={t('promo.promo_details')} />}
      promo={true}
      black={true}
    >
      {isLoading ? (
        <div className={styles.page__loader}>
          <Spinner color="white" />
        </div>
      ) : (
        <div className={`${isIOS && isMobile && styles.details}`}>
          <PromoItem
            id={promoDetails?._id}
            description={promoDetails?.shortDecsription}
            reward={promoDetails?.reward}
            currency={promoDetails?.currency}
            startDate={promoDetails?.startDate}
            endDate={promoDetails?.endDate}
            resultDate={promoDetails?.resultDate}
            title={promoDetails?.title}
            isLink={false}
            sponsor={promoDetails?.sponsor}
            getStatus={getStatus}
            winners={promoDetails?.winners}
          />
          {status === 'ongoing' && (
            <PromoTasks
              reward={promoDetails?.rewardsList[0]?.reward}
              joined={promoDetails?.isParticipant?.participantStatus}
              tasks={promoDetails?.conditionsCheck?.conditions}
              announce={promoDetails?.resultDate}
            />
          )}
          {status === 'finalization' && (
            <div className={styles.page__status}>
              <div className={styles.page__status__title}>Promo Ended</div>
              <div className={styles.page__status__description}>{promoDetails?.isParticipant?.participantStatus
                ? 'You participated in this promo, wait for the winners to be announced'
                : 'You did not participate in this promo'}
              </div>
            </div>
          )}
          {status === 'ended' && (
            <div className={styles.page__status}>
              <div className={styles.page__status__title}>Promo Ended</div>
              <div className={styles.page__status__description}>{promoDetails?.isParticipant?.participantStatus
                ? "Congratulations, you've one of the winners in this promotion"
                : 'You did not participate in this promo'}
              </div>
            </div>
          )}
          {status !== 'ended' && (
            <PromoRewards rewards={promoDetails?.rewardsList} />
          )}
          {promoDetails?.winners?.length && (
            <PromoRanking isParticipate={promoDetails?.isParticipant?.participantStatus} count={promoDetails?.participantsCount} rewards={promoDetails?.rewardsList} winners={promoDetails?.winners} />
          )}

          <div className={styles.block}>
            <Link to={`/home/promo/${id}/rules`} className={styles.block__footer}>
              {t('promo.open_rules')}
            </Link>
          </div>
        </div>
      )}
    </SecondaryLayout>
  )
}
