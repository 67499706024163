import { useEffect, useRef, useState } from 'react'
import styles from './SplashScreen.module.scss'

import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../components/Spinner/Spinner'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'

import axiosInstance from '../../../api/config'
import { store } from '../../../store'
import ReCAPTCHA from 'react-google-recaptcha'

export default function SplashScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const recaptchaRef = useRef<any>(null)
  const { dispatch } = store

  const [isLoading, setIsLoading] = useState(true)
  const [isShowContent, setIsShowContent] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsShowContent(true)
      }, 100)
    }
  }, [isLoading])

  const socialLogin = async (network: string, accessToken: string) => {
    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body: any = {
        accessToken,
        network,
      }

      const refId = sessionStorage.getItem('refId')

      if (refId) {
        body.refId = refId
      }

      const response = await axiosInstance.post(
        '/api/auth/social-login',
        body,
        {
          headers: {
            recaptcha: token,
          },
        },
      )

      dispatch({ type: 'auth/login', payload: response.data.token })
      navigate('/home')
    } catch (e: any) {}
  }

  const authWithFacebook = () => {
    window.FB.getLoginStatus((loginStatus: any) => {
      if (loginStatus.status === 'connected') {
        window.FB.logout()
      }

      window.FB.login((response: any) => {
        if (response.authResponse) {
          socialLogin('facebook', response?.authResponse.accessToken || '')
        }
      })
    })
  }

  return (
    <div className={styles.page}>
      <div>
        <div
          className={`${styles.page__block} ${!isShowContent ? styles['page__block--loading'] : ''}`}
        >
          <LogoIcon />
        </div>
        <div className={styles.page__beta}>
          <div>
            <span>{t('splashScreen.beta_start')}</span>
            <span className={styles.page__beta__bold}>{t('splashScreen.beta')}</span>
            <span>{t('splashScreen.beta_end')}</span>
          </div>
          <div>
            <span>{t('splashScreen.feedback')}</span>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className={styles.page__loader}>
          <Spinner size={42} />
        </div>
      )}

      {!isLoading ? (
        <div
          className={`${styles.page__content} ${isShowContent ? styles['page__content--shown'] : ''} `}
        >
          <h2 className={styles.page__heading}>{t('splashScreen.title')}</h2>
          <Link to="/registration" className="button">
            {t('splashScreen.sign_up')}
          </Link>
          <div className={styles.page__divider}>{t('splashScreen.or')}</div>
          <div className={styles.page__buttons}>
            <div className={styles.page__googleContainer}>
              <div className={styles.page__googleButton}>
                <GoogleLogin
                  ux_mode="popup"
                  shape="square"
                  width={56}
                  locale="en"
                  type="icon"
                  onSuccess={(credentialResponse) => {
                    socialLogin('google', credentialResponse.credential || '')
                  }}
                />
              </div>

              <div
                className={`button button--outline ${styles.page__socialButton} ${styles.page__overflowButton}`}
              >
                <GoogleIcon />
              </div>
            </div>
            <button
              className={`button button--outline ${styles.page__socialButton}`}
              onClick={authWithFacebook}
            >
              {' '}
              <FacebookIcon />
            </button>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            style={{
              display: 'none',
            }}
          />

          <p className={styles.page__footer}>
            {t('splashScreen.have_an_account')}{' '}
            <Link to="/login">{t('splashScreen.login')}</Link>
          </p>
        </div>
      ) : null}
    </div>
  )
}
