import { useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

interface IProps {
  bottom: number
}

export default function ToastsContainer({ bottom = 100 }: IProps) {
  const location = useLocation()

  useEffect(() => {
    toast.remove()
  }, [location.pathname])

  return (
    <Toaster
      toastOptions={{
        duration: 3000,
      }}
      position="bottom-center"
      containerStyle={{
        bottom,
      }}
    />
  )
}
