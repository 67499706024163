import RestrictionBrowser from '../../components/Restriction/RestrictionBrowser/RestrictionBrowser'
import RestrictionMobile from '../../components/Restriction/RestrictionMobile/RestrictionMobile'
import styles from './RestrictionScreen.module.scss'

import { isMobileOnly } from 'react-device-detect'

export default function RestrictionScreen() {
  const notMobileSize = window.innerWidth > 768
  return (
    <div className={styles.page}>
      {isMobileOnly && !notMobileSize ? <RestrictionMobile /> : <RestrictionBrowser />}
    </div>
  )
}
