import styles from './Input.module.scss'

import { ReactComponent as EyeOpenedIcon } from '@assets/icons/eye_open.svg'
import { ReactComponent as EyeClosedIcon } from '@assets/icons/eye_close.svg'
import { ReactElement, useCallback, useRef, useState } from 'react'

interface IProps {
  name: string
  label?: string
  placeholder: string
  type?: 'text' | 'password' | 'email' | 'number'
  isError?: boolean
  errors?: any
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyDown?: (e: any) => void
  value?: string
  id: string
  disabled?: boolean
  requiredFlag?: boolean
  min?: number
  maxLength?: number
  isPreventScroll?: boolean
  editable?: boolean
  leftContent?: ReactElement | null
  rightContent?: ReactElement | null
}

export default function Input({
  name,
  label,
  placeholder,
  type = 'text',
  isError = false,
  errors = [],
  onChange = (e: any) => {},
  onBlur = (e: any) => {},
  onKeyDown = (e: any) => {},
  onFocus = (e: any) => {},
  value = '',
  id = '',
  disabled = false,
  requiredFlag = false,
  min,
  maxLength = 1000,
  isPreventScroll = false,
  editable = true,
  leftContent = null,
  rightContent = null
}: IProps) {
  const [inputType, setInputType] = useState<
    'text' | 'password' | 'email' | 'number'
  >(type)
  const ref = useRef<HTMLInputElement | null>(null)

  const togglePassword = (e: any) => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  const preventScroll = useCallback(() => {
    if (window.scrollY > 150) {
      window.scrollTo({
        top: 150,
      })
    }
  }, [])

  const handleFocus = (e: any) => {
    if (isPreventScroll) {
      window.addEventListener('scroll', preventScroll, true)
    }
  }

  const handleBlur = (e: any) => {
    window.removeEventListener('scroll', preventScroll, true)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (ref.current) {
        ref.current.blur()

        return
      }
    }

    onKeyDown(e)
  }

  return (
    <div
      className={`${styles.block} ${isError ? styles['block--invalid'] : ''}  ${!editable && styles['block--notEditable']}`}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <label htmlFor={name} className={styles.block__label}>
        {label} {requiredFlag ? <span>*</span> : null}
      </label>
      <div
        className={`${styles.block__container} ${disabled && styles.block__container__disabled}`}
      >
        {leftContent && <div className={styles.block__leftContent}>{leftContent}</div>}
        <input
          ref={ref}
          id={id}
          type={inputType}
          className={styles.block__input}
          placeholder={placeholder}
          autoComplete="one-time-code"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          min={min || ''}
          maxLength={maxLength}
        />
        {rightContent && <div className={styles.block__rightContent}>{rightContent}</div>}
        {type === 'password' && (
          <button
            onClick={togglePassword}
            type="button"
            className={`${styles.block__passwordButton} ${inputType === 'text' ? styles['block__passwordButton--active'] : ''}`}
          >
            {inputType === 'password' ? <EyeClosedIcon /> : <EyeOpenedIcon />}
          </button>
        )}
      </div>
      {isError && errors.length ? (
        <div className={styles.block__errors}>
          <p className={styles.block__error}>{errors}</p>
        </div>
      ) : null}
    </div>
  )
}
