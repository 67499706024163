import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { PASSWORD_REGEX } from "../../constants/validation"
import Input from "../Input/Input"
import axiosInstance from "../../api/config"
import { forwardRef, useRef, useState } from 'react'
import {useTranslation} from "react-i18next";
import styles from "../Auth/ForgotPassword/ForgotPasswordSetPasswordForm/ForgotPasswordSetPasswordForm.module.scss";

const SignupSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .matches(PASSWORD_REGEX, i18next.t('validation.invalid_password')),
  password: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .matches(PASSWORD_REGEX, i18next.t('validation.invalid_password')),
  repeatPassword: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .test(
      'passwords-match',
      i18next.t('validation.password_match'),
      function (value) {
        const { password } = this.parent

        return value === password
      },
    ),
})

interface IProps {
  ref?: any
  nextStep: () => void
}

interface ISetNewPasswordBody {
  oldPassword: string
  password: string
  repeatPassword: string
}

const SetNewPasswordForm = forwardRef(
  ({ nextStep }: IProps, ref) => {
    const { t } = useTranslation()

    const [isPending, setIsPending] = useState(false)
    const [serverError, setServerError] = useState('')

    const formik = useFormik({
      initialValues: {
        oldPassword: '',
        password: '',
        repeatPassword: '',
      },
      validationSchema: SignupSchema,
      onSubmit: async (values: any) => {
        if (isPending) return

        await submitForm(values)
      },
    })

    const submitForm = async (values: any) => {
      setIsPending(true)
      setServerError('')

      try {
        const body: ISetNewPasswordBody = {
          oldPassword: values.oldPassword.trim().replaceAll(/\s/g,''),
          password: values.password.trim().replaceAll(/\s/g,''),
          repeatPassword: values.repeatPassword.trim().replaceAll(/\s/g,''),
        }
        await axiosInstance.post(`/api/users/change-password`, body)

        setIsPending(false)
        nextStep()
      } catch (e: any) {
        setServerError(e?.response?.data?.data?.message || '')
        setIsPending(false)
      }
    }

    return (
      <form
        ref={ref as any}
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.form__row}>
          <Input
            id="oldPassword"
            name="oldPassword"
            placeholder={t('registerScreen.current_password_placeholder')}
            label={t('registerScreen.current_password_label')}
            type="password"
            value={formik.values.oldPassword.trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.oldPassword}
            isError={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
          />
        </div>
        <div className={styles.form__row}>
          <Input
            id="password"
            name="password"
            placeholder={t('resetPasswordScreen.password_placeholder')}
            label={t('loginScreen.new_password_label')}
            type="password"
            value={formik.values.password.trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.password}
            isError={formik.touched.password && Boolean(formik.errors.password)}
          />
        </div>
        <div className={styles.form__row}>
          <div className={styles.form__email}>
            <Input
              id="repeatPassword"
              name="repeatPassword"
              placeholder={t('resetPasswordScreen.password_placeholder')}
              label={t('registerScreen.confirm_new_password_label')}
              type="password"
              value={formik.values.repeatPassword.trim()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.repeatPassword}
              isError={
                formik.touched.repeatPassword &&
                Boolean(formik.errors.repeatPassword)
              }
            />
          </div>
        </div>
        <div className={styles.form__errorContainer}>
          {serverError && <p className="form__error">{serverError}</p>}{' '}
        </div>
      </form>
    )
})

export default SetNewPasswordForm