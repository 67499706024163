import styles from './EditChange.module.scss'
import { useNavigate } from "react-router-dom";

interface IProps {
    title: string,
    label?: string,
    button: string
    password?: boolean
}

export default function EditChange({ title, label, button, password = false }: IProps) {
  const navigate = useNavigate()

    return (
        <div>
            <div className={styles.editChange__subtitle}>{title}</div>
            <div className={styles.editChange__email}>{label}</div>
            <button onClick={password ? () => navigate('/change-password') : () => navigate('/change-email')} className="button">{button}</button>
        </div>
    )
}