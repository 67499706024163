import { useState } from 'react'
import styles from './SelectCategoryScreen.module.scss'
import {ReactComponent as SelectedRoleIcon} from "@assets/icons/categories/selected_role.svg";

import axiosInstance from '../../api/config'
import { useNavigate } from 'react-router-dom'
import { store } from '../../store'
import { useTranslation } from 'react-i18next'
import { ECategories } from '../../types/profile.interface'
import CategoryIcon from '../CategoryIcon/CategoryIcon'

interface ICategoryItem {
  name: string
  value: ECategories
  description: string
}

export default function SelectCategoryScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = store

  const [selectedCategory, setSelectedCategory] =
    useState<ICategoryItem | null>(null)

  const CATEGORIES: ICategoryItem[] = [
    {
      name: t('categories.sommelier.title'),
      value: ECategories.SOMMELIER,
      description: t('categories.sommelier.description'),
    },
    {
      name: t('categories.caviste.title'),
      value: ECategories.CAVISTE,
      description: t('categories.caviste.description'),
    },
    {
      name: t('categories.waiter.title'),
      value: ECategories.WAITER,
      description: t('categories.waiter.description'),
    },
    {
      name: t('categories.wine_lover.title'),
      value: ECategories.WINE_LOVER,
      description: t('categories.wine_lover.description'),
    },
    {
      name: t('categories.producer.title'),
      value: ECategories.PRODUCER,
      description: t('categories.producer.description'),
    },
    {
      name: t('categories.winemaker.title'),
      value: ECategories.WINEMAKER,
      description: t('categories.winemaker.description'),
    },
    {
      name: t('categories.grape_grower.title'),
      value: ECategories.GRAPE_GROWER,
      description: t('categories.grape_grower.description'),
    },
    {
      name: t('categories.education.title'),
      value: ECategories.EDUCATION,
      description: t('categories.education.description'),
    },
    {
      name: t('categories.associations.title'),
      value: ECategories.ASSOCIATIONS,
      description: t('categories.associations.description'),
    },
    {
      name: t('categories.press.title'),
      value: ECategories.PRESS,
      description: t('categories.press.description'),
    },
    {
      name: t('categories.influencer.title'),
      value: ECategories.INFLUENCER,
      description: t('categories.influencer.description'),
    },
    {
      name: t('categories.distributor.title'),
      value: ECategories.DISTRIBUTOR,
      description: t('categories.distributor.description'),
    },
    {
      name: t('categories.marketer.title'),
      value: ECategories.MARKETER,
      description: t('categories.marketer.description'),
    },
    {
      name: t('categories.investors.title'),
      value: ECategories.INVESTORS,
      description: t('categories.investors.description'),
    },
    {
      name: t('categories.others.title'),
      value: ECategories.OTHERS,
      description: t('categories.others.description'),
    }
  ]

  const confirm = async () => {
    try {
      await axiosInstance.patch('/api/users/profile/category', {
        category: selectedCategory?.value,
      })

      dispatch({ type: 'profile/getProfile' })
      navigate('/home')
    } catch (e: any) {
      console.error(e.response.data)
    }
  }

  return (
    <div className={styles.block}>
      <p className={styles.block__title}>{t('categories_screen.title')}</p>
      <div className={styles.block__list}>
        {CATEGORIES.map((category: ICategoryItem) => (
          <button
            onClick={() => setSelectedCategory(category)}
            key={category.value}
            type="button"
            className={styles.block__item}
          >
            <div className={styles.block__wrapper}>
              <div className={styles.block__inner}>
                <div className={styles.block__iconContainer}>
                  <CategoryIcon category={category.value} />
                </div>
                <div className={styles.block__name}>{category.name}</div>
              </div>
              {category.value === selectedCategory?.value && <SelectedRoleIcon />}
            </div>
            {category.value === selectedCategory?.value && (
              <div className={styles.block__description}>{category.description}</div>
            )}
          </button>
        ))}
      </div>

      {selectedCategory && (
        <>
          <div className={styles.block__buttonContainer}>
            <button className="button" onClick={confirm}>
              {t('categories_screen.button')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
