import { Models } from '@rematch/core'
import { auth } from './auth'
import { profile } from './profile'
import { geo } from './geo'
import { chats } from './chats'
import { transactions } from './transactions'
import { map } from './map'
import { feed } from './feed'
import { posts } from './posts'
import { promo } from './promo'

export interface RootModel extends Models<RootModel> {
  auth: typeof auth
  profile: typeof profile
  geo: typeof geo
  chats: typeof chats
  transactions: typeof transactions
  map: typeof map
  feed: typeof feed
  posts: typeof posts
  promo: typeof promo
}

export const models: RootModel = {
  auth,
  profile,
  geo,
  chats,
  transactions,
  map,
  feed,
  posts,
  promo
}
