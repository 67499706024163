import { useState } from 'react'
import ScanWine from '../../components/Scan/ScanWine/ScanWine'
import ScanReceipt from '../../components/Scan/ScanReceipt/ScanReceipt'
import IdentifiedWine from '../../components/Scan/IdentifiedWine/IdentifiedWine'
import ReceiptStart from '../../components/Scan/ReceiptStart/ReceiptStart'
import ReceiptConfirm from '../../components/Scan/ReceiptConfirm/ReceiptConfirm'
import WineNotFound from '../../components/Scan/WineNotFound/WineNotFound'
import ScanSuccess from '../../components/Scan/ScanSuccess/ScanSuccess'
import ReceiptNotFound from '../../components/Scan/ReceiptNotFound/ReceiptNotFound'
import WineList from '../../components/Scan/WineList/WineList'
import axiosInstance from '../../api/config'
import { IScanInfoResponse, IScanInfoResult } from '../../types/scan.interface'
import { prepareBase64String } from '../../utils/scan'

enum EScanStep {
  SCAN_WINE = 'scan_wine',
  WINE_IDENTIFIED = 'wine_identified',
  RECEIPT_START = 'receipt_start',
  SCAN_RECEIPT = 'scan_receipt',
  RECEIPT_CONFIRM = 'receipt_confirm',
  SUCCESS_SCREEN = 'success_screen',
  WINE_ERROR = 'wine_error',
  RECEIPT_ERROR = 'receipt_error',
  RESULTS_LIST = 'results_list',
}

export default function ScanScreen() {
  const [step, setStep] = useState<EScanStep>(EScanStep.SCAN_WINE)
  const [receiptPhoto, setReceiptPhoto] = useState<string[]>([])
  const [prevStep, setPrevStep] = useState<EScanStep | null>(null)
  const [scanId, setScanId] = useState<string | null>(null)
  const [scanInfo, setScanInfo] = useState<IScanInfoResult | null>(null)
  const [wineInfo, setWineInfo] = useState<any>(null)

  const getScanInfo = async (id: string): Promise<void> => {
    try {
      const { data } = await axiosInstance.get<IScanInfoResponse>(
        `/api/scans/${id}`,
      )

      setScanId(id)
      setScanInfo(data.data)
      toNextStep(EScanStep.RESULTS_LIST)
    } catch (e: any) {}
  }

  const handleScanId = async (id: string) => {
    await getScanInfo(id)
  }

  const handleReceiptPhoto = async (value: string) => {
    const image = prepareBase64String(value)

    try {
      await axiosInstance.post(`/api/scans/${scanId}/receipt`, {
        image,
        retry: receiptPhoto.length > 0,
      })

      setReceiptPhoto([...receiptPhoto, value])
      toNextStep(EScanStep.RECEIPT_CONFIRM)
    } catch (e: any) {
      setStep(EScanStep.RECEIPT_ERROR)
    }
  }

  const onScanReceiptBack = () => {
    if (prevStep === EScanStep.RECEIPT_CONFIRM) {
      toNextStep(EScanStep.RECEIPT_CONFIRM)

      return
    }

    if (receiptPhoto.length > 0) {
      toNextStep(EScanStep.RECEIPT_CONFIRM)
    } else {
      toNextStep(EScanStep.RECEIPT_START)
    }
  }

  const onConfirmReceiptBack = () => {
    setReceiptPhoto((prev) => prev.slice(0, -1))
    toNextStep(EScanStep.SCAN_RECEIPT)
  }

  const toNextStep = (value: EScanStep) => {
    setPrevStep(step)
    setStep(value)
  }

  const onBackFromReceiptError = () => {
    setStep(EScanStep.SCAN_RECEIPT)
  }

  const onBackFromWineError = () => {
    setStep(EScanStep.SCAN_WINE)
  }

  const handleSelectWine = async (choice: number) => {
    try {
      await axiosInstance.post(`/api/scans/${scanId}/choice`, {
        choice,
      })

      setWineInfo(scanInfo?.wineSearchInfo[choice])
      toNextStep(EScanStep.WINE_IDENTIFIED)
    } catch (e: any) {}
  }

  const handleConfirmReceipt = async () => {
    try {
      await axiosInstance.post(`/api/scans/${scanId}/confirm`)

      toNextStep(EScanStep.SUCCESS_SCREEN)
    } catch (e: any) {}
  }

  const handleBackToList = async () => {
    toNextStep(EScanStep.RESULTS_LIST)
  }

  return (
    <>
      {step === EScanStep.SCAN_WINE && (
        <ScanWine
          onScanId={handleScanId}
          onError={() => setStep(EScanStep.WINE_ERROR)}
        />
      )}

      {step === EScanStep.RESULTS_LIST && (
        <WineList
          list={scanInfo?.wineSearchInfo || []}
          onSelect={handleSelectWine}
          onBack={() => setStep(EScanStep.SCAN_WINE)}
        />
      )}

      {step === EScanStep.WINE_IDENTIFIED && (
        <IdentifiedWine
          data={wineInfo}
          onNext={() => toNextStep(EScanStep.RECEIPT_START)}
          onTryAgain={() => toNextStep(EScanStep.SCAN_WINE)}
          onBack={() => handleBackToList()}
        />
      )}

      {step === EScanStep.RECEIPT_START && (
        <ReceiptStart
          onBack={() => toNextStep(EScanStep.WINE_IDENTIFIED)}
          onTakePhoto={() => toNextStep(EScanStep.SCAN_RECEIPT)}
        />
      )}

      {step === EScanStep.SCAN_RECEIPT && (
        <ScanReceipt
          onTakePhoto={handleReceiptPhoto}
          onBack={onScanReceiptBack}
          onError={() => setStep(EScanStep.RECEIPT_ERROR)}
        />
      )}

      {step === EScanStep.RECEIPT_CONFIRM && (
        <ReceiptConfirm
          onConfirm={handleConfirmReceipt}
          onAddAnother={() => toNextStep(EScanStep.SCAN_RECEIPT)}
          isFirstPhoto={receiptPhoto.length === 1}
          photo={receiptPhoto[receiptPhoto.length - 1]}
          onBack={onConfirmReceiptBack}
        />
      )}

      {step === EScanStep.SUCCESS_SCREEN && <ScanSuccess />}

      {step === EScanStep.WINE_ERROR && (
        <WineNotFound onBack={onBackFromWineError} />
      )}

      {step === EScanStep.RECEIPT_ERROR && (
        <ReceiptNotFound onBack={onBackFromReceiptError} />
      )}
    </>
  )
}
