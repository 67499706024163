import { useState } from 'react'
import styles from './WithdrawModal.module.scss'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import i18next from 'i18next'
import { store } from '../../../store'

import { ReactComponent as USDCIcon } from '@assets/icons/usdc_small.svg'
import { ReactComponent as MaticIcon } from '@assets/icons/matic.svg'

import ModalContainer from '../../ModalContainer/ModalContainer'
import Input from '../../Input/Input'
import SelectInput from '../../SelectInput/SelectInput'
import Spinner from '../../Spinner/Spinner'

import axiosInstance from '../../../api/config'
import useNotification from '../../../hooks/useNotification'

interface Types {
  'to must be an Ethereum address': string
  'Something went wrong': string
  'Estimation is not correct': string
  'Not enough funds to pay': string
  'Insufficient funds': string
  MATIC: string
}

interface IProps {
  open: boolean
  onClose: () => void
}

const CodeSchemaMarketing = Yup.object().shape({
  amount: Yup.number()
    .min(20, i18next.t('withdraw.minimum_marketing'))
    .required(i18next.t('validation.required')),
})

export default function WithdrawModal({ open, onClose }: IProps) {
  const { t } = useTranslation()
  const { dispatch } = store

  const [selectValue, setSelectValue] = useState<string>('marketing')
  const [isPending, setIsPending] = useState(false)
  const [systemFee, setSystemFee] = useState('')
  const [maticFee, setMaticFee] = useState('')
  const [estimationId, setEstimationId] = useState('')

  const { profile } = useSelector((state: any) => state.profile)

  const notification = useNotification()

  const options = [
    {
      value: 'marketing',
      label: (
        <div className={styles.select}>
          <div className={styles.select__title}>{t('withdraw.marketing')}</div>
          <div className={styles.select__balance}>
            <USDCIcon />
            <span className={styles.select__count}>
              {Number(profile?.balance?.marketing?.usdc).toLocaleString(
                'es-US',
                { minimumFractionDigits: 4, maximumFractionDigits: 4 },
              ) || '0.0000'}
            </span>
            <span>USDC</span>
          </div>
        </div>
      ),
    },
    {
      value: 'main',
      label: (
        <div className={styles.select}>
          <div className={styles.select__title}>{t('withdraw.main')}</div>
          <div className={styles.select__balance}>
            <USDCIcon />
            <span className={styles.select__count}>
              {Number(profile?.balance?.main[1]?.balance).toLocaleString(
                'es-US',
                { minimumFractionDigits: 4, maximumFractionDigits: 4 },
              ) || '0.0000'}
            </span>
            <span>USDC</span>
          </div>
        </div>
      ),
    },
  ]

  const getSelectValue = (value: string) => {
    setSelectValue(value)
    formik.setFieldValue('amount', 1)
    formik.setFieldValue('to', '')
  }

  const errorTypes: Types = {
    'to must be an Ethereum address': t('editProfileScreen.invalid_address'),
    'Something went wrong': t('editProfileScreen.went_wrong'),
    'Estimation is not correct': t('editProfileScreen.estimation_wrong'),
    'Not enough funds to pay': t('editProfileScreen.not_enough_funds'),
    'Insufficient funds': t('editProfileScreen.not_enough_funds'),
    MATIC: t('editProfileScreen.matic_enough'),
  }

  const formik = useFormik({
    initialValues: {
      amount: '',
      to: '',
    },
    validationSchema: selectValue === 'marketing' && CodeSchemaMarketing,
    onSubmit: async (values: any) => {
      await submitForm(values)
    },
  })

  const closeModal = () => {
    onClose()
    setEstimationId('')
  }

  const setError = (e: any) => {
    let errorMessage =
      errorTypes[e?.response?.data?.data?.message[0] as keyof Types]

    if (
      typeof e?.response?.data?.data?.message === 'string' &&
      !e?.response?.data?.data?.message?.includes('You don')
    ) {
      errorMessage = errorTypes[e?.response?.data?.data?.message as keyof Types]
    } else if (
      typeof e?.response?.data?.data?.message === 'string' &&
      e?.response?.data?.data?.message?.includes('You don')
    ) {
      errorMessage = errorTypes['MATIC']
    } else {
      errorMessage =
        errorTypes[e?.response?.data?.data?.message[0] as keyof Types]
    }

    notification.error(errorMessage)
  }

  const submitForm = async (values: any) => {
    setIsPending(true)

    if (selectValue === 'marketing') {
      try {
        await axiosInstance.post('/api/transactions/withdrawal/marketing', {
          ...values,
        })

        setIsPending(false)
        onClose()
        dispatch({ type: 'profile/getProfile' })
        setSelectValue('marketing')
      } catch (e: any) {
        setIsPending(false)
        onClose()
        setError(e)
        setSystemFee('')
        setSelectValue('marketing')
      }

      formik.setValues({
        amount: '',
        to: '',
      })
    }

    if (selectValue === 'main' && !estimationId) {
      try {
        const response = await axiosInstance.post(
          '/api/transactions/withdrawal/estimate',
          {
            ...values,
            currency: 'USDC',
          },
        )

        dispatch({ type: 'profile/getProfile' })
        setSystemFee(response?.data?.data?.systemFee)
        setMaticFee(response?.data?.data?.minerFee)
        setEstimationId(response?.data?.data?.estimationId)
        setIsPending(false)
      } catch (e: any) {
        setIsPending(false)
        onClose()
        setError(e)
        setSelectValue('marketing')
        formik.setValues({
          amount: '',
          to: '',
        })
      }
    }

    if (selectValue === 'main' && estimationId) {
      try {
        await axiosInstance.post('/api/transactions/withdrawal', {
          estimationId,
        })

        setIsPending(false)
        setEstimationId('')
        setSystemFee('')
        setMaticFee('')
        setSelectValue('marketing')
        onClose()
        dispatch({ type: 'profile/getProfile' })
      } catch (e: any) {
        setIsPending(false)
        onClose()
        setError(e)
        setSystemFee('')
        setEstimationId('')
        setSelectValue('marketing')
      }

      formik.setValues({
        amount: '',
        to: '',
      })
    }
  }

  return (
    <div className={styles.withdraw}>
      <ModalContainer isOpen={open} onClose={closeModal}>
        <div className={styles.withdraw__title}>{t('withdraw.title')}</div>
        <div className={styles.withdraw__subtitle}>
          {t('withdraw.set_withdraw')}
        </div>
        <div className={styles.withdraw__row}>
          <SelectInput
            name="balance"
            options={options}
            placeholder={t('withdraw.balance')}
            label={t('withdraw.balance')}
            getSelectValue={getSelectValue}
            isDisabled={Boolean(estimationId)}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.withdraw__row}>
            <Input
              id="amount"
              name="amount"
              type="number"
              placeholder="0.00"
              disabled={Boolean(estimationId)}
              label={t('withdraw.amount')}
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.amount}
              isError={
                formik.touched.amount &&
                Boolean(formik.errors.amount) &&
                selectValue === 'marketing'
              }
            />
            <div className={styles.form__errorContainer}>
              {!formik.errors.amount && selectValue === 'marketing' && (
                <span className={styles.withdraw__warning}>
                  {t('withdraw.minimum_marketing')}
                </span>
              )}
              {!formik.errors.amount && selectValue === 'main' && (
                <span className={styles.withdraw__warning}>
                  {t('withdraw.minimum_main')}
                </span>
              )}
            </div>
          </div>
          <div
            className={`${styles.withdraw__row} ${systemFee && styles.withdraw__row__bottom}`}
          >
            <Input
              id="to"
              name="to"
              type="text"
              disabled={Boolean(estimationId)}
              placeholder={t('withdraw.recipient_wallet')}
              label={t('withdraw.send_to')}
              value={formik.values.to}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.to}
              isError={formik.touched.to && Boolean(formik.errors.to)}
            />
          </div>
          {systemFee && selectValue === 'main' && (
            <div className={styles.withdraw__fee}>
              <div className={styles.withdraw__fee__title}>
                {t('withdraw.fees')}
              </div>
              <div
                className={`${styles.select} ${styles.select__balance__top}`}
              >
                <div className={styles.select__titles}>
                  {t('withdraw.network_fee')}
                </div>
                <div className={styles.select__balance}>
                  <MaticIcon />
                  <span className={styles.select__number}>
                    {Number(maticFee).toLocaleString('es-US', {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                  </span>
                  <span className={styles.select__number}>MATIC</span>
                </div>
              </div>
              {+systemFee > 0 && (
                <div
                  className={`${styles.select} ${styles.select__balance__top}`}
                >
                  <div className={styles.select__titles}>
                    {t('withdraw.network_fee')}
                  </div>
                  <div className={styles.select__balance}>
                    <USDCIcon />
                    <span className={styles.select__number}>
                      {Number(systemFee).toLocaleString('es-US', {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      })}
                    </span>
                    <span className={styles.select__number}>USDC</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {!estimationId && (
            <button
              disabled={
                !formik.values.amount ||
                (+formik.values.amount < 20 && selectValue === 'marketing') ||
                (+formik.values.amount < 1 && selectValue === 'main') ||
                !formik.values.to ||
                (selectValue === 'marketing' &&
                  profile?.balance?.marketing?.usdc === 0) ||
                (selectValue === 'main' &&
                  profile?.balance?.main[1]?.balance === '0')
              }
              className={`button ${styles.withdraw__button}`}
            >
              {isPending ? (
                <Spinner size={32} color="white" />
              ) : selectValue === 'marketing' ? (
                t('withdraw.withdraw')
              ) : (
                t('withdraw.approve')
              )}
            </button>
          )}
          {estimationId && (
            <button className={`button ${styles.withdraw__button}`}>
              {isPending ? (
                <Spinner size={32} color="white" />
              ) : (
                t('withdraw.withdraw')
              )}
            </button>
          )}
        </form>
      </ModalContainer>
    </div>
  )
}
