import { useState } from 'react'
import ModalContainer from '../../ModalContainer/ModalContainer'
import { useTranslation } from 'react-i18next'
import styles from './MoreOptions.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg'
import { ReactComponent as CopyIcon } from '@assets/icons/copy_outline.svg'
import { ReactComponent as InviteIcon } from '@assets/icons/invite.svg'
import { ReactComponent as LogOutIcon } from '@assets/icons/log_out.svg'
import { ReactComponent as WalletIcon } from '@assets/icons/wallet.svg'
import useNotification from '../../../hooks/useNotification'

interface IProps {
  open: boolean
  onClose: () => void
  activeButton: (active: string) => void
}

export default function MoreOptions({ open, onClose, activeButton }: IProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { profile } = useSelector((state: any) => state.profile)
  const notification = useNotification()

  const options = [
    {
      icon: <EditIcon />,
      title: t('more.edit'),
      value: 'edit',
    },
    {
      icon: <CopyIcon />,
      title: t('more.copy'),
      value: 'copy',
    },
    {
      icon: <InviteIcon />,
      title: t('more.invite'),
      value: 'invite',
    },
    {
      icon: <LogOutIcon />,
      title: t('more.log_out'),
      value: 'logout',
    },
  ]

  const activeModal = (active: string) => {
    if (active === 'edit') {
      navigate('/edit-profile')
    } else if (active === 'copy') {
      const link = profile?.balance?.main[1]?.address
      navigator.clipboard.writeText(link)
      notification.info(t('more.copied'), <WalletIcon />)
      onClose()
    } else {
      activeButton(active)
      onClose()
    }
  }

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div className={styles.more}>
        {options.map((item) => (
          <div
            key={item.value}
            className={styles.more__button}
            onClick={() => activeModal(item.value)}
          >
            {item.icon}
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    </ModalContainer>
  )
}
