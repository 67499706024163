import styles from './Sorting.module.scss'
import { ReactComponent as SortIcon } from '@assets/icons/sort.svg'
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow_bold.svg'
import { useEffect, useRef, useState } from 'react'
import { ISortingValue } from '../../types/sorting.interface'
import { useTranslation } from 'react-i18next'

interface IProps {
  values: ISortingValue[]
  selectedValue?: string | null
  onSelect?: (value: string | null) => void
  disabled?: boolean
}

export default function Sorting({
  values = [],
  selectedValue = null,
  onSelect,
  disabled = false,
}: IProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef<any>(null)

  const handleSelect = (value: string | null) => {
    setIsOpen(false)

    if (onSelect) {
      onSelect(value)
    }
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wrapperRef])
  return (
    <div className={styles.block} ref={wrapperRef}>
      <div className={styles.block__info}>
        {/* <SortIcon /> */}
        <p>{t('map.ranking.show')}</p>
      </div>
      <button
        className={styles.block__button}
        onClick={() => setIsOpen((prev) => !prev)}
        disabled={disabled}
      >
        {values.find((value: ISortingValue) => value.value === selectedValue)
          ?.label || 'All'}
        <ArrowIcon />
      </button>

      {isOpen && (
        <div className={styles.block__dropdown}>
          {values.map((value: ISortingValue) => (
            <button
              type="button"
              key={value.value}
              className={`${styles.block__item} ${value.value === selectedValue ? styles['block__item--active'] : ''}`}
              onClick={() => handleSelect(value.value)}
            >
              {value.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
