import { useState, useEffect } from 'react'
import styles from './ProfileHeader.module.scss'

import { ReactComponent as MedalIcon } from '@assets/icons/medal.svg'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg'

import { ReactComponent as NotificationIcon } from '@assets/icons/notification.svg'
import { ReactComponent as DotsIcon } from '@assets/icons/dots.svg'
import { ReactComponent as ArrowIcon } from '@assets/icons/chevron.svg'
import { ReactComponent as ProfileConfirm } from '@assets/icons/profile-confirm.svg'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import MoreOptions from '../../modals/MoreOptions/MoreOptions'
import InviteModal from '../../modals/InviteModal/InviteModal'
import LogOutModal from '../../modals/LogOutModal/LogOutModal'
import { IProfile } from '../../../types/profile.interface'
import Avatar from '../../Avatar/Avatar'
import { IMAGE_URL } from '../../../constants'
import axiosInstance from '../../../api/config'
import { IChatUser } from '../../../types/chat.interface'

// @ts-ignore
import debounce from 'lodash.debounce'
import CategoryIcon from '../../CategoryIcon/CategoryIcon'
import { ELevel, levelColor } from '../../../constants/level'

interface IProps {
  data: IProfile
  isAlternate?: boolean
  isViewable?: boolean
  refetchUser: () => void
  wineBottles?: number
}

export default function ProfileHeader({
  data,
  isAlternate,
  isViewable = true,
  refetchUser,
  wineBottles,
}: IProps) {
  const { profile } = useSelector((state: any) => state.profile)

  const [isFixed, setIsFixed] = useState(false)
  const [isOpenMore, setIsOpenMore] = useState(false)
  const [isOpenLogOut, setIsOpenLogOut] = useState(false)
  const [isOpenInvite, setIsOpenInvite] = useState(false)
  const [chatData, setChatData] = useState<IChatUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (profile) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [profile])

  const getActiveModal = (active: string) => {
    if (active === 'invite') {
      setIsOpenInvite(true)
    } else if (active === 'logout') {
      setIsOpenLogOut(true)
    }
  }

  useEffect(() => {
    if (isAlternate) {
      fetchChatUser()
    }
  }, [isAlternate])

  const handleScroll = debounce(() => {
    const container = document.getElementById('layout_with_navigation')
    const scrollTop = container?.scrollTop || 0

    if (scrollTop > 116) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  }, 50)

  useEffect(() => {
    const container = document.getElementById('layout_with_navigation')
    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const fetchChatUser = async () => {
    try {
      const { data: response } = await axiosInstance.get<IChatUser[]>(
        `${process.env.REACT_APP_CHAT_API_URL}/api/user/search?text=${data._id}`,
      )

      if (response && response.length) {
        setChatData(response[0])
      }
    } catch (e: any) {}
  }

  const followUser = async () => {
    try {
      await axiosInstance.post(`/api/users/follow/${data._id}`)

      refetchUser()
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const back = () => {
    if (location.key === 'default') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <div
      className={`${styles.header} ${isFixed && styles['header--fixed']} ${isAlternate ? styles['header--alternate'] : ''}`}
    >
      <div className={`${styles.header__background}`}>
        {data?.background && !isLoading && (
          <>
            <img
              className={`${styles.header__background__image} ${isFixed && styles.header__background__image__fixed}`}
              src={`${IMAGE_URL}/${data?.background}`}
              alt="background"
            />
            <div className={styles.header__backgroundGradient}></div>
          </>
        )}
        <div
          className={styles.header__backgroundName}
          style={{ opacity: isFixed ? 1 : 0 }}
        >
          {data?.name || data?.email || '-'}
        </div>
      </div>
      {data?._id === profile?._id ? (
        <div className={`${styles.header__buttons} `}>
          <Link to="/notifications" className={styles.header__button}>
            <NotificationIcon className={styles.header__buttonIcon} />
            {profile?.hasNotifications && (
              <div className={styles.header__buttonDot}></div>
            )}
          </Link>
          <button
            className={styles.header__button}
            onClick={() => setIsOpenMore(true)}
          >
            <DotsIcon className={styles.header__buttonIcon} />
          </button>
        </div>
      ) : (
        <button className={styles.header__back} type="button" onClick={back}>
          <ArrowIcon />
        </button>
      )}

      <div className={styles.header__avatarWrapper}>
        <div className={`${styles.header__avatarContainer} `}>
          <Avatar
            isLoading={isLoading}
            size={isFixed ? 34 : 130}
            url={data?.avatar ? `${IMAGE_URL}/${data?.avatar}` : ''}
            className={styles.header__avatar}
            color="white"
            disabled={true}
          />
          {!isFixed && data?.receiveNft && (
            <ProfileConfirm
              className={styles.header__avatarContainer__confirmed}
            />
          )}
        </div>
      </div>

      <div className={`${styles.header__name}`}>
        {data?.name || data?.email || '-'}
      </div>
      {isViewable && (
        <>
          <div className={styles.header__position}>
            {data?.workPlace?.company
              ? `Sommelier at ${data?.workPlace?.company}`
              : '-'}
          </div>
          <div className={styles.header__grid}>
            <Link
              to={`/profile/${data._id}/followers`}
              className={`${styles.header__item} ${styles.header__item__follow}`}
            >
              <p className={styles.header__itemValue}>
                {data?.countFollowers || 0}
              </p>
              <p className={styles.header__itemLabel}>
                {t('profile.followers')}
              </p>
            </Link>
            <Link
              to={`/profile/${data._id}/following`}
              className={`${styles.header__item} ${styles.header__item__follow}`}
            >
              <p className={styles.header__itemValue}>
                {data?.countFollowings || 0}
              </p>
              <p className={styles.header__itemLabel}>
                {t('profile.following')}
              </p>
            </Link>
            <div className={styles.header__item}>
              <p className={styles.header__itemValue}>
                {isAlternate
                  ? data.showAchievements
                    ? wineBottles || 0
                    : '-'
                  : wineBottles || 0}
              </p>
              <p className={styles.header__itemLabel}>
                {t('profile.wine_list')}
              </p>
            </div>
          </div>
        </>
      )}

      <div className={styles.header__footer}>
        {isViewable && (
          <>
            <div className={styles.header__row}>
              <div
                className={styles.header__icon}
                style={{ color: levelColor[data?.level || ELevel.STANDARD] }}
              >
                <CategoryIcon category={data?.category} />
              </div>
              <p className={styles.header__text}>{t('profile.level')}</p>
              <p
                onClick={() => navigate('/sommelier-level')}
                className={`${styles.header__text} ${styles.header__level} ${styles['header__text--black']}`}
              >
                {data?.level || '-'}
              </p>
            </div>
            {data?.workPlace?.country || data?.workPlace?.city ? (
              <div className={styles.header__row}>
                <div className={styles.header__icon}>
                  <LocationIcon />
                </div>

                <p className={styles.header__text}>
                  {' '}
                  {data?.workPlace?.city && data.workPlace.city + ','}{' '}
                  {data?.workPlace?.country || ''}
                </p>
              </div>
            ) : null}

            <div className={styles.header__row}>
              <div className={styles.header__icon}>
                <CalendarIcon />
              </div>
              <p className={styles.header__text}>
                {dayjs(data?.createdAt).format('DD MMM YYYY')}{' '}
                {t('profile.joined')}
              </p>
            </div>
          </>
        )}

        {data?._id !== profile?._id && (
          <div className={styles.header__followContainer}>
            {isViewable ? (
              <button
                className={`button ${!!data?.followedStatus?.isFollowed ? 'button--outline' : ''} ${styles.header__followButton}`}
                onClick={followUser}
              >
                {!!data?.followedStatus?.isFollowed ? 'Following' : 'Follow'}
              </button>
            ) : (
              <button
                className={`button ${!!data?.followedStatus?.isFollowedSendRequest ? 'button--outline' : ''} ${styles.header__followButton}`}
                onClick={followUser}
              >
                {!!data?.followedStatus?.isFollowedSendRequest
                  ? 'Request sent'
                  : 'Friend request'}
              </button>
            )}

            <Link
              to={`/chats/${chatData?.id}`}
              className={`button button--outline ${styles.header__followButton}`}
            >
              Message
            </Link>
          </div>
        )}
      </div>

      <MoreOptions
        activeButton={getActiveModal}
        open={isOpenMore}
        onClose={() => setIsOpenMore(false)}
      />
      <InviteModal open={isOpenInvite} onClose={() => setIsOpenInvite(false)} />
      <LogOutModal open={isOpenLogOut} onClose={() => setIsOpenLogOut(false)} />
    </div>
  )
}
