import { NavLink } from 'react-router-dom'
import styles from './NavBar.module.scss'

import { ReactComponent as HomeIcon } from '@assets/icons/nav/home.svg'
import { ReactComponent as ChatIcon } from '@assets/icons/nav/chat.svg'
import { ReactComponent as ScanIcon } from '@assets/icons/nav/scan.svg'
import { ReactComponent as MapIcon } from '@assets/icons/nav/map.svg'
import { ReactComponent as ProfileIcon } from '@assets/icons/nav/user.svg'
import { useTranslation } from 'react-i18next'
import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isIOS, isMobile } from 'react-device-detect'

interface INavigationItem {
  title?: string
  icon: ReactNode
  to: string
  isMain: boolean
}

export default function NavBar() {
  const { profile } = useSelector((state: any) => state.profile)
  const { t } = useTranslation()

  const NAVIGATION = [
    {
      title: t('nav.home'),
      icon: <HomeIcon className={styles.nav__icon} />,
      to: '/home',
      isMain: false,
    },
    {
      title: t('nav.chats'),
      icon: <ChatIcon className={styles.nav__icon} />,
      to: '/chats',
      isMain: false,
    },
    {
      icon: <ScanIcon className={styles.nav__icon} />,
      to: '/scan',
      isMain: true,
    },
    {
      title: t('nav.map'),
      icon: <MapIcon className={styles.nav__icon} />,
      to: '/map',
      isMain: false,
    },
    {
      title: t('nav.profile'),
      icon: <ProfileIcon className={styles.nav__icon} />,
      to: `/profile/${profile?._id}`,
      isMain: false,
    },
  ]

  useEffect(() => {
    NAVIGATION[4].to = `/profile/${profile?._id}`
  }, [profile])

  return (
    <div className={`${styles.nav} ${isIOS && isMobile && styles['nav--ios']}`}>
      {NAVIGATION.map((item: INavigationItem, index: number) => {
        return (
          <NavLink
            key={index}
            to={item.to}
            className={({ isActive }) =>
              `${
                isActive
                  ? `${styles.nav__link} ${styles['nav__link--active']}`
                  : styles.nav__link
              } ${item.isMain ? styles['nav__link--main'] : ''}`
            }
          >
            {item.icon}
            {item.title && (
              <span className={styles.nav__title}>{item.title}</span>
            )}
          </NavLink>
        )
      })}
    </div>
  )
}
