import styles from "../../pages/ThanksScreen/ThanksScreen.module.scss";
import { ReactComponent as CopyIcon } from "@assets/icons/copy.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { useState } from 'react'

interface IProps {
  option: boolean
  onCopy?: () => void
}
export default function Invite({ option = false, onCopy = () => {} }) {
  const { t } = useTranslation()

  const [isCopied, setIsCopied] = useState(false)

  const { profile } = useSelector((state: any) => state.profile)

  const copyLink = () => {
    const link = `${window.origin}?k=${profile?.refId || ''}`
    navigator.clipboard.writeText(link)
    setIsCopied(true)
    onCopy()
  }

  return (
    <div className={`${styles.page__content} ${option && styles.page__content__option}`}>
      <h2 className={styles.page__heading}>{t('thanks.heading')}</h2>
      <div className={`${styles.page__count} ${option && styles.page__count__option}`}>{t('thanks.registered')} {profile?.referredUsers || 0}</div>
      <p className={styles.page__message}>{t('thanks.message')}</p>
      <button
        className={`button button--grey ${styles.page__button}`}
        type="button"
        onClick={copyLink}
      >
        <CopyIcon />
        {isCopied ? t('thanks.copied') : t('thanks.copy_link')}
      </button>
    </div>
  )
}