import { useTranslation } from 'react-i18next'
import styles from './PostFooter.module.scss'

import { ReactComponent as ImageIcon } from '@assets/icons/gallery_plus.svg'

interface IProps {
  onPost: () => void
  onAddImage: () => void
  isPending: boolean
  isImage: boolean
  isDisabled?: boolean
}

export default function PostFooter({
  onAddImage,
  onPost,
  isPending,
  isImage,
  isDisabled = false,
}: IProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <button
        className={`button button--outline ${styles.block__imageButton} `}
        type="button"
        disabled={isPending || isImage}
        onClick={onAddImage}
      >
        <ImageIcon />
      </button>
      <button
        className={`button ${styles.block__button}`}
        type="button"
        disabled={isPending || isDisabled}
        onClick={onPost}
      >
        {t('feed.post')}
      </button>
    </div>
  )
}
