import Select, { components } from 'react-select'
import styles from './SelectInput.module.scss'
import { ReactComponent as Arrow } from "@assets/icons/arrow-down.svg";

interface IProps {
  options: any
  name: string
  placeholder: string
  label: string
  getSelectValue: (value: string) => void
  isDisabled: boolean
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Arrow />
    </components.DropdownIndicator>
  );
};

export default function SelectInput({ options, name, placeholder, label, getSelectValue, isDisabled }: IProps) {
  return (
    <div className={styles.select}>
      <div className={styles.select__label}>{label}</div>
      <Select
        name={name}
        placeholder={placeholder}
        className="react-select"
        styles={{
          option: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',
          }),
        }}
        isSearchable={false}
        classNamePrefix="select-input"
        defaultValue={options[0]}
        options={options}
        onChange={(e) => getSelectValue(e.value)}
        isDisabled={isDisabled}
        components={{ DropdownIndicator }}
      />
    </div>
  )
}
