import { useEffect, useRef, useState } from 'react'
import Input from '../Input/Input'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { IPlaceInputParams } from '../../types/place.interface'

import styles from './PlaceInput.module.scss'

interface IProps {
  onChange: (value: IPlaceInputParams) => void
  initialValue?: string
  type: 'address' | 'place'
  label: string
  placeholder: string
  country: string
  location?: [number, number] | null
  onKeyDown?: (e: any) => void
}

export default function PlaceInput({
  onChange,
  initialValue,
  type,
  label,
  placeholder,
  country,
  location,
  onKeyDown = (e: any) => {}
}: IProps) {
  const wrapperRef = useRef<any>(null)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('')
  const [service, setService] = useState<any>(null)
  const placesLib = useMapsLibrary('places')

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOptions([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  useEffect(() => {
    if (!placesLib) return

    const s = new placesLib.PlacesService(
      document.getElementById('places_container') as HTMLDivElement,
    )

    setService(s)
  }, [placesLib])

  const handleInput = (e: any) => {
    setValue(e.target.value)

    const inputValue = e.target.value

    if (e.target.value.length > 0) {
      service.textSearch(
        {
          query: inputValue,
          region: country,
          type: type === 'address' ? 'locality' : null,
          location: location
            ? {
                lat: location ? location[0] : 0,
                lng: location ? location[1] : 0,
              }
            : null,
        },
        (response: any) => {
          const opts = response.map((item: any) => ({
            value: type === 'address' ? item.name : item.name,
            label:
              type === 'address'
                ? item.name
                : `${item.name} - ${item.formatted_address}`,
            googleInfo: item,
          }))

          setOptions(opts)
        },
      )
    } else {
      onChange({
        value: '',
      })
    }
  }

  const selectValue = (selected: any) => {
    if (options.length > 0) {
      setValue(selected.value)
      onChange(selected)
      setOptions([])
    }
  }

  const handleBlur = () => {
    setTimeout(() => {
      setOptions([])
    }, 300)
  }

  return (
    <div ref={wrapperRef} className={styles.block}>
      <Input
        name="address"
        id="address"
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleInput}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
      />

      {options.length > 0 && (
        <div className={styles.block__dropdown}>
          {options.map((value: any) => (
            <button
              type="button"
              key={value.googleInfo.place_id}
              className={`${styles.block__item} ${value.value === true ? styles['block__item--active'] : ''}`}
              onClick={() => selectValue(value)}
            >
              {value.label}
            </button>
          ))}
        </div>
      )}

      <div
        id={`places_container_${type}`}
        style={{ display: 'none', position: 'absolute' }}
      ></div>
    </div>
  )
}
