import Avatar from '../../Avatar/Avatar'
import styles from './PromoRankingItem.module.scss'
import { Link } from 'react-router-dom'

import { ReactComponent as USDCIcon } from '../../../assets/icons/usdc_small.svg'
import { ReactComponent as WPIcon } from '../../../assets/icons/wp.svg'
import { ICountry, IRewardsList } from '../../../types/promo.interface'
import { IMAGE_URL } from '../../../constants'
import { useEffect, useState } from 'react'
import { hasFlag } from 'country-flag-icons'
import { COUNTRIES } from '../../../constants/countries'
import { useSelector } from "react-redux";
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg'

interface IProps {
  avatar: string
  country: string
  index: number | string
  id: string
  name: string
  email: string
  credits: number
  rewards?: IRewardsList[]
  viewable?: boolean
}

export default function PromoRankingItem({
  avatar,
  country,
  index,
  id,
  name,
  email,
  credits,
  rewards,
  viewable
}: IProps) {
  const [userCountry, setUserCountry] = useState<ICountry | null>(null)

  const { profile } = useSelector((state: any) => state.profile)

  useEffect(() => {
    if (country) {
      const countries = COUNTRIES.find(
        (item: any) => item.name === country,
      )

      if (countries && hasFlag(countries.code)) {
        setUserCountry(countries)
      }
    }
  }, [])

  return (
    <div className={styles.block}>
      <div className={styles.block__content}>
        <div className={styles.block__start}>
          <div className={styles.block__place}>{index}</div>
          <Link to={`/profile/${id}`}>
            <div className={styles.block__avatarContainer}>
              <Avatar viewable={viewable} url={avatar ? `${IMAGE_URL}/${avatar}` : ''} size={40} />
              {userCountry?.code && (
                <div className={styles.block__country}>
                  <div
                    style={{ width: 18, height: 12, borderRadius: '50%' }}
                    dangerouslySetInnerHTML={{ __html: CountryFlagSvg[userCountry?.code] }}
                  ></div>
                </div>
              )}
            </div>
          </Link>
          <div className={styles.block__info}>
            <Link to={`/profile/${id}`}>
              <p className={`${styles.block__name} ${id === profile?._id && styles.block__name__me}`}>
                {id === profile?._id ? 'You' : (name && `${name.slice(0, 6)}...`) || (email && `${email?.slice(0, 6)}...`) || '-'}
              </p>
            </Link>
            <p className={styles.block__points}>
              {new Intl.NumberFormat('en-US', {}).format(credits || 0)}{' '}
              WP
            </p>
          </div>
        </div>
        <div className={styles.block__end}>
          {rewards ? (
            <USDCIcon className={styles.block__icon} />
          ) : (
            <WPIcon className={styles.block__icon} />
          )}
          <p className={`${styles.block__value} ${id === profile?._id && styles.block__value__me}`}>
            {(rewards && typeof index === 'number') ? rewards[index - 1]?.reward : 50}
          </p>
          <p className={`${styles.block__currency} ${id === profile?._id && styles.block__currency__me}`}>
            {rewards ? 'USDC' : 'WP'}
          </p>
        </div>
      </div>
    </div>
  )
}
