import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(LineElement, LinearScale, CategoryScale, PointElement)

interface IProps {
  keys: string[]
  values: number[]
  types?: string
}

export default function LineChart({ keys, values, types }: IProps) {
  const data = {
    labels: keys,
    datasets: [
      {
        data: values,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#000',
        tension: 0.2,
        borderWidth: 1,
        pointRadius: 2,
        borderColor: '#1C1B1B',
        align: 'start'
      },
    ],
  }

  const options = {
    scales: {
      x: {
        border: {
          display: false,
          dash: [4, 4],
        },
        ticks: {
          color: '#7B7B7B',
          fontFamily: 'Lexend',
          fontSize: 10
        }
      },
      y: {
        border: {
          display: false,
          dash: [4, 4],
        },
        ticks: {
          callback: function(value: any) {
            return types === 'profit' ? '$' + value : value;
          },
          fontSize: 10,
          precision: 0,
          color: '#7B7B7B',
          fontFamily: 'Lexend'
        }
      }
    }
  }

  return <Line data={data} options={options} />
}