import styles from './HomeScreen.module.scss'
import NavTabs from '../../components/NavTabs/NavTabs'
import WithNavigation from '../../layouts/WithNavigation/WithNavigation'
import FeedInput from '../../components/Feed/FeedInput/FeedInput'
import FeedEmpty from '../../components/Feed/FeedEmpty/FeedEmpty'
import { useTranslation } from 'react-i18next'
import FeedPost from '../../components/Feed/FeedPost/FeedPost'
import { IFeedPost } from '../../types/feed.interface'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../api/config'
import { useEffect, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../../components/Spinner/Spinner'
import { RootState, store } from '../../store'
import { useSelector } from 'react-redux'

export default function HomeScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const { dispatch } = store
  const { pagination, posts } = useSelector((state: RootState) => state.feed)

  useEffect(() => {
    if (!location.state?.isBack || !posts.length) {
      fetchPosts()
    } else {
      if (location.state?.scrollTop) {
        const container = document.querySelector('#scrollableDiv')

        setTimeout(() => {
          container?.scrollTo({
            top: location.state?.scrollTop,
          })
        }, 200)
      }
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    dispatch({ type: 'profile/getProfile' })
  }, [])

  const TABS = [
    {
      title: t('tabs.feed'),
      to: '/home',
    },
    {
      title: t('tabs.promo'),
      to: '/home/promo',
    },
  ]

  const fetchPosts = async (page: number = 1) => {
    try {
      const { data } = await axiosInstance.get('/api/posts/feed', {
        params: {
          page,
        },
      })

      dispatch({
        type: 'feed/setFeed',
        payload: {
          posts:
            page === 1
              ? data?.data?.entities || []
              : [...posts, ...(data?.data?.entities || [])],
          pagination: {
            page: data?.data?.page || 1,
            pages: data?.data?.pages || 1,
            countItems: data?.data?.countItems || 0,
          },
        },
      })

      setIsLoading(false)

      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    fetchPosts(+pagination.page + 1)
  }

  const handleDeletePost = (id: string) => {
    dispatch({
      type: 'feed/setFeed',
      payload: {
        posts: posts.filter((post) => post._id !== id),
        pagination,
      },
    })
  }

  return (
    <WithNavigation>
      <div className={styles.page__header}>
        <NavTabs tabs={TABS} />
      </div>
      <div id="scrollableDiv" className={styles.page__content}>
        <div className={styles.page__inputContainer}>
          <FeedInput
            placeholder={t('feed.input_placeholder')}
            onClick={() => navigate('/create-post')}
          />
        </div>
        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {posts.length > 0 ? (
              <InfiniteScroll
                dataLength={posts.length}
                next={loadMore}
                hasMore={true}
                loader={<></>}
                className={styles.page__feedContainer}
                scrollableTarget="scrollableDiv"
              >
                {posts.map((post: IFeedPost) => (
                  <FeedPost
                    post={post}
                    key={post._id}
                    onDelete={handleDeletePost}
                    isLoadingImage={isLoadingImage}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <FeedEmpty />
            )}
          </>
        )}
      </div>
    </WithNavigation>
  )
}
