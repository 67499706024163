import dayjs from 'dayjs'
import {ENotificationType, INotification, IWine,} from '../../../types/notifications'
import styles from './NotificationItem.module.scss'

import {ReactComponent as ReceivedIcon} from '@assets/icons/received.svg'
import {ReactComponent as MessageIcon} from '@assets/icons/message_add.svg'
import {ReactComponent as UserIcon} from '@assets/icons/user_tick.svg'
import {ReactComponent as MapIcon} from '@assets/icons/map_bold.svg'
import {ReactComponent as BingIcon} from '@assets/icons/notification_bing.svg'
import {ReactComponent as ScannerIcon} from '@assets/icons/scanner.svg'
import {ReactComponent as UsersIcon} from '@assets/icons/users.svg'
import {ReactComponent as MoneyIcon} from '@assets/icons/money_receive.svg'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

interface IProps {
  data: INotification
  onSelectWine: (wine: IWine | null) => void
  onSelectTransaction: (data: INotification) => void
}

const NotificationIcon = ({ type }: { type: ENotificationType }) => {
  switch (type) {
    case ENotificationType.NEW_CHAT_MESSAGE:
      return <MessageIcon className={styles.block__icon} />
    case ENotificationType.WINE_SCANNED:
      return <ScannerIcon className={styles.block__icon} />
    case ENotificationType.REFERRAL_REGISTER:
      return <UsersIcon className={styles.block__icon} />
    case ENotificationType.REPLENISH:
      return <ReceivedIcon className={styles.block__icon} />
    case ENotificationType.WITHDRAW:
      return <BingIcon className={styles.block__icon} />
    case ENotificationType.PROFILE_FILLED:
      return <UserIcon className={styles.block__icon} />
    case ENotificationType.ADDRESS_FILLED:
      return <MapIcon className={styles.block__icon} />
    case ENotificationType.PROMO_REWARD:
      return <MoneyIcon className={styles.block__icon} />
    case ENotificationType.RECEIVE_NFT:
      return <ReceivedIcon className={styles.block__icon} />
    case ENotificationType.SIGN_UP_BONUS:
      return <UsersIcon className={styles.block__icon} />
    default:
      return type || '-'
  }
}

const NotificationTitle = ({ type }: { type: ENotificationType }) => {
  const { t } = useTranslation()

  let title = ''

  switch (type) {
    case ENotificationType.NEW_CHAT_MESSAGE:
      title = t('notifications.new_message')
      break
    case ENotificationType.WINE_SCANNED:
      title = t('notifications.wine_scanned')
      break
    case ENotificationType.REFERRAL_REGISTER:
      title = t('notifications.ref_registration')
      break
    case ENotificationType.REPLENISH:
      title = t('notifications.points_credited')
      break
    case ENotificationType.WITHDRAW:
      title = t('notifications.withdraw_completed')
      break
    case ENotificationType.PROFILE_FILLED:
      title = t('notifications.profile_filled')
      break
    case ENotificationType.ADDRESS_FILLED:
      title = t('notifications.address_filled')
      break
    case ENotificationType.PROMO_REWARD:
      title = t('notifications.promo_reward')
      break
    case ENotificationType.RECEIVE_NFT:
      title = t('notifications.nft_received')
      break
    case ENotificationType.SIGN_UP_BONUS:
      title = t('notifications.sign_up_bonus')
      break
    default:
      title = type || '-'
  }

  return <p className={styles.block__title}>{title}</p>
}

const NotificationDescription = ({ data }: { data: INotification }) => {
  switch (data.type) {
    case ENotificationType.NEW_CHAT_MESSAGE:
      return null

    case ENotificationType.WITHDRAW:
      return (
        <p className={styles.block__description}>{`${
          new Intl.NumberFormat('en-US', {
            maximumSignificantDigits: 3,
          }).format(data?.data?.amount || 0) || 0
        } ${data?.data?.currency || ''} has been withdrawn from your balance`}</p>
      )

    default:
      return (
        <p className={styles.block__description}>{`${
          new Intl.NumberFormat('en-US', {
            maximumSignificantDigits: 3,
          }).format(data?.data?.amount || 0) || 0
        } ${data?.data?.currency || ''} added to your balance`}</p>
      )
  }
}

const NotificationDate = ({ createdAt }: { createdAt: string }) => {
  if (!createdAt) return null

  const diff = dayjs().diff(createdAt, 'day')

  if (diff < 1)
    // @ts-ignore
    return <p className={styles.block__date}>{dayjs(createdAt).fromNow()}</p>

  if (diff > 1 && diff < 6)
    return (
      <p className={styles.block__date}>
        {dayjs(createdAt).format('[Last] dddd [at] HH:mm')}
      </p>
    )

  if (diff > 6)
    return (
      <p className={styles.block__date}>
        {dayjs(createdAt).format('MMM DD, YYYY HH:mm a')}
      </p>
    )

  //@ts-ignore
  return <p className={styles.block__date}>{dayjs(createdAt).fromNow()}</p>
}

export default function NotificationItem({ data, onSelectWine, onSelectTransaction }: IProps) {
  const navigate = useNavigate()

  const handleClick = () => {
    switch (data.type) {
      case ENotificationType.NEW_CHAT_MESSAGE:
        navigate('/chats')
        break
      case ENotificationType.WINE_SCANNED:
        onSelectWine(data?.data?.wine || null)
        break
      default:
        onSelectTransaction(data)
    }
  }

  return (
    <button className={styles.block} onClick={handleClick}>
      <div className={styles.block__container}>
        <div className={styles.block__iconContainer}>
          <NotificationIcon type={data.type} />
        </div>
        <div className={styles.block__info}>
          <NotificationTitle type={data.type} />
          <NotificationDate createdAt={data.createdAt} />
        </div>
      </div>
      <NotificationDescription data={data} />
    </button>
  )
}
