import { useTranslation } from 'react-i18next'
import styles from './ForgotPasswordSuccess.module.scss'
import { ReactComponent as KeyIcon } from '@assets/icons/key.svg'

interface IProps {
  change?: boolean
}

export default function ForgotPasswordSuccess({ change = false }: IProps) {
  const { t } = useTranslation()
  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <KeyIcon />
      </div>
      <h2 className={styles.block__title}>
        {change ? t('resetPasswordScreen.title_changed') : t('resetPasswordScreen.title_success')}
      </h2>
      <p className={styles.block__description}>
        {change ? t('resetPasswordScreen.description_changed') : t('resetPasswordScreen.description_success')}
      </p>
    </div>
  )
}
