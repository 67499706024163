import { useTranslation } from 'react-i18next'
import ModalContainer from '../../ModalContainer/ModalContainer'
import Spinner from '../../Spinner/Spinner'
import styles from './PendingModal.module.scss'

interface IProps {
  isOpen: boolean
  onClose: () => void
  disabled?: boolean
}

export default function PendingModal({ isOpen, onClose, disabled }: IProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <p className={styles.block__title}>{t('scan.scan_wine.checking')}</p>
      <button className={`button ${disabled && styles.block__disabled}`}>
        <Spinner size={32} color="white" />
      </button>
    </ModalContainer>
  )
}
