import { NavLink, useParams } from 'react-router-dom'
import styles from './ProfileTabs.module.scss'

import { ReactComponent as InfoIcon } from '@assets/icons/info_circle.svg'
import { ReactComponent as DashboardIcon } from '@assets/icons/graph.svg'
import { ReactComponent as TransactionIcon } from '@assets/icons/receipt.svg'
import { ReactComponent as PostIcon } from '@assets/icons/firstline.svg'
import { ReactComponent as FilledInfoIcon } from '@assets/icons/info_circle_filled.svg'
import { ReactComponent as FilledDashboardIcon } from '@assets/icons/graph_filled.svg'
import { ReactComponent as FilledTransactionIcon } from '@assets/icons/receipt_filled.svg'
import { ReactComponent as FilledPostIcon } from '@assets/icons/firstline_filled.svg'

import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

interface INavigationItem {
  icon: ReactNode
  icon_filled: ReactNode
  to: string
  onlyForMe: boolean
}

export default function ProfileTabs() {
  const { profile } = useSelector((state: any) => state.profile)
  const { id } = useParams()

  const NAVIGATION = [
    {
      icon: <InfoIcon className={styles.nav__icon} />,
      icon_filled: <FilledInfoIcon className={styles.nav__icon} />,
      to: `/profile/${id}`,
      onlyForMe: false,
    },
    {
      icon: <DashboardIcon className={styles.nav__icon} />,
      icon_filled: <FilledDashboardIcon className={styles.nav__icon} />,
      to: `/profile/${id}/dashboard`,
      onlyForMe: true,
    },
    {
      icon: <TransactionIcon className={styles.nav__icon} />,
      icon_filled: <FilledTransactionIcon className={styles.nav__icon} />,
      to: `/profile/${id}/transactions`,
      onlyForMe: true,
    },
    {
      icon: <PostIcon className={styles.nav__icon} />,
      icon_filled: <FilledPostIcon className={styles.nav__icon} />,
      to: `/profile/${id}/posts`,
      onlyForMe: false,
    },
  ]

  return (
    <div className={styles.nav}>
      {NAVIGATION.map((item: INavigationItem, index: number) => {
        return (
          ((id !== profile?._id && !item.onlyForMe) || id === profile?._id) && (
            <NavLink
              key={index}
              to={item.to}
              end
              className={({ isActive }) =>
                `${
                  isActive
                    ? `${styles.nav__link} ${styles['nav__link--active']}`
                    : styles.nav__link
                } }`
              }
            >
              {({ isActive }) => (isActive ? item.icon_filled : item.icon)}
            </NavLink>
          )
        )
      })}
    </div>
  )
}
