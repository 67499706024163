import { useCallback, useRef, useState } from 'react'
import styles from './CommentsFooter.module.scss'

import { ReactComponent as SendIcon } from '@assets/icons/send.svg'
import { useTranslation } from 'react-i18next'
import { createRange } from '../../../utils/inputs'

interface IProps {
  onSend: (text: string) => void
  disabled?: boolean
}

export default function CommentsFooter({ onSend, disabled }: IProps) {
  const { t } = useTranslation()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const scrollCallback = useCallback(() => {
    window.scrollTo(0, 0)
    document.documentElement.scrollTo(0, 0)
  }, [])

  const handleFocus = () => {
    setIsFocused(true)

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)

    window.addEventListener('scroll', scrollCallback, true)
  }

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 100)

    window.removeEventListener('scroll', scrollCallback, true)
  }

  const setPosition = (targetPosition: any) => {
    const range = createRange(textareaRef.current, targetPosition)
    const selection = window.getSelection()

    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  const handleInputChange = (e: any) => {
    if (e.target.innerText && e.target.innerText?.length > 1000) {
      e.preventDefault()

      setInputValue(e.target.innerText.slice(0, 1000))
      if (textareaRef.current) {
        textareaRef.current.innerText = e.target.innerText.slice(0, 1000)
        setPosition(e.target.innerText?.length)
      }
    } else {
      setInputValue(e.target.innerText || '')
    }
  }

  const sendComment = () => {
    onSend(inputValue)
    setInputValue('')

    if (textareaRef.current) {
      textareaRef.current.textContent = ''
    }
  }

  return (
    <div
      className={`${styles.block} ${isFocused ? styles['block--focus'] : ''}`}
    >
      <span
        ref={textareaRef}
        className={styles.block__input}
        placeholder={t('comments.add_comment')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={handleInputChange}
        contentEditable
      />

      <button
        className={`${styles.block__sendButton}`}
        disabled={!inputValue.length || disabled}
        type="button"
        onClick={sendComment}
      >
        <SendIcon />
      </button>
    </div>
  )
}
