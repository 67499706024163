import { useState } from 'react'
import ModalContainer from '../../components/ModalContainer/ModalContainer'
import Spinner from '../../components/Spinner/Spinner'
import styles from './UIKit.module.scss'

import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal'

export default function UIKit() {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  return (
    <div className={styles.page}>
      <LogoIcon />
      <Spinner />
      <div className={styles.page__flex}>
        <button className="button">Primary</button>
        <button className="button button--outline">Outline</button>
        <button className="button button--white">White</button>
        <button className="button button--outline-white">Outline white</button>

        <button className="button" disabled>
          Primary
        </button>
        <button className="button button--outline" disabled>
          Outline
        </button>
        <button className="button button--white" disabled>
          White
        </button>
        <button className="button button--outline-white" disabled>
          Outline white
        </button>
      </div>

      <div className={styles.page__flex}>
        <button className="button" onClick={() => setIsOpenConfirmModal(true)}>
          Open Confirm Modal
        </button>
        <button className="button" onClick={() => setIsOpen(true)}>
          Open Modal
        </button>
      </div>

      <ModalContainer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div>Modal</div>
      </ModalContainer>

      <ConfirmModal
        title="Delete Comment?"
        isOpen={isOpenConfirmModal}
        onCancel={() => setIsOpenConfirmModal(false)}
        onConfirm={() => setIsOpenConfirmModal(false)}
        confirmButtonTitle="Yes, delete"
        cancelButtonTitle="Close"
      />
    </div>
  )
}
