import styles from './ContentContainer.module.scss'

import { ReactComponent as ArrowIcon } from '@assets/icons/chevron.svg'

interface IProps {
  title: string
  onBack?: () => void
  children?: React.ReactNode
}

export default function ContentContainer({ title, onBack, children }: IProps) {
  return (
    <div className={styles.block}>
      <div className={styles.block__header}>
        {onBack && (
          <button
            className={styles.block__backButton}
            type="button"
            onClick={onBack}
          >
            <ArrowIcon />
          </button>
        )}
        <div className={styles.block__title}>{title}</div>
      </div>

      <div className={styles.block__content}>{children}</div>
    </div>
  )
}
