import styles from './DashboardAchievement.module.scss'
import { useTranslation } from 'react-i18next'

import Achievement from '../Achievement/Achievement'
import { IAchievement } from '../../types/profile.interface'

import { ReactComponent as Coin } from '@assets/icons/coin.svg'
import { ReactComponent as Bottles } from '@assets/icons/bottles.svg'
import { ReactComponent as Rating } from '@assets/icons/rating.svg'

interface IProps {
  visibleAchievement: boolean
  achievement: IAchievement
  isPending: boolean
  isMyProfile?: boolean
}

export default function DashboardAchievement({
  visibleAchievement,
  achievement,
  isPending,
  isMyProfile = true,
}: IProps) {
  const { t } = useTranslation()

  const data = [
    {
      icon: <Coin />,
      title: t('dashboard.total_sold'),
      value: achievement?.totalAmount,
      type: 'sold',
    },
    {
      icon: <Bottles />,
      title: t('dashboard.bottles_sold'),
      value: achievement?.totalWineBottles,
      type: 'wine',
    },
    {
      icon: <Rating />,
      title: t('dashboard.rating_score'),
      value: achievement?.rank,
      type: 'rank',
    },
  ]

  return (
    <div className={styles.achievement}>
      <div className={styles.achievement__header}>
        <div className={styles.achievement__title}>
          {t('dashboard.achievement')}
        </div>
        {isMyProfile && (
          <div className={styles.achievement__subtitle}>
            {visibleAchievement
              ? t('dashboard.visible_achievement')
              : t('dashboard.hidden_achievement')}
          </div>
        )}
      </div>
      {data.map((item, index) => (
        <Achievement
          key={index}
          isPending={isPending}
          icon={item.icon}
          title={item.title}
          amount={item.value}
          total={index}
        />
      ))}
    </div>
  )
}
