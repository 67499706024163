import { useTranslation } from 'react-i18next'
import styles from './ScanSuccess.module.scss'
import { ReactComponent as SuccessIcon } from '@assets/icons/success.svg'
import { Link } from 'react-router-dom'

export default function ScanSuccess() {
  const { t } = useTranslation()
  return (
    <div className={styles.block}>
      <div className={styles.block__content}>
        <div className={styles.block__iconContainer}>
          <SuccessIcon />
        </div>
        <h2 className={styles.block__title}>{t('scan.success.title')}</h2>
        <p className={styles.block__description}>
          {t('scan.success.description')}
        </p>
      </div>

      <div className={styles.block__buttonContainer}>
        <Link to="/home" className="button button--white">
          {t('scan.success.button')}
        </Link>
      </div>
    </div>
  )
}
