import { ReactComponentElement } from "react";
import styles from './Achievement.module.scss'
import AppSkeleton from "../Skeleton/Skeleton";

interface IProps {
  icon: ReactComponentElement<any>,
  title: string,
  amount: number
  total: number
  isPending: boolean
}

export default function Achievement({ icon, title, amount, total, isPending }: IProps) {
  return (
    <div className={`${styles.block} ${total !== 2 && styles.block__bottom}`}>
      <div className={styles.block__icon}>{icon}</div>
      <div>
        <div className={styles.block__title}>{title}</div>
        <div className={styles.block__subtitle}>{
          isPending ? <AppSkeleton width={50} height={15} borderRadius={8} /> : `${total === 0 ? '$' : ''}${total === 0 ? amount?.toLocaleString('es-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : amount?.toLocaleString('es-US') || '-'}`
        }</div>
      </div>
    </div>
  )
}