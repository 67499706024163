import { useRef, useState } from 'react'
import styles from './ScanWine.module.scss'

import { ReactComponent as FrameIcon } from '@assets/icons/frame.svg'
import PendingModal from '../PendingModal/PendingModal'
import ScanBackButton from '../ScanBackButton/ScanBackButton'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'

import PhotoPreview from '../PhotoPreview/PhotoPreview'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axiosInstance from '../../../api/config'

import ReCAPTCHA from 'react-google-recaptcha'
import { prepareBase64String } from '../../../utils/scan'

enum EScanStatus {
  DEFAULT = 'default',
  SCANNING = 'scanning',
  NOTHING_FOUND = 'nothing_found',
}

interface IProps {
  onError: () => void
  onScanId: (id: string) => void
  onTakePhoto?: (dataUri: string) => void
}

export default function ScanWine({ onError, onScanId }: IProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isOpenPendingModal, setIsOpenPendingModal] = useState(false)
  const [dataUri, setDataUri] = useState<string>('')
  const [countError, setCountError] = useState<number>(1)
  const [scanStatus, setScanStatus] = useState<EScanStatus>(EScanStatus.DEFAULT)
  const [isCameraStarted, setIsCameraStarted] = useState(false)
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)

  const recaptchaRef = useRef<any>(null)

  const handleTakePhoto = (dataUri: string) => {
    setDataUri(dataUri)
    setScanStatus(EScanStatus.SCANNING)

    setTimeout(() => {
      setIsOpenPendingModal(true)

      setTimeout(() => {
        uploadPhoto(dataUri)
      }, 1000)
    }, 1000)
  }

  const uploadPhoto = async (value: string) => {
    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }
      const image = prepareBase64String(value)

      const { data } = await axiosInstance.post(
        '/api/scans/photo/upload',
        {
          image,
        },
        {
          headers: {
            recaptcha: token,
          },
        },
      )
      onScanId(data?.data?.scanId || '')
    } catch (e: any) {
      handleError()
    }
  }

  const scanText = () => {
    switch (scanStatus) {
      case EScanStatus.SCANNING:
        return t('scan.scan_wine.scanning')
      case EScanStatus.NOTHING_FOUND:
        return t('scan.scan_wine.nothing_found')
      default:
        return t('scan.scan_wine.keep_label')
    }
  }

  const handleError = () => {
    setDataUri('')
    setIsOpenPendingModal(false)

    if (countError < 2) {
      setScanStatus(EScanStatus.NOTHING_FOUND)
      setCountError(countError + 1)
    } else {
      onError()
    }
  }

  const handleCameraError = () => {
    setIsPermissionDenied(true)

    const button = document.getElementById('container-circles')

    if (button) {
      button.style.display = 'none'
    }
  }

  return (
    <div className={`${styles.block}`}>
      <div className={styles.block__back}>
        <ScanBackButton onBack={() => navigate('/home')} />
      </div>
      <div className={styles.block__container}>
        {dataUri && <PhotoPreview dataUri={dataUri} isFullscreen={true} />}

        <div
          className={`${styles.block__camera} ${dataUri ? styles['block__camera--hide'] : ''}`}
        >
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isDisplayStartCameraError={false}
            isSilentMode={true}
            onCameraError={handleCameraError}
            onTakePhoto={handleTakePhoto}
            isMaxResolution={true}
            onCameraStart={() => setIsCameraStarted(true)}
            onCameraStop={() => setIsCameraStarted(false)}
          />
        </div>
      </div>

      {isPermissionDenied && (
        <div className={styles.block__errorBlock}>
          <p className={styles.block__error}>
            {t('scan.scan_wine.camera_disabled')}
          </p>
        </div>
      )}

      {!isCameraStarted && <div className={styles.block__overlay}></div>}

      {isCameraStarted && (
        <div className={styles.block__frameContainer}>
          <div className={styles.block__frame}>
            <FrameIcon className={styles.block__frameIcon} />
            {scanStatus === EScanStatus.SCANNING && (
              <div className={styles.block__bar}></div>
            )}
          </div>
          <p className={styles.block__text}>{scanText()}</p>
        </div>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        style={{
          display: 'none',
        }}
      />

      <PendingModal disabled={true} isOpen={isOpenPendingModal} onClose={() => {}} />
    </div>
  )
}
