import { useState, useEffect } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useTranslation } from "react-i18next"
import 'react-day-picker/dist/style.css'
import styles from './DatePicker.module.scss'

import ModalContainer from "../../ModalContainer/ModalContainer"

interface IProps {
  open: boolean
  onClose: () => void
  getDate: (date: DateRange | undefined) => void
}

export default function DatePickerModal({ open, onClose, getDate }: IProps) {
  const [selectedDays, setSelectedDays] = useState<{ from: Date | undefined, to: Date | undefined }>({ from: undefined, to: undefined });

  const handleDayClick = (day: Date) => {
    const lastDate = new Date(day);
    lastDate.setHours(23, 59, 59, 999);

    if (!selectedDays.from || selectedDays.to || (new Date(day) < new Date(selectedDays.from))) {
      setSelectedDays({ from: day, to: undefined });
      return;
    }

    setSelectedDays({ ...selectedDays, to: lastDate });
  };

  const { t } = useTranslation()

  const resetDate = () => {
    setSelectedDays({ from: undefined, to: undefined });
  }

  useEffect(() => {
    getDate(selectedDays);
  }, [selectedDays])

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div>
        <div style={{ fontSize: '24px', fontWeight: '500', textAlign: 'center', marginBottom: '12px' }}>{t('transactions.select')}</div>
        <DayPicker
          mode="range"
          selected={selectedDays}
          onDayClick={handleDayClick}
          showOutsideDays
        />
        <div onClick={resetDate} className={styles.clear}>Clear</div>
      </div>
    </ModalContainer>
  )
}