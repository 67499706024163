import io from 'socket.io-client'
import { getCookie } from './cookie'

let socket: any = null

enum ESocketEvents {
  SIGN_IN = 'sign-in',
  EXCEPTION = 'exception',
  DISCONNECT = 'disconnect',
  REPLENISH = 'event.replenish.tx',
}

const checkSocket = () => {
  return socket && socket.connected ? true : false
}

const getToken = async () => {
  return await getCookie('token')
}

const initSocket = () => {
  if (!socket) {
    socket = io(process.env.REACT_APP_API_URL, {
      forceNew: true
    })

    socket.on('connect', () => {
      console.log('Connected to server by socket! - Balance')
    })

    socket.on('error', (error: any) => {
      console.log('error!', error)
    })

    socket.on('exception', (error: any) => {
      console.log('exception!', error)
    })

    socket.on('disconnect', (reason: any) => {
      if (reason === 'io server disconnect') {
        socket.connect()
      }
    })
  } else if (socket.disconnected) {
    socket.open()
    console.log('>>> socket connection is opened', socket)
  }
}

const signIn = async () => {
  const token = await getToken()
  return new Promise((resolve, reject) => {
    socket.emit(
      ESocketEvents.SIGN_IN,
      {
        token,
      },
      (res: any) => {
        if (res === true) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  }).catch((err) => {
    throw err
  })
}

const subscriptionSignIn = (cb = (res: any) => {}, unsubscribe?: string) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(ESocketEvents.SIGN_IN, cb)
  } else {
    socket.on(ESocketEvents.SIGN_IN, cb)
  }
}

const subscriptionReplenish = (cb = (res: any) => {}, unsubscribe?: string) => {
  if (unsubscribe === 'unsubscribe') {
    socket.off(ESocketEvents.REPLENISH, cb)
  } else {
    socket.on(ESocketEvents.REPLENISH, cb)
  }
}

const closeSocket = () => {
  if (socket && socket.connected) {
    socket.close()
  }
}

export const socketBalance = {
  constants: ESocketEvents,
  initSocket,
  closeSocket,
  checkSocket,
  signIn,
  subscriptionSignIn,
  subscriptionReplenish,
}
