import styles from './RegistrationForm.module.scss'
import Input from '../../Input/Input'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import { PASSWORD_REGEX } from '../../../constants/validation'
import Checkbox from '../../Checkbox/Checkbox'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IRegistrationBody } from '../../../types/auth.interface'
import axiosInstance from '../../../api/config'

import ReCAPTCHA from 'react-google-recaptcha'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t('validation.invalid_email'))
    .required(i18next.t('validation.required'))
    .trim(),
  password: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .matches(PASSWORD_REGEX, i18next.t('validation.invalid_password')),
  confirmPassword: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .test(
      'passwords-match',
      i18next.t('validation.password_match'),
      function (value) {
        const { password } = this.parent

        return value === password
      },
    ),
})

interface IProps {
  ref?: any
  onChangeCheckbox: (isChecked: boolean) => void
  onChangeCheckboxEmail: (isChecked: boolean) => void
}

const LANDING_URL = process.env.REACT_APP_LANDING_URL

const RegistrationForm = forwardRef(({ onChangeCheckbox, onChangeCheckboxEmail }: IProps, ref) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const recaptchaRef = useRef<any>(null)

  const [isPending, setIsPending] = useState(false)
  const [isAggreed, setIsAggreed] = useState(false)
  const [isAggreedEmail, setIsAggreedEmail] = useState(false)
  const [serverError, setServerError] = useState('')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values: any) => {
      if (isPending || !isAggreed) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)
    setServerError('')

    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body: IRegistrationBody = {
        email: values.email.trim().replaceAll(/\s/g,''),
        password: values.password.trim().replaceAll(/\s/g,''),
        repeatPassword: values.confirmPassword.trim().replaceAll(/\s/g,''),
      }

      const refId = sessionStorage.getItem('refId')

      if (refId) {
        body.refId = refId
      }

      await axiosInstance.post(
        '/api/auth/register',
        {
          ...body,
        },
        {
          headers: {
            recaptcha: token,
          },
        },
      )

      setIsPending(false)

      navigate('/registration/verify', {
        state: {
          email: values.email,
          password: values.password.trim(),
        },
      })
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  const handleAggreeCheckbox = (isChecked: boolean) => {
    setIsAggreed(isChecked)
    onChangeCheckbox(isChecked)
  }

  const handleAggreeCheckboxEmail = (isChecked: boolean) => {
    setIsAggreedEmail(isChecked)
    onChangeCheckboxEmail(isChecked)
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form__row}>
        <Input
          id="email"
          name="email"
          type="text"
          placeholder={t('registerScreen.email_placeholder')}
          label={t('registerScreen.email_label')}
          value={formik.values.email.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          isPreventScroll={true}
          isError={formik.touched.email && Boolean(formik.errors.email)}
        />
      </div>
      <div className={styles.form__row}>
        <Input
          id="password"
          name="password"
          placeholder={t('registerScreen.password_placeholder')}
          label={t('registerScreen.password_label')}
          type="password"
          value={formik.values.password.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.password}
          isPreventScroll={true}
          isError={formik.touched.password && Boolean(formik.errors.password)}
        />
      </div>
      <div className={styles.form__row}>
        <Input
          id="confirmPassword"
          name="confirmPassword"
          placeholder={t('registerScreen.confirm_password_placeholder')}
          label={t('registerScreen.confirm_password_label')}
          type="password"
          value={formik.values.confirmPassword.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.confirmPassword}
          isPreventScroll={true}
          isError={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
        />
      </div>
      {serverError && <p className="form__error">{serverError}</p>}
      <div className={styles.form__checkbox}>
        <Checkbox checked={isAggreed} onChange={handleAggreeCheckbox}>
          <p>
            I agree with{' '}
            <a href={`${LANDING_URL}/terms`} target="_blank" rel="noreferrer">
              Terms
            </a>{' '}
            &{' '}
            <a
              href={`${LANDING_URL}/privacy-policy`}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
          </p>
        </Checkbox>
      </div>
      <div className={styles.form__checkbox__email}>
        <Checkbox checked={isAggreedEmail} onChange={handleAggreeCheckboxEmail}>
          <p>
            I agree to receive emails
          </p>
        </Checkbox>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      />
    </form>
  )
})

export default RegistrationForm
