import { useRef, useState, useEffect } from 'react'
import styles from "../Auth/VerifyEmailScreen/VerifyEmailScreen.module.scss"
import { Trans } from "react-i18next"
import AuthHeader from "../../components/AuthHeader/AuthHeader"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import VerifyPhone from "../../components/VerifyPhone/VerifyPhone";
import VerifyPhoneSuccess from "../../components/VerifyPhone/VerifyPhoneSuccess";
import axiosInstance from "../../api/config";

export default function VerifyPhoneScreen() {
  const [step, setStep] = useState(0)
  const { t } = useTranslation()
  const formRef = useRef<any>(null)
  const navigate = useNavigate()

  const { profile } = useSelector((state: any) => state.profile)

  const changeStep = () => {
    setStep(step + 1)
  }

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  const getVerifyCode = async() => {
    await axiosInstance.post('/api/users/phone/verify')
  }

  useEffect(() => {
    getVerifyCode()
  }, [])

  return (
    <div className={`page ${styles.page}`}>
      {step === 0 && (
        <AuthHeader
          title={t('editProfileScreen.verify_number')}
          description={
            <Trans
              i18nKey={'verifyEmail.description'}
              values={{ email: profile?.phone }}
              components={{ bold: <b /> }}
            />
          }
        />
      )}

      {step === 0 && <VerifyPhone ref={formRef} changeStep={changeStep} />}
      {step === 1 && <VerifyPhoneSuccess />}

      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={step === 1 ? () => navigate(`/profile/${profile?._id}`) : triggerForm}>
          {step === 0 ? t('verifyEmail.confirm') : t('verifyEmail.back')}
        </button>
      </div>
    </div>
  )
}