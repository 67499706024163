import { useState, useEffect } from 'react'
import styles from './SalesStatistic.module.scss'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Arrow } from "@assets/icons/arrow.svg";

import ModalContainer from "../ModalContainer/ModalContainer";
import AppSkeleton from "../Skeleton/Skeleton";

export default function SalesStatistic() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [dateRange, setDateRange] = useState('weekly')
  const [rangeTitle, setRangeTitle] = useState(t('dashboard.7_days_sale'));
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isPending, setIsPending] = useState<boolean>(false)

  const salesRange = [
    {
      option: t('dashboard.last_7_days'),
      title: t('dashboard.7_days_sale'),
      range: 'weekly'
    },
    {
      option: t('dashboard.last_month'),
      title: t('dashboard.month_sale'),
      range: 'monthly'
    },
    {
      option: t('dashboard.last_3_month'),
      title: t('dashboard.3_month_sale'),
      range: 'quarterly'
    },
    {
      option: t('dashboard.last_year'),
      title: t('dashboard.year_sale'),
      range: 'yearly'
    },
    {
      option: t('dashboard.all_time'),
      title: t('dashboard.all_time_sale'),
      range: 'all_time'
    }
  ]

  const { totalStatistic } = useSelector((state: any) => state.profile)

  const setRange = (range: string, rangeTitle: string) => {
    setDateRange(range);
    setRangeTitle(rangeTitle);
    setOpenModal(false);
  }

  useEffect(() => {
    setIsPending(true)

    try {
      dispatch({ type: 'profile/getTotalStatistic', payload: dateRange })
      setIsPending(false)
    } catch (e: any) {
      setIsPending(false)
    }
  }, [dateRange]);

  return (
    <div className={styles.sales}>
      <div className={styles.sales__header}>
        <div className={styles['sales__header--title']}>{t('dashboard.sales')}</div>
        <div className={styles['sales__header--subtitle']}>{t('dashboard.currency')}</div>
      </div>
      <div className={styles.sales__range}>
        <div>
          <div onClick={() => setOpenModal(true)} className={`${styles['sales__range--title']} ${styles['sales__range--width']}`}>{rangeTitle} <Arrow className={`${styles.sales__arrow} ${openModal && styles.sales__arrow__top}`} /></div>
          <div className={styles['sales__range--subtitle']}>{
            isPending ? <AppSkeleton width={50} height={15} borderRadius={8} /> : (`$${totalStatistic?.rangeTotalAmount?.toLocaleString('es-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}`)
          }</div>
        </div>
        <div className={styles.sales__total}>
          <div className={styles['sales__range--title']}>{t('dashboard.total_sale')}</div>
          <div className={styles['sales__range--subtitle']}>{
            isPending ? <AppSkeleton width={50} height={15} borderRadius={8} /> : (`$${totalStatistic?.totalAmount?.toLocaleString('es-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}`)
          }</div>
        </div>
      </div>

      {openModal && (
        <ModalContainer isOpen={openModal} onClose={() => setOpenModal(false)}>
          <div>
            <div className={styles.sales__modal}>{t('dashboard.select_period')}</div>
            {salesRange.map((item) => (
              <div className={styles.sales__options} key={item.range} onClick={() => setRange(item.range, item.title)}>{item.option}</div>
            ))}
          </div>
        </ModalContainer>
      )}
    </div>
  )
}