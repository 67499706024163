import styles from './PlaceMarker.module.scss'

import { ReactComponent as PlacePin } from '@assets/icons/place_pin.svg'
import { ReactComponent as PlacePinShadow } from '@assets/icons/pin_shadow.svg'

import Avatar from '../../Avatar/Avatar'
import { IMAGE_URL } from '../../../constants'

interface IProps {
  avatar: string
  count: number
}

export default function PlaceMarker({ avatar, count }: IProps) {
  return (
    <div className={styles.marker}>
      <PlacePin className={styles.marker__icon} />
      <Avatar
        className={styles.marker__avatarContainer}
        url={avatar ? `${IMAGE_URL}/${avatar}` : ''}
        size={43}
      />
      <div className={styles.marker__count}>{count}</div>
      <PlacePinShadow className={styles.marker__shadow} />
    </div>
  )
}
