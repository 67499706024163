import { useState, useEffect } from 'react';
import styles from './PromoItem.module.scss'

import { ReactComponent as USDCIcon } from '@assets/icons/usd-coin.svg'
import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PromoHeader from "../PromoHeader/PromoHeader";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import { IPromoWinners } from "../../../types/promo.interface";

dayjs.extend(duration);

interface IProps {
  id: string
  description: string
  reward: number
  currency: string
  startDate: string
  endDate: string
  resultDate: string
  title:string
  isLink?: boolean
  sponsor: { sponsorLogo: string, sponsorName: string }
  getStatus?: (status: 'soon' | 'ongoing' | 'finalization' | 'ended') => void
  winners: IPromoWinners[]
}

export default function PromoItem({
    id,
    description,
    reward,
    currency,
    startDate,
    endDate,
    resultDate,
    title,
    sponsor,
    getStatus = () => {},
    winners,
    isLink = true
}: IProps) {
  const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [status, setStatus] = useState<'soon' | 'ongoing' | 'finalization' | 'ended'>('soon');

  useEffect(() => {
    getStatus(status)
  }, [status])

  const updateTimer = (time: number) => {
    const duration = dayjs.duration(time);
    setTimer({
      days: String(duration.days()) === '0' ? '00' : String(duration.days()).length === 1 ? `0${String(duration.days())}` : String(duration.days()),
      hours: String(duration.hours()) === '0' ? '00' : String(duration.hours()).length === 1 ? `0${String(duration.hours())}` : String(duration.hours()),
      minutes: String(duration.minutes()) === '0' ? '00' : String(duration.minutes()).length === 1 ? `0${String(duration.minutes())}` : String(duration.minutes()),
      seconds: String(duration.seconds()) === '0' ? '00' : String(duration.seconds()).length === 1 ? `0${String(duration.seconds())}` : String(duration.seconds()),
    });
  }

  const getDifference = () => {
    const now = dayjs();
    const target = dayjs(startDate);
    const targetEnd = dayjs(endDate);
    const difference = target.diff(now);
    const differenceEnd = targetEnd.diff(now);

    if (difference > 0 && differenceEnd > 0) {
      setStatus('soon')
      updateTimer(difference)
    } else if (difference < 0 && differenceEnd > 0) {
      setStatus('ongoing');
      updateTimer(differenceEnd)
    } else if (difference < 0 && differenceEnd < 0 && !winners?.length) {
      setStatus('finalization');
    } else if (difference < 0 && differenceEnd < 0 && winners?.length) {
      setStatus('ended')
    }
  }

  useEffect(() => {
    getDifference()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      getDifference()
    }, 1000)
  }, [timer])

  const { t } = useTranslation()

  return (
    <div className={`${styles.block} ${!isLink && styles.block__link}`}>
      <PromoHeader
        startDate={startDate}
        endDate={endDate}
        resultDate={resultDate}
        sponsor={sponsor}
        status={status} />

      <Link to={isLink ? `/home/promo/${id}` : ''} className={`${styles.block__info} ${!isLink && styles.block__info__link}`}>
        <div>
          <div className={styles.block__info__title}>{title}</div>
          <div className={styles.block__info__description}>{description}</div>
        </div>
        {isLink && (
          <div>
            <ArrowRight />
          </div>
        )}
      </Link>

      <div className={styles.block__prize}>
        <div className={`${status !== 'ended' && status !== 'finalization' && styles.block__prize__top}`}>
          <div className={styles.block__prize__pool}>
            <div className={styles.block__prize__pool__title}>Prize Pool</div>
            <div className={styles.block__prize__pool__wrapper}>
              <USDCIcon />
              <span className={styles.block__prize__pool__reward}>{reward}</span>
              <span className={styles.block__prize__pool__currency}>{currency}</span>
            </div>
          </div>
        </div>
        {status !== 'ended' && status !== 'finalization' && (
          <div className={styles.block__prize__bottom}>
            <div className={styles.block__prize__pool}>
              <div className={styles.block__prize__pool__title}>{status === 'soon' ? 'Starts in' : 'Ends in'}</div>
              <div className={styles.block__timer}>
                <div className={styles.block__timer__cell}>{timer.days}</div>
                <div className={styles.block__timer__marker}>d.</div>
                <div className={styles.block__timer__cell}>{timer.hours}</div>
                <div className={styles.block__timer__marker}>:</div>
                <div className={styles.block__timer__cell}>{timer.minutes}</div>
                <div className={styles.block__timer__marker}>:</div>
                <div className={styles.block__timer__cell}>{timer.seconds}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
