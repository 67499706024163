import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import styles from './ProfileFollow.module.scss'
import PageHeader from '../../../components/PageHeader/PageHeader'
import NavTabs from '../../../components/NavTabs/NavTabs'
import { useTranslation } from 'react-i18next'
import ProfileFollowers from '../../../components/Profile/ProfileFollowers/ProfileFollowers'
import { EFollowType } from '../../../types/profile.interface'
import { isIOS, isMobile } from 'react-device-detect'

export default function ProfileFollow() {
  const { t } = useTranslation()
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [user, isMyProfile] = useOutletContext<any>()

  const TABS = [
    {
      title: t('profile.followers'),
      to: `/profile/${id}/followers`,
    },
    {
      title: t('profile.followings'),
      to: `/profile/${id}/following`,
    },
  ]

  return (
    <div className={`${styles.page} ${isIOS && isMobile ? styles['page--ios'] : ''}`}>
      <div className={styles.page__header}>
        <PageHeader
          title={user?.name || user?.email}
          colored
          navigateOnBack={false}
          onClickBack={() => navigate(-1)}
        />
        <NavTabs tabs={TABS} />
      </div>

      <div id='followersContainer' className={styles.page__content}>
        <ProfileFollowers
          type={
            location.pathname.includes('followers')
              ? EFollowType.FOLLOWERS
              : EFollowType.FOLLOWINGS
          }
          isMyProfile={isMyProfile()}
        />
      </div>
    </div>
  )
}
