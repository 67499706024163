import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { useState } from 'react'
import styles from '../../components/Scan/ScanWine/ScanWine.module.scss'
import ScanBackButton from '../../components/Scan/ScanBackButton/ScanBackButton'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axiosInstance from '../../api/config'
import { store } from '../../store'
import { prepareBase64String } from '../../utils/scan'
import { useTranslation } from 'react-i18next'
import PhotoPreview from '../../components/Scan/PhotoPreview/PhotoPreview'

export default function TakePhotos() {
  const { t } = useTranslation()
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)
  const [isCameraStarted, setIsCameraStarted] = useState(false)
  const [dataUri, setDataUri] = useState<string>('')
  const navigate = useNavigate()
  const { dispatch } = store

  const { takePhotoType } = useSelector((state: any) => state.profile)

  const handleTakePhoto = async (dataUri: string) => {
    const body = {
      image: '',
    }

    if (dataUri) {
      setDataUri(dataUri)
      const base64Image = prepareBase64String(dataUri)
      const base64Data = base64Image.split(';base64,').pop()
      const format = dataUri.split(';')[0].split('/')[1]
      if (base64Data) {
        const binaryData = atob(base64Data)
        const blob = new Blob(
          [
            new Uint8Array(binaryData.length).map((_, i) =>
              binaryData.charCodeAt(i),
            ),
          ],
          { type: 'image/png' },
        )
        const file = new File([blob], `image/${format}`, {
          type: `image/${format}`,
          lastModified: Date.now(),
        })

        const formData = new FormData()

        formData.append('image', file)

        try {
          const { data } = await axiosInstance.post(
            '/api/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )

          if (data?.data?.filename) {
            body.image = data?.data?.filename
          }
        } catch (e: any) {}
      }
    }

    try {
      if (takePhotoType === 'background') {
        await axiosInstance.patch('api/users/profile/background', body)
      } else {
        await axiosInstance.patch('api/users/profile/avatar', body)
      }
      navigate('/edit-profile')

      dispatch({ type: 'profile/getProfile' })
    } catch (e: any) {}
  }

  const handleCameraError = () => {
    setIsPermissionDenied(true)

    const button = document.getElementById('container-circles')

    if (button) {
      button.style.display = 'none'
    }
  }

  return (
    <div className={styles.block}>
      <div className={styles.block__back}>
        <ScanBackButton onBack={() => navigate('/edit-profile')} />
      </div>

      <div className={styles.block__container}>
        {dataUri && <PhotoPreview dataUri={dataUri} isFullscreen={true} />}
        <div
          className={`${styles.block__camera} ${dataUri ? styles['block__camera--hide'] : ''}`}
        >
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isDisplayStartCameraError={false}
            isSilentMode={true}
            onCameraError={handleCameraError}
            onTakePhoto={handleTakePhoto}
            isMaxResolution={true}
            onCameraStart={() => setIsCameraStarted(true)}
            onCameraStop={() => setIsCameraStarted(false)}
          />
        </div>
      </div>

      {isPermissionDenied && (
        <div className={styles.block__errorBlock}>
          <p className={styles.block__error}>
            {t('scan.scan_wine.camera_disabled')}
          </p>
        </div>
      )}

      {!isCameraStarted && <div className={styles.block__overlay}></div>}
    </div>
  )
}
