export interface IChatUser {
  avatar: string | null
  email: string
  id: string
  name: string
  foreign_id: string
}

export interface IMessage {
  text: string
  type: EMessageType
}

export interface IConversation {
  chat_id: string
  created_at: string
  id: string
  message: IMessage
  updated_at: string
  user: IChatUser
  user_id?: string
  viewed?: boolean
}

export interface IChatItemUser {
  unviewed_conversations_number: number
  viewed_conversation_id: number | null
  user: IChatUser
}

export interface IChatItemConversation {
  created_at: string
  id: string
  message: IMessage
  updated_at: string
  user: {
    id: string
  }
}

export interface IChatItem {
  id: string
  user_chats: IChatItemUser[]
  conversations: []
}
export enum EMessageType {
  TEXT_MESSAGE = 'TEXT_MESSAGE',
  AR_MESSAGE = 'AR_MESSAGE',
}

export enum EChatEvents {
  JOIN_CHAT = 'joinChat',
  LEFT_CHAT = 'leftChat',
  JOIN_CHATS = 'joinChats',
  LEFT_CHATS = 'leftChats',
  NEW_MESSAGE_IN_CHAT = 'newMessageInChat',
  VIEW_MESSAGE_IN_CHAT = 'viewMessageInChat',
  UPDATES_IN_CHATS = 'updatesInChats',
  UPDATE_MESSAGE_IN_CHAT = 'updateMessageInChat',
}

export interface IChatItemUI {
  name: string
  email: string
  avatar: string
  companionId: string
  unread: number
}
