import styles from './ScanReceipt.module.scss'

import { ReactComponent as FrameIcon } from '@assets/icons/frame_receipt.svg'
import ScanBackButton from '../ScanBackButton/ScanBackButton'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhotoPreview from '../PhotoPreview/PhotoPreview'
import PendingModal from "../PendingModal/PendingModal";

interface IProps {
  onTakePhoto: (dataUri: string) => void
  onBack: () => void
  onError: () => void
}

export default function ScanReceipt({ onTakePhoto, onBack, onError }: IProps) {
  const { t } = useTranslation()
  const [isCameraStarted, setIsCameraStarted] = useState(false)
  const [isOpenPendingModal, setIsOpenPendingModal] = useState(false)
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)
  const [dataUri, setDataUri] = useState<string>('')

  const handleTakePhoto = (dataUri: string) => {
    onTakePhoto(dataUri)
    setIsOpenPendingModal(true)
  }

  const handleError = () => {
    onError()
  }

  const handleCameraError = () => {
    setIsPermissionDenied(true)
    setIsOpenPendingModal(false)

    const button = document.getElementById('container-circles')

    if (button) {
      button.style.display = 'none'
    }
  }

  return (
    <div className={`${styles.block}`}>
      <div className={styles.block__back}>
        <ScanBackButton onBack={onBack} />
      </div>
      <div className={styles.block__container}>
        {dataUri && <PhotoPreview dataUri={dataUri} isFullscreen={true} />}

        <div
          className={`${styles.block__camera} ${dataUri ? styles['block__camera--hide'] : ''}`}
        >
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isDisplayStartCameraError={false}
            isSilentMode={true}
            onCameraError={handleCameraError}
            onTakePhoto={(data: string) => {
              setDataUri(data)
              handleTakePhoto(data)
            }}
            isMaxResolution={true}
            onCameraStart={() => setIsCameraStarted(true)}
            onCameraStop={() => setIsCameraStarted(false)}
          />
        </div>
      </div>

      {isPermissionDenied && (
        <div className={styles.block__errorBlock}>
          <p className={styles.block__error}>
            {t('scan.scan_wine.camera_disabled')}
          </p>
        </div>
      )}

      {isCameraStarted && (
        <div className={styles.block__frameContainer}>
          <div className={styles.block__frame}>
            <FrameIcon className={styles.block__frameIcon} />
          </div>
        </div>
      )}

      <PendingModal disabled={true} isOpen={isOpenPendingModal} onClose={() => {}} />
    </div>
  )
}
