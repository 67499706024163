import { createModel } from '@rematch/core'
import { RootModel } from '.'

interface IMapState {
  sorting: string | null
}

export const map = createModel<RootModel>()({
  state: {
    sorting: null,
  } as IMapState,
  reducers: {
    setSorting(state, payload: string | null) {
      return {
        ...state,
        sorting: payload,
      }
    },
  },
})
