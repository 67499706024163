import { useEffect, useState } from 'react'
import axiosInstance from '../../../api/config'
import { EMarkerType, IMapItem } from '../../../types/map.interface'

import styles from './MapRanking.module.scss'
import MapSommelierCard from '../../../components/Map/MapSommelierCard/MapSommelierCard'
import MapPlaceCard from '../../../components/Map/MapPlaceCard/MapPlaceCard'
import Sorting from '../../../components/Sorting/Sorting'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../components/Spinner/Spinner'
import { useSelector } from 'react-redux'
import { store } from '../../../store'

export default function MapRanking() {
  const { t } = useTranslation()

  const [items, setItems] = useState<IMapItem[]>([])
  const [isPending, setIsPending] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const { sorting } = useSelector((state: any) => state.map)
  const { dispatch } = store

  const sortingValues = [
    {
      value: null,
      label: t('map.ranking.all'),
    },
    {
      value: EMarkerType.SOMMELIER,
      label: t('map.ranking.sommeliers'),
    },
    {
      value: EMarkerType.STORE,
      label: t('map.ranking.stores'),
    },
  ]

  useEffect(() => {
    fetchItems()
  }, [])

  useEffect(() => {
    fetchItems()
  }, [sorting])

  const fetchItems = async () => {
    try {
      setIsPending(true)
      const { data } = await axiosInstance.post('/api/maps', {
        distance: 10000000000000,
        type: sorting,
      })

      setItems(data?.data || [])
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
      setIsPending(false)
    } catch (error) {
      setIsPending(false)
    }
  }

  const handleSorting = (value: string | null) => {
    dispatch({ type: 'map/setSorting', payload: value })
  }

  return (
    <div className={styles.page}>
      <Sorting
        values={sortingValues}
        onSelect={handleSorting}
        selectedValue={sorting}
        disabled={isPending}
      />
      <p className={styles.page__title}>{t('map.ranking.near_me')}</p>
      {isPending ? (
        <div className={styles.page__loader}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.page__list}>
          {items.length ? (
            items.map((item: IMapItem) => {
              if (item.type === EMarkerType.SOMMELIER) {
                return (
                  <MapSommelierCard isLoadingImage={isLoadingImage} key={item._id} data={item} forList={true} />
                )
              } else {
                return (
                  <MapPlaceCard key={item._id} data={item} forList={true} />
                )
              }
            })
          ) : (
            <p className={styles.page__empty}>{t('map.ranking.empty')}</p>
          )}
        </div>
      )}
    </div>
  )
}
