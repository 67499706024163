import CountryList from 'country-list-with-dial-code-and-flag'
import Select, { components } from 'react-select'
import { useEffect, useMemo, useRef, useState } from 'react'
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg'
import { ReactComponent as ChevronIcon } from '@assets/icons/arrow-down.svg'

import styles from './CountrySelect.module.scss'

const countryListOptions = () => {
  const countries = CountryList.getAll()

  return countries.map(({ data }: any) => {
    return {
      value: data.code,
      label: data.name,
      flag: data.flag,
    }
  })
}

const ValueContainer = ({ children, ...props }: any) => {
  const [flagSvg, setFlagSvg] = useState<any>(null)

  const value = useMemo(() => {
    const val = props.getValue()

    return val.length && val[0]
  }, [props.getValue()])

  useEffect(() => {
    if (value) {
      setFlagSvg(CountryFlagSvg[value.value])
    } else {
      setFlagSvg(null)
    }
  }, [value])

  return (
    <components.ValueContainer
      className="react-select__value-container"
      {...props}
    >
      {flagSvg ? (
        <div
          style={{ width: 24, height: 16, marginRight: 13, marginTop: 3 }}
          dangerouslySetInnerHTML={{ __html: flagSvg }}
        ></div>
      ) : null}
      {children}
    </components.ValueContainer>
  )
}

const DownChevron = ({ children, ...props }: any) => {
  return (
    <components.IndicatorsContainer
      className="react-select__value-container"
      {...props}
    >
      <div
        style={{
          transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        }}
      >
        <ChevronIcon />
      </div>
    </components.IndicatorsContainer>
  )
}

interface IProps {
  label?: string
  requiredFlag?: boolean
  onBlur?: (e: any) => void
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
  defaultValue: string
}

export default function CountrySelect({
  label,
  requiredFlag = false,
  onBlur,
  onFocus,
  onChange,
  defaultValue,
}: IProps) {
  const selectRef = useRef<any>(null)

  useEffect(() => {
    if (defaultValue) {
      const country: any = CountryList.findOneByCountryCode(defaultValue) || CountryList.findByKeyword(defaultValue)?.[0]

      if (country?.data) {
        selectRef.current.setValue({
          value: country.data.code,
          label: country.data.name,
          flag: country.data.flag,
        })
      }
    }
  }, [defaultValue])

  return (
    <div className={styles.block}>
      {label && (
        <div className={styles.block__label}>
          {label} {requiredFlag ? <span>*</span> : null}
        </div>
      )}
      <Select
        ref={selectRef}
        className="react-select"
        classNamePrefix="select-input"
        options={countryListOptions()}
        components={{ ValueContainer, IndicatorsContainer: DownChevron }}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
    </div>
  )
}
