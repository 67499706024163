import { useEffect, useState } from 'react'
import axiosInstance from '../../../api/config'
import { EFollowType, IFollower } from '../../../types/profile.interface'
import Avatar from '../../Avatar/Avatar'
import styles from './FollowItem.module.scss'
import { useTranslation } from 'react-i18next'
import { IMAGE_URL } from '../../../constants'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

interface IProps {
  type: EFollowType
  data: IFollower
  onUpdate: () => void
  onRemove?: () => void
  isMyProfile?: boolean
  isLoadingImage?: boolean
}

enum ERequestTypeParam {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export default function FollowItem({
  type,
  data,
  onUpdate,
  isMyProfile = false,
  onRemove,
  isLoadingImage
}: IProps) {
  const { t } = useTranslation()
  const { profile } = useSelector((state: any) => state.profile)
  const [item, setItem] = useState<any>(data)
  const [isPending, setIsPending] = useState(false)

  useEffect(() => {
    setItem(data)
  }, [data])

  const toggleFollow = async () => {
    if (isPending) return

    try {
      await axiosInstance.post(`/api/users/follow/${data.user._id}`)
      onUpdate()
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const acceptRequest = async () => {
    if (isPending) return

    try {
      await axiosInstance.post(`/api/users/follow/${item._id}/request`, {
        type: ERequestTypeParam.ACCEPT,
      })
      onUpdate()
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const declineRequest = async () => {
    if (isPending) return

    try {
      await axiosInstance.post(`/api/users/follow/${item._id}/request`, {
        type: ERequestTypeParam.DECLINE,
      })
      onUpdate()
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const cancelMyRequest = async () => {
    if (isPending) return

    try {
      await axiosInstance.post(
        `/api/users/follow/${item._id}/decline-own-request`,
      )
      onUpdate()
    } catch (e: any) {
      setIsPending(false)
    }
  }

  const removeFollower = async () => {
    if (isPending) return

    try {
      await axiosInstance.delete(`/api/users/follow/${data.user._id}`)

      if (onRemove) {
        onRemove()
      }
    } catch (e: any) {
      setIsPending(false)
    }
  }

  return (
    <div className={styles.block}>
      <Link to={`/profile/${item?.user?._id}`} className={styles.block__box}>
        <Avatar isLoading={isLoadingImage} color="white" size={40} url={data?.user?.avatar ? `${IMAGE_URL}/${data?.user?.avatar}` : ''} />
        <p className={styles.block__name}>{data?.user?.name || ''}</p>
      </Link>

      <div className={styles.block__buttons}>
        {/* Followings tab or Followers tab and NOT my profile */}
        {(type === EFollowType.FOLLOWINGS ||
          (type === EFollowType.FOLLOWERS && !isMyProfile)) && (
          <button
            className={`button ${!!item?.isFollowing || (type === EFollowType.FOLLOWINGS && isMyProfile) ? 'button--outline-darker' : ''} ${styles.block__button}`}
            type="button"
            disabled={item.user._id === profile?._id || isPending}
            onClick={toggleFollow}
          >
            {item.user._id === profile?._id ? (
              'You'
            ) : (
              <>
                {!!item?.isFollowing ||
                (type === EFollowType.FOLLOWINGS && isMyProfile)
                  ? t('profile.following')
                  : t('profile.follow')}
              </>
            )}
          </button>
        )}

        {/* Followers tab & my profile */}
        {type === EFollowType.FOLLOWERS && isMyProfile && (
          <button
            className={`button button--outline-darker ${styles.block__button}`}
            type="button"
            disabled={isPending}
            onClick={removeFollower}
          >
            {t('profile.remove')}
          </button>
        )}

        {/* Follow requests */}
        {type === EFollowType.REQUESTS && (
          <>
            <button
              className={`button ${styles.block__button} ${styles['block__button--confirm']}`}
              type="button"
              onClick={acceptRequest}
            >
              {t('profile.confirm')}
            </button>
            <button
              className={`button button--outline-darker ${styles.block__button} ${styles['block__button--delete']}`}
              type="button"
              onClick={declineRequest}
            >
              {t('profile.delete')}
            </button>
          </>
        )}

        {/* My requests */}
        {type === EFollowType.MY_REQUESTS && (
          <>
            <button
              className={`button button--outline-darker ${styles.block__button} ${styles['block__button--delete']}`}
              type="button"
              onClick={cancelMyRequest}
            >
              {t('profile.cancel')}
            </button>
          </>
        )}
      </div>
    </div>
  )
}
