import { useEffect, useState } from 'react'
import NotificationItem from '../../../components/Notifications/NotificationItem/NotificationItem'
import PageHeader from '../../../components/PageHeader/PageHeader'
import SecondaryLayout from '../../../layouts/SecondaryLayout/SecondaryLayout'
import styles from './NotificationsScreen.module.scss'
import axiosInstance from '../../../api/config'
import Spinner from '../../../components/Spinner/Spinner'

import InfiniteScroll from 'react-infinite-scroll-component'
import {
  INotification,
  INotificationResponse, ISocketNotification,
  IWine,
} from '../../../types/notifications'
import { useTranslation } from 'react-i18next'
import ScannedWine from '../../../components/Notifications/ScannedWine/ScannedWine'
import TransactionDetails from '../../../components/modals/TransactionDetails/TransactionDetails'
import { ITransactionsEntities } from '../../../types/transactions.interface'
import { store } from '../../../store'
import { socketWithdrawal } from "../../../services/socket_withdrawal"
import useNotification from "../../../hooks/useNotification"
import { ReactComponent as BingIcon } from '@assets/icons/notification_bing.svg'

export default function NotificationsScreen() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState({
    countItems: 0,
    page: 1,
    pages: 1,
  })
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [selectedWine, setSelectedWine] = useState<IWine | null>(null)
  const [transactionDetails, setTransactionDetails] =
    useState<ITransactionsEntities | null>(null)

  const { dispatch } = store
  const notification = useNotification()

  useEffect(() => {
    fetchNotifications()

    dispatch({ type: 'profile/getProfile' })
  }, [])

  const fetchNotifications = async (page: number = 1) => {
    try {
      const { data } = await axiosInstance.get<INotificationResponse>(
        `/api/notifications`,
        {
          params: {
            page,
            limit: 10,
            orderBy: 'createdAt',
          },
        },
      )

      setNotifications(
        page === 1
          ? data?.data?.entities || []
          : [...notifications, ...(data?.data?.entities || [])],
      )

      setPagination({
        page: +data?.data?.page || 1,
        pages: data?.data?.pages || 1,
        countItems: data?.data?.countItems || 0,
      })

      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    fetchNotifications(+pagination.page + 1)
  }

  const handleSelectWine = (wine: IWine | null) => {
    if (wine) {
      setSelectedWine(wine)
    }
  }

  const updateHash = (hash: string, id: string) => {
    if (transactionDetails?._id === id) {
      setTransactionDetails({ ...transactionDetails, hash: hash })
    }
  }

  const listenNotification = (res: ISocketNotification) => {
    setTransactionDetails(transactionDetails)
    if (res.hash) {
      setNotifications((prevState) =>
        prevState.map(obj => {
          if (obj.data.id === res.id) {
            return { ...obj, data: { ...obj.data, hash: res.hash} }
          }
          return obj;
        })
      )

      notification.info('Withdraw completed', <BingIcon />)

      updateHash(res.hash, res.id)
    }
  }

  const openTransactionDetails = (data: INotification) => {
    setTransactionDetails({
      from: data?.data?.hash && data?.data?.from ? data?.data?.from : 'Metawine',
      to: data.data?.to || 'You',
      _id: data.data?.id,
      hash: data?.data?.hash || '',
      type: data.type,
      amount: data.data.amount,
      amountUSD: data.data.amountUSD,
      currency: data.data.currency,
      createdAt: data.createdAt,
      updatedAt: data.createdAt,
    })
  }

  useEffect(() => {
    socketWithdrawal.initSocket()
    signInForSocket()
    socketWithdrawal.subscriptionWithdrawal(listenNotification)

    return () => {
      socketWithdrawal.subscriptionWithdrawal(listenNotification, 'unsubscribe')
    }
  }, [])

  const signInForSocket = async () => {
    try {
      await socketWithdrawal.signIn()
    } catch (e: any) {
      console.error('signInForSocket', e)
    }
  }

  return (
    <SecondaryLayout header={<PageHeader title={t('notifications.title')} />}>
      <div className={styles.page__content}>
        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {notifications.length > 0 ? (
              <InfiniteScroll
                dataLength={notifications.length}
                next={loadMore}
                hasMore={pagination.page < pagination.pages}
                loader={<Spinner />}
                className={styles.page__listContainer}
              >
                {notifications.map((notification: any, index: number) => (
                  <NotificationItem
                    data={notification}
                    key={index}
                    onSelectWine={handleSelectWine}
                    onSelectTransaction={openTransactionDetails}
                  />
                ))}
                <div></div>
              </InfiniteScroll>
            ) : (
              <div className={styles.page__empty}>
                {t('notifications.empty_message')}
              </div>
            )}
          </>
        )}
      </div>
      {selectedWine && (
        <ScannedWine
          data={selectedWine}
          onClose={() => setSelectedWine(null)}
        />
      )}

      {!!transactionDetails && (
        <TransactionDetails
          data={transactionDetails}
          open={!!transactionDetails}
          onClose={() => setTransactionDetails(null)}
        />
      )}
    </SecondaryLayout>
  )
}
