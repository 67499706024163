import ModalContainer from "../../ModalContainer/ModalContainer"
import { useTranslation } from "react-i18next"
import { store } from "../../../store"
import styles from './LogOutModal.module.scss'

interface IProps {
  open: boolean
  onClose:() => void
}

export default function LogOutModal({ open, onClose }: IProps) {
  const { t } = useTranslation()
  const { dispatch } = store

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div className={styles.logout}>
        <div className={styles.logout__title}>{t('more.log_out_question')}</div>
        <div className={styles.logout__subtitle}>{t('more.log_out_sure')}</div>
        <button className="button" onClick={() => dispatch({ type: 'auth/logout' }) }>{t('more.log_out')}</button>
        <button className="button button--white" onClick={onClose}>{t('more.close')}</button>
      </div>
    </ModalContainer>
  )
}