import styles from './NFTCards.module.scss'
import { useTranslation } from "react-i18next";

import nft from '@assets/images/nft-MOCK.png'

import NFT from "../NFT/NFT";

export default function NFTCards() {
  const { t } = useTranslation()

  const mock = [
    {
      title: 'Monkey',
      description: 'Monkey',
      image: nft
    },
    {
      title: 'Monkey',
      description: 'Monkey',
      image: nft
    }
  ]
  return (
    <div className={styles.cards}>
      <div className={styles.cards__title}>{t('dashboard.nft')}</div>
      <div className={styles.cards__wrapper}>
        {mock.map((item, index) => (
          <NFT title={item.title} description={item.description} img={item.image} key={index} />
        ))}
      </div>
      <button className={`button ${styles.cards__button}`}>
        <span className={styles.cards__transfer}>{t('dashboard.transfer_nft')}</span>
        <span className={styles.cards__coming}>{t('dashboard.coming_soon')}</span>
      </button>
    </div>
  )
}