import dayjs from 'dayjs'
import ChatFooter from '../../../components/Chat/ChatFooter/ChatFooter'
import ChatMessage from '../../../components/Chat/ChatMessage/ChatMessage'
import PageHeader from '../../../components/PageHeader/PageHeader'
import styles from './Chat.module.scss'

import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { socketChat } from '../../../services/socket'
import axiosInstance from '../../../api/config'
import {
  EMessageType,
  IChatUser,
  IConversation,
} from '../../../types/chat.interface'
import { IMAGE_URL } from '../../../constants'

import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../../../components/Spinner/Spinner'
import { useSelector } from 'react-redux'
import { store } from '../../../store'
import { useTranslation } from 'react-i18next'
import ToastsContainer from '../../../components/Toasts/ToastsContainer/ToastsContainer'
import { isIOS, isMobile } from 'react-device-detect'

const LIMIT = 20

export default function Chat() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const { dispatch } = store
  const { me, lastCompanion } = useSelector((state: any) => state.chats)
  const { id } = useParams()

  const [user, setUser] = useState<IChatUser | null>(null)
  const [messages, setMessages] = useState<IConversation[]>([])
  const [pagination, setPagination] = useState(true)
  const [isFocused, setIsFocused] = useState(false)
  const [lastMessageId, setLastMessageId] = useState('')
  const [currentDate, setCurrentDate] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const [sendingMessage, setSendingMessage] = useState<IConversation | null>(
    null,
  )

  useEffect(() => {
    socketChat.initSocket()
    joinChat()
    initChat()
    addSocketListeners()

    if (lastCompanion?.id === id) {
      setUser(lastCompanion)
    }

    return () => {
      removeSocketListeners()
    }
  }, [])

  const addSocketListeners = async () => {
    await socketChat.subscriptionNewMessageInChat(newChatMessage)
  }

  const getFocus = (focus: boolean) => setIsFocused(focus)

  const removeSocketListeners = async () => {
    await socketChat.subscriptionNewMessageInChat(newChatMessage, 'unsubscribe')
    await socketChat.leftChat(id as string)
  }

  const initChat = async () => {
    await fetchMe()
    await fetchUser()
  }

  const fetchMessages = async () => {
    try {
      const { data } = await axiosInstance.get<IConversation[]>(
        `${process.env.REACT_APP_CHAT_API_URL}/api/chat/conversation/${id}`,
        {
          params: {
            limit: LIMIT,
          },
        },
      )
      setLastMessageId(data[0].id)
      setMessages(data.reverse())
      setCurrentDate(data[0].created_at)
      setIsLoading(false)
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)

      if (data.length) {
        socketChat.setViewedMessageInChat(id as string, data[0].id)
      }
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const fetchMoreMessages = async () => {
    try {
      const { data } = await axiosInstance.get<IConversation[]>(
        `${process.env.REACT_APP_CHAT_API_URL}/api/chat/conversation/${id}`,
        {
          params: {
            limit: LIMIT,
            conversationIdFrom: lastMessageId,
          },
        },
      )

      setLastMessageId(data[0].id)

      if (data.length < LIMIT) {
        setPagination(false)
      }

      data.pop()
      setMessages([...messages, ...data.reverse()])
    } catch (error) {
      setPagination(false)
    }
  }

  const fetchUser = async () => {
    try {
      const { data } = await axiosInstance.get<IChatUser>(
        `${process.env.REACT_APP_CHAT_API_URL}/api/user/${id}`,
      )

      setUser(data)
      dispatch({ type: 'chats/setLastCompanion', payload: data })

      await fetchMessages()
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const fetchMe = async () => {
    if (!me) {
      await dispatch({ type: 'chats/getMe' })
    }
  }

  const joinChat = async () => {
    try {
      await socketChat.joinToChat(id as string)
    } catch (e: any) {
      console.log('Error join chat', e)
    }
  }

  const newChatMessage = async (message: any) => {
    setMessages((messages) => [message, ...messages])
    setSendingMessage(null)
    await socketChat.setViewedMessageInChat(id as string, message.id)
  }

  const onSend = async (text: string, type: EMessageType) => {
    setSendingMessage({
      message: {
        text,
        type,
      },
      user: me,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      id: 'sending',
      chat_id: '',
    })

    await socketChat.sendMessageToChat(id as string, text, type)

    chatContainerRef.current?.scrollTo(0, 0)
  }

  const handleMessageInView = (message: IConversation) => {
    setCurrentDate(message.created_at)
  }

  return (
    <div className={`${styles.page} ${isIOS && isMobile ? styles['page--ios'] : ''}`}>
      <ToastsContainer bottom={77} />
      <div className={styles.page__header}>
        {!isLoading && (
          <PageHeader
            title={user?.name || user?.email || ''}
            colored
            avatar={user?.avatar ? `${IMAGE_URL}/${user?.avatar}` : ''}
            onClickBack={() => navigate(-1)}
            navigateOnBack={false}
            titleLink={`/profile/${user?.foreign_id}`}
            message={true}
          />
        )}
      </div>
      {currentDate && (
        <div className={styles.page__date}>
          {dayjs(currentDate).format('MMMM DD')}
        </div>
      )}

      <div
        ref={chatContainerRef}
        id="chat-container"
        className={`${styles.page__content} ${isFocused && styles.page__content__padding}`}
      >
        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={messages.length + 20}
            next={fetchMoreMessages}
            scrollThreshold={0.4}
            hasMore={pagination}
            loader={<Spinner />}
            className={styles.page__scroll}
            inverse={true}
            scrollableTarget="chat-container"
          >
            {!!sendingMessage && (
              <ChatMessage
                isLoadingImage={isLoadingImage}
                message={sendingMessage}
                me={me}
                onMessageInView={handleMessageInView}
                loading={true}
              />
            )}
            {messages.length ? (
              messages.map((message: IConversation) => (
                <ChatMessage
                  isLoadingImage={isLoadingImage}
                  key={message.id}
                  message={message}
                  me={me}
                  onMessageInView={handleMessageInView}
                />
              ))
            ) : (
              <p className={styles.page__empty}>{t('chat.no_messages')}</p>
            )}
          </InfiniteScroll>
        )}
      </div>
      <div className={styles.page__footer}>
        <ChatFooter getFocus={getFocus} onSend={onSend} disabled={false} />
      </div>
    </div>
  )
}
