import { useTranslation } from 'react-i18next'
import styles from './ScannedWine.module.scss'

import { prepareImageForDisplay } from '../../../utils/scan'
import ContentContainer from '../../Scan/ContentContainer/ContentContainer'
import { IWine } from '../../../types/notifications'
import { useEffect, useState } from 'react'

interface IProps {
  data: IWine
  onClose: () => void
}

export default function IdentifiedWine({ data, onClose }: IProps) {
  const { t } = useTranslation()
  const [isImgError, setIsImgError] = useState(false)

  useEffect(() => {
    const body = document.body

    body.style.overflow = 'hidden'

    return () => {
      body.style.overflow = 'auto'
    }
  }, [])

  const imageLink = (): string => {
    const images = data?.image?.variations || {}

    let key = 'bottle_medium_square'

    if ('bottle_medium_square' in images) {
      key = 'bottle_medium_square'
    } else if ('medium_square' in images) {
      key = 'medium_square'
    } else if ('small_square' in images) {
      key = 'small_square'
    } else {
      key = Object.keys(images)[0]
    }

    return prepareImageForDisplay(images[key] || '')
  }

  return (
    <div className={styles.container}>
      <ContentContainer title={t('notifications.identified_wine')}>
        <div className={styles.block}>
          <div className={styles.block__content}>
            <div className={styles.block__imgContainer}>
              {!isImgError && (
                <img
                  className={styles.block__img}
                  src={imageLink()}
                  alt=""
                  onError={() => setIsImgError(true)}
                />
              )}
            </div>
            <p className={styles.block__title}>{data.name || '-'}</p>
            <p className={styles.block__year}>{data.year || '-'}</p>
            <div className={styles.block__divider}></div>
            <p className={styles.block__price}>
              Avg Price $
              {new Intl.NumberFormat('en-US', {
                maximumSignificantDigits: 3,
              }).format(data?.price || 0)}{' '}
              {data?.bottleType?.volume_ml &&
                `/ ${data.bottleType.volume_ml}ml`}
            </p>
          </div>

          <div className={styles.block__buttons}>
            <button
              className="button button--outline-white"
              type="button"
              onClick={onClose}
            >
              {t('notifications.close')}
            </button>
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}
