import styles from './ProfilePosts.module.scss'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { useEffect, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { IFeedPost, IPagination } from '../../../types/feed.interface'
import axiosInstance from '../../../api/config'
import FeedInput from '../../../components/Feed/FeedInput/FeedInput'
import Spinner from '../../../components/Spinner/Spinner'
import FeedPost from '../../../components/Feed/FeedPost/FeedPost'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../../store'

export default function ProfilePosts() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const [, isMyProfile] = useOutletContext<any>()

  const [posts, setPosts] = useState<IFeedPost[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    countItems: 0,
    page: 1,
    pages: 1,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isIsLoadingImage, setIsLoadingImage] = useState(true)

  const { users } = useSelector((state: RootState) => state.posts)
  const { dispatch } = store

  useEffect(() => {
    const currentUser = users[id as string]

    if (!location.state?.isBack || !currentUser?.posts?.length) {
      fetchPosts()
    } else {
      if (currentUser) {
        setPosts(currentUser?.posts || [])
        setPagination(currentUser?.pagination || {})
        setIsLoading(false)

        if (location.state?.scrollTop) {
          const container = document.querySelector('#layout_with_navigation')

          setTimeout(() => {
            container?.scrollTo({
              top: location.state?.scrollTop,
            })
          }, 200)
        }
      } else {
        fetchPosts()
      }
    }
  }, [])

  const fetchPosts = async (page: number = 1) => {
    try {
      const { data } = await axiosInstance.get('/api/posts', {
        params: {
          page,
          userId: id,
        },
      })

      dispatch({
        type: 'posts/setPosts',
        payload: {
          id,
          posts:
            page === 1
              ? data?.data?.entities || []
              : [...posts, ...(data?.data?.entities || [])],
          pagination: {
            page: data?.data?.page || 1,
            pages: data?.data?.pages || 1,
            countItems: data?.data?.countItems || 0,
          },
        },
      })

      setPosts(
        page === 1
          ? data?.data?.entities || []
          : [...posts, ...(data?.data?.entities || [])],
      )

      setPagination({
        page: data?.data?.page || 1,
        pages: data?.data?.pages || 1,
        countItems: data?.data?.countItems || 0,
      })
      setIsLoading(false)
      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = () => {
    fetchPosts(+pagination.page + 1)
  }

  const handleDeletePost = (id: string) => {
    setPosts(posts.filter((post) => post._id !== id))

    dispatch({
      type: 'posts/setPosts',
      payload: {
        id,
        posts: posts.filter((post) => post._id !== id),
        pagination,
      },
    })
  }

  return (
    <div className={styles.page}>
      <div className={styles.page__content}>
        {isMyProfile() ? (
          <div className={styles.page__inputContainer}>
            <FeedInput
              placeholder={t('feed.input_placeholder')}
              onClick={() =>
                navigate('/create-post', {
                  state: { redirectTo: location.pathname },
                })
              }
            />
          </div>
        ) : (
          <div className={styles.page__label}>Posts</div>
        )}

        {isLoading ? (
          <div className={styles.page__loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {posts.length > 0 ? (
              <InfiniteScroll
                dataLength={posts.length}
                next={loadMore}
                hasMore={+pagination.page < +pagination.pages}
                loader={<></>}
                className={styles.page__feedContainer}
                scrollableTarget="layout_with_navigation"
              >
                {posts.map((post: IFeedPost) => (
                  <FeedPost
                    isLoadingImage={isIsLoadingImage}
                    post={post}
                    key={post._id}
                    onDelete={handleDeletePost}
                    isProfile={true}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <>
                {isMyProfile() ? null : (
                  <div className={styles.page__empty}>
                    There is nothing here yet
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
