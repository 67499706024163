import WithNavigation from '../../../layouts/WithNavigation/WithNavigation'
import { useSelector } from 'react-redux'
import { store } from '../../../store'

import DashboardBalance from '../../../components/DashboardBalance/DashboardBalance';
import DashboardAchievement from "../../../components/DashboardAchievement/DashboardAchievement";
import SalesStatistic from '../../../components/SalesStatistic/SalesStatistic'
import DashboardChartWrapper from "../../../components/DashboardChartWrapper/DashboardChartWrapper";
import NFTCards from "../../../components/NFTCards/NFTCards"

export default function ProfileDashboard() {
  const { dispatch } = store

  const { profile, achievement, isPending } = useSelector((state: any) => state.profile)
  const logout = () => {
    dispatch({ type: 'auth/logout' })
  }

  return (
    <div style={{ padding: 20 }}>
      <DashboardBalance profile={profile} isPending={isPending} />
      <DashboardAchievement isPending={isPending} visibleAchievement={profile?.showAchievements} achievement={achievement} />
      <SalesStatistic />
      <DashboardChartWrapper />
      <NFTCards />
    </div>
  )
}
