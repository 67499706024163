import { useTranslation } from 'react-i18next'
import styles from './ProfileHidden.module.scss'
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg'

export default function ProfileHidden() {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>
        <LockIcon className={styles.block__icon} />
      </div>
      <p className={styles.block__title}>{t('profile.hidden_title')}</p>
      <p className={styles.block__description}>
        {t('profile.hidden_description')}
      </p>
    </div>
  )
}
