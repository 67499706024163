import { useTranslation } from 'react-i18next'
import ContentContainer from '../ContentContainer/ContentContainer'
import WineCard from '../WineCard/WineCard'
import styles from './WineList.module.scss'
import { IWineSearchInfo } from '../../../types/scan.interface'

interface IProps {
  onSelect: (choice: number) => void
  onBack: () => void
  list: IWineSearchInfo[]
}

export default function WineList({ onSelect, onBack, list }: IProps) {
  const { t } = useTranslation()

  return (
    <ContentContainer title={t('scan.scan_wine.results')} onBack={onBack}>
      <div className={styles.block}>
        <div className={styles.block__list}>
          {list.map((item: IWineSearchInfo, index: number) => (
            <WineCard data={item} key={index} onClick={() => onSelect(index)} />
          ))}
        </div>
      </div>
    </ContentContainer>
  )
}
