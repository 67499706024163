import { ReactElement } from 'react'
import toast from 'react-hot-toast'

import { ReactComponent as CheckIcon } from '@assets/icons/check_circle.svg'

import BaseToast from '../components/Toasts/BaseToast/BaseToast'
import ErrorToast from '../components/Toasts/ErrorToast/ErrorToast'
import ImageErrorToast from '../components/Toasts/ImageErrorToast/ImageErrorToast'

export default function useNotification() {
  const success = (message: string) => {
    toast.custom(<BaseToast message={message} icon={<CheckIcon />} />)
  }

  const info = (message: string, icon: ReactElement = <CheckIcon />) => {
    toast.custom(<BaseToast message={message} icon={icon} />)
  }

  const error = (message: string) => {
    toast.custom(<ErrorToast message={message} />)
  }

  const imageError = () => {
    toast.custom(<ImageErrorToast />)
  }

  return {
    success,
    error,
    info,
    imageError,
  }
}
