import styles from './RestrictionBrowser.module.scss'

import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import image from '@assets/images/restriction_image.png'

export default function RestrictionBrowser() {
  return (
    <div className={styles.block}>
      <LogoIcon className={styles.block__logo} />
      <p className={styles.block__text}>
        Decentralized Autonomous Wine Alliance
      </p>

      <div className={styles.block__imgContainer}>
        <img className={styles.block__img} src={image} alt="MetaWine" />
      </div>

      <p className={styles.block__text}>
        Visit <span>app.metawine.pro</span> on mobile to install the app
      </p>
      <a
        href={process.env.REACT_APP_LANDING_URL}
        className={styles.block__button}
      >
        Back to Home
      </a>
    </div>
  )
}
