import { createModel } from '@rematch/core'
import axiosInstance from '../../api/config'
import { RootModel } from '.'
import {
  IAchievement,
  IAchievementResponse,
  IProfile,
  IProfileResponse,
  IProfit,
  IWinesSale,
  ITotalStatistic,
  ITotalStatisticResponse,
  IProfileMainBalance,
} from '../../types/profile.interface'

interface IProfileState {
  profile: IProfile | null
  takePhotoType: string
  achievement: IAchievement | null
  totalStatistic: ITotalStatistic | null
  profit: IProfit[] | null
  winesSales: IWinesSale[] | null
  isPending: boolean
}

export const profile = createModel<RootModel>()({
  state: {
    profile: null,
    takePhotoType: '',
    achievement: null,
    totalStatistic: null,
    profit: null,
    winesSales: null,
    isPending: false,
  } as IProfileState,
  reducers: {
    setProfile(state, payload: any) {
      return {
        ...state,
        profile: payload,
      }
    },
    clearProfile(state) {
      return {
        ...state,
        profile: null,
      }
    },
    setPhotoType(state, payload: string) {
      return {
        ...state,
        takePhotoType: payload,
      }
    },
    setAchievement(state, payload: any) {
      return {
        ...state,
        achievement: payload,
      }
    },
    setTotalStatistic(state, payload: any) {
      return {
        ...state,
        totalStatistic: payload,
      }
    },
    setProfit(state, payload: any) {
      return {
        ...state,
        profit: payload,
      }
    },
    setWinesSales(state, payload: any) {
      return {
        ...state,
        winesSales: payload,
      }
    },
    setIsPending(state, payload: boolean) {
      return {
        ...state,
        isPending: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getProfile(_, state) {
      dispatch({ type: 'profile/setIsPending', payload: true })

      try {
        const { data } =
          await axiosInstance.get<IProfileResponse>('/api/users/profile')

        dispatch({ type: 'profile/setProfile', payload: data.data })
        dispatch({ type: 'profile/getAchievement' })

        const userCountry = data.data.workPlace?.country

        if (!userCountry) {
          const timezoneCountries = state?.geo?.timezoneCountries || {}
          let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

          if (timezone === 'Europe/Kiev') {
            timezone = 'Europe/Kyiv'
          }

          if (timezone in timezoneCountries) {
            dispatch({
              type: 'profile/updateCountry',
              payload: timezoneCountries[timezone],
            })
          }
        }

        dispatch({ type: 'profile/setIsPending', payload: false })
      } catch (e: any) {
        dispatch({ type: 'profile/setIsPending', payload: false })
      }
    },
    async updateCountry(country: string) {
      try {
        await axiosInstance.patch('/api/users/profile', {
          workPlace: {
            country,
          },
        })

        dispatch({ type: 'profile/getProfile' })
      } catch (e: any) {}
    },
    async updatePhotoType(type: string) {
      dispatch({ type: 'profile/setPhotoType', payload: type })
    },
    async getAchievement() {
      try {
        const { data } = await axiosInstance.get<IAchievementResponse>(
          '/api/users/achievements',
        )

        dispatch({ type: 'profile/setAchievement', payload: data.data })
      } catch (e: any) {}
    },
    async getTotalStatistic(
      dateRange: 'weekly' | 'monthly' | 'quarterly' | 'yearly' | 'all_time',
    ) {
      try {
        const { data } = await axiosInstance.get<ITotalStatisticResponse>(
          '/api/users/profile/total-statistics',
          { params: { dateRange } },
        )

        dispatch({ type: 'profile/setTotalStatistic', payload: data.data })
      } catch (e: any) {}
    },
    async getProfit(data: IProfit[]) {
      dispatch.profile.setProfit(data)
    },
    async getWinesSales(data: IWinesSale[]) {
      dispatch.profile.setWinesSales(data)
    },
    updateBalance(amount: number, state: any) {
      if (!state.profile.profile) return

      const p: IProfile = { ...state.profile.profile }

      if (p.balance.main.length) {
        p.balance.main = p.balance?.main.map((item: IProfileMainBalance) => {
          if (item.currency === 'USDC') {
            item.balance = +item.balance + amount
          }

          return item
        })
      }

      p.hasNotifications = true

      dispatch({ type: 'profile/setProfile', payload: p })
    },
  }),
})
