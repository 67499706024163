import AuthHeader from '../../../components/AuthHeader/AuthHeader'
import styles from './RegistrationSocialScreen.module.scss'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import RegistrationSocialForm from '../../../components/Auth/RegistrationSocialForm/RegistrationSocialForm'
import { useLocation } from 'react-router-dom'

export default function RegistrationSocialScreen() {
  const { t } = useTranslation()
  const location = useLocation()

  const provider = location.state?.provider || 'Google'

  const formRef = useRef<any>(null)

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  return (
    <div className={`page ${styles.page}`}>
      <AuthHeader
        title={t('registerSocialScreen.title')}
        description={t('registerSocialScreen.description', { provider })}
      />
      <RegistrationSocialForm ref={formRef} />
      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={triggerForm}>
          {t('registerSocialScreen.create_account')}
        </button>
      </div>
    </div>
  )
}
