import { useTranslation } from 'react-i18next'
import styles from './SommelierLevelScreen.module.scss'

import SecondaryLayout from '../../layouts/SecondaryLayout/SecondaryLayout'
import PageHeader from '../../components/PageHeader/PageHeader'
import SommelierLevel from "../../components/SommelierLevel/SommelierLevel";

import { ReactComponent as StandartIcon } from "@assets/icons/standart.svg";
import { ReactComponent as MagnumIcon } from "@assets/icons/magnum.svg";
import { ReactComponent as JeroboamIcon } from "@assets/icons/jeroboam.svg";
import { ReactComponent as RehoboamIcon } from "@assets/icons/rehoboam.svg";
import { ReactComponent as MethuelahIcon } from "@assets/icons/methuselah.svg";
import { ReactComponent as SalmanazarIcon } from "@assets/icons/salmanazar.svg";
import { ReactComponent as BalthazarIcon } from "@assets/icons/balthazar.svg";
import { ReactComponent as NebuchadnezzarIcon } from "@assets/icons/nebuchadnezzar.svg";
import { ReactComponent as MelchiorIcon } from "@assets/icons/melchior.svg";
import { ReactComponent as SolomonIcon } from "@assets/icons/solomon.svg";

export default function SommelierLevelScreen() {
  const { t } = useTranslation()

  const sommelierLevels = [
    {
      icon: <StandartIcon />,
      name: t('sommelierLevel.standart'),
      range: '$0 - 999'
    },
    {
      icon: <MagnumIcon />,
      name: t('sommelierLevel.magnum'),
      range: '$1,000 - 4,999'
    },
    {
      icon: <JeroboamIcon />,
      name: t('sommelierLevel.jeroboam'),
      range: '$5,000 - 9,999'
    },
    {
      icon: <RehoboamIcon />,
      name: t('sommelierLevel.rehoboam'),
      range: '$10,000 - 19,999'
    },
    {
      icon: <MethuelahIcon />,
      name: t('sommelierLevel.methuselah'),
      range: '$20,000 - 39,999'
    },
    {
      icon: <SalmanazarIcon />,
      name: t('sommelierLevel.salmanazar'),
      range: '$40,000 - 79,999'
    },
    {
      icon: <BalthazarIcon />,
      name: t('sommelierLevel.balthazar'),
      range: '$80,000 - 159,999'
    },
    {
      icon: <NebuchadnezzarIcon />,
      name: t('sommelierLevel.nebuchadnezzar'),
      range: '$160,000 - 319,999'
    },
    {
      icon: <MelchiorIcon />,
      name: t('sommelierLevel.melchior'),
      range: '$320,000 - 639,999'
    },
    {
      icon: <SolomonIcon />,
      name: t('sommelierLevel.solomon'),
      range: '$640,000 - 1,279,999'
    }
  ]

  return (
    <SecondaryLayout
      colored={true}
      header={<PageHeader colored={true} title={t('sommelierLevel.header')} />}
    >
      <div className={styles.level}>
        <div className={styles.level__title}>{t('sommelierLevel.title')}</div>
        <div className={styles.level__subtitle}>{t('sommelierLevel.subtitle')}</div>
        <div className={styles.level__new}>{t('sommelierLevel.new_level')}</div>
        <div className={styles.level__complete}>{t('sommelierLevel.complete')}</div>

        {sommelierLevels.map((item, index) => (
          <SommelierLevel key={index} icon={item.icon} name={item.name} range={item.range} />
        ))}
      </div>
    </SecondaryLayout>
  )
}