import { Link, useNavigate } from 'react-router-dom'
import LoginForm from '../../../components/Auth/LoginForm/LoginForm'
import AuthHeader from '../../../components/AuthHeader/AuthHeader'
import styles from './LoginScreen.module.scss'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'

export default function LoginScreen() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const formRef = useRef<any>(null)

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  return (
    <div className={`page ${styles.page}`}>
      <AuthHeader
        title={t('loginScreen.title')}
        description={t('loginScreen.description')}
        navigateOnBack={false}
        onClickBack={() => navigate('/')}
      />
      <LoginForm ref={formRef} />
      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={triggerForm}>
          {t('loginScreen.login')}
        </button>
        <p className={styles.page__footer}>
          {t('loginScreen.dont_have_account')}{' '}
          <Link to="/registration">{t('loginScreen.register')}</Link>
        </p>
      </div>
    </div>
  )
}
