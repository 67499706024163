import { Link } from 'react-router-dom'
import Avatar from '../../Avatar/Avatar'
import styles from './ChatItem.module.scss'

import {
  IChatItem,
  IChatItemUser,
  IChatUser,
} from '../../../types/chat.interface'
import { IMAGE_URL } from '../../../constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: IChatItem
  me: IChatUser
  isLoadingImage?: boolean
}

export default function ChatItem({ item, me, isLoadingImage }: IProps) {
  const { t } = useTranslation()

  const myUser = item.user_chats.find(
    (item: IChatItemUser) => item.user?.id === me.id,
  )
  const companion = item.user_chats.find(
    (user: IChatItemUser) => user?.user?.id !== me?.id,
  )

  return (
    <Link to={`/chats/${companion?.user?.id}`} className={styles.item}>
      <div className={styles.avatarContainer}>
        <Avatar
          isLoading={isLoadingImage}
          url={companion?.user?.avatar ? `${IMAGE_URL}/${companion?.user?.avatar}` : ''}
          size={54}
          color="white"
        />
      </div>
      <div className={styles.item__body}>
        <p className={styles.item__name}>
          {companion?.user?.name || companion?.user?.email || ''}
        </p>
        {!!myUser?.unviewed_conversations_number && (
          <p className={styles.item__status}>{t('chat.unread_messages')}</p>
        )}
      </div>
    </Link>
  )
}
