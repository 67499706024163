import ContentContainer from '../ContentContainer/ContentContainer'
import styles from './ReceiptNotFound.module.scss'

import { ReactComponent as ErrorIcon } from '@assets/icons/scan_error.svg'
import { useTranslation } from 'react-i18next'

interface IProps {
  onBack: () => void
}

export default function ReceiptNotFound({ onBack }: IProps) {
  const { t } = useTranslation()

  return (
    <ContentContainer title={t('scan.receipt.error')}>
      <div className={styles.block}>
        <div className={styles.block__content}>
          <div className={styles.block__iconContainer}>
            <ErrorIcon />
          </div>
          <h2 className={styles.block__message}>
            {t('scan.receipt.not_found_message')}
          </h2>
        </div>

        <div className={styles.block__buttonContainer}>
          <button
            type="button"
            className="button button--outline-white"
            onClick={onBack}
          >
            {t('scan.receipt.back_to_camera')}
          </button>
        </div>
      </div>
    </ContentContainer>
  )
}
