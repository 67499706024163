import { createModel } from '@rematch/core'
import { getCookie, removeCookie, setCookie } from '../../services/cookie'
import { socketChat } from '../../services/socket'
import { socketBalance } from '../../services/socket_balance'
import { socketWithdrawal } from "../../services/socket_withdrawal"

export const auth = createModel()({
  state: {
    isAuthorized: !!getCookie('token'),
  },
  reducers: {
    setLogin(state) {
      return {
        ...state,
        isAuthorized: true,
      }
    },
    setLogout(state) {
      return {
        ...state,
        isAuthorized: false,
      }
    },
  },
  effects: (dispatch) => ({
    async logout() {
      removeCookie('token')

      dispatch.auth.setLogout()
      dispatch.chats.clearChats()

      try {
        if ('FB' in window) {
          window.FB.logout()
        }
        socketChat.closeSocket()
        socketBalance.closeSocket()
        socketWithdrawal.closeSocket()
      } catch (e: any) {}
    },

    async login(token: string) {
      setCookie('token', token, {
        domain: window.location.hostname,
        maxAge: 60 * 60 * 24 * 7,
        path: '/'
      })

      dispatch.auth.setLogin()
    },
  }),
})
