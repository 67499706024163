import axiosInstance from '../api/config'

export async function uploadImage(image: File) {
  try {
    const formData = new FormData()

    formData.append('image', image)

    const { data } = await axiosInstance.post('/api/upload/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data?.data?.filename
  } catch (e: any) {
    throw e
  }
}
