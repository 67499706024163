import { useEffect, useState } from 'react'
import styles from './ProfileTransactions.module.scss'
import { useTranslation } from "react-i18next"
import { DateRange } from 'react-day-picker'
import { store } from "../../../store"
import { useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component"

import { ReactComponent as Calendar } from "@assets/icons/calendar_big.svg"

import Transaction from "../../../components/Transaction/Transaction";
import TransactionDetails from "../../../components/modals/TransactionDetails/TransactionDetails"
import DatePickerModal from "../../../components/modals/DatePickerModal/DatePickerModal"

import axiosInstance from "../../../api/config";
import { ITransactionsEntities } from "../../../types/transactions.interface";
import Spinner from "../../../components/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import { socketWithdrawal } from "../../../services/socket_withdrawal";
import { ISocketNotification } from "../../../types/notifications";

export default function ProfileTransactions() {
  const { t } = useTranslation()
  const { dispatch } = store
  const location = useLocation()

  const [details, setDetails] = useState<ITransactionsEntities>()
  const [isOpenTransactionDetails, setIsOpenTransactionDetails] = useState(false)
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const [selectedDate, setSelectedDate] = useState<DateRange | undefined>()
  const [isPending, setIsPending] = useState(true)
  const [page, setPage] = useState(1)
  const [loadMorePending, setLoadMorePending] = useState(false)

  const { transactionsList, totalPages } = useSelector((state: any) => state.transactions)

  const getTransactionsList = async(currentPage: number = 1) => {
    page !== 1 && setLoadMorePending(true)

    let offsetMinutes

    if (selectedDate?.from) {
      const date = new Date(selectedDate?.from);
      offsetMinutes = date.getTimezoneOffset();
    }

    try {
      const response = await axiosInstance.get('/api/transactions', {
        params: {
          from: selectedDate?.from && offsetMinutes ? new Date(selectedDate?.from.getTime() - (offsetMinutes * 60 * 1000)) : undefined,
          to: selectedDate?.to && offsetMinutes ? new Date(selectedDate?.to.getTime() - (offsetMinutes * 60 * 1000)) : undefined,
          page: currentPage
        }
      })

      dispatch({ type: 'transactions/getTransactions', payload: response.data.data})

      setLoadMorePending(false)
      setPage(currentPage)
      setIsPending(false)
    } catch (e: any) {
      setIsPending(false)
      setLoadMorePending(false)
    }
  }

  const getDate = (date: DateRange | undefined) => {
    setPage(1)
    if (date?.from && !date?.to) {
      const lastDate = new Date(date.from);
      lastDate.setHours(23, 59, 59, 999);

      setSelectedDate({ from: date?.from, to: lastDate})
    } else {
      setSelectedDate(date)
    }
  }

  const loadMore = async () => {
    getTransactionsList(+page + 1);
  }

  const openDetails = (data: ITransactionsEntities) => {
    setDetails(data);
    setIsOpenTransactionDetails(true);
  }

  const listenTransaction = (res: ISocketNotification) => {
    if (res.hash) {
      getTransactionsList()

      if (details?._id === res.id) {
        setDetails({ ...details, hash: res.hash })
      }
    }
  }

  useEffect(() => {
    socketWithdrawal.initSocket()
    signInForSocket()
    socketWithdrawal.subscriptionWithdrawal(listenTransaction)

    return () => {
      socketWithdrawal.subscriptionWithdrawal(listenTransaction, 'unsubscribe')
    }
  }, [])

  const signInForSocket = async () => {
    try {
      await socketWithdrawal.signIn()
    } catch (e: any) {
      console.error('signInForSocket', e)
    }
  }

  useEffect(() => {
    if (!location.state?.isBack || !transactionsList?.length) {
      loadMore()
    } else {
      if (transactionsList) {
        if (location.state?.scrollTop) {
          const container = document.querySelector('#layout_with_navigation')

          setTimeout(() => {
            container?.scrollTo({
              top: location.state?.scrollTop,
            })
          }, 200)
        }
      } else {
        loadMore()
      }
    }
  }, [])


  useEffect(() => {
    getTransactionsList()
  }, [selectedDate])

  return (
    <div className={styles.page}>
      <div className={styles.page__content}>
        <div className={styles.page__header}>
          <div className={styles.page__title}>{t('transactions.title')}</div>
          <Calendar onClick={() => setIsOpenCalendar(true)} />
        </div>

        {isPending ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            {transactionsList.length > 0 ? (
              <InfiniteScroll
                dataLength={transactionsList.length}
                next={loadMore}
                hasMore={+page < +totalPages}
                loader={<></>}
                className={styles.page__feedContainer}
                scrollableTarget="layout_with_navigation"
              >
                {transactionsList?.map((item: ITransactionsEntities) => (
                  <div onClick={() => openDetails(item)}>
                    <Transaction data={item} />
                  </div>
                ))}
                {loadMorePending && <Spinner />}
              </InfiniteScroll>
            ) : (
              <div className={styles.page__empty}>{t('transactions.empty_list')}</div>
            )}
          </>
        )}
      </div>

      {details && (
        <TransactionDetails data={details} open={isOpenTransactionDetails} onClose={() => setIsOpenTransactionDetails(false)} />
      )}

      <DatePickerModal getDate={getDate} open={isOpenCalendar} onClose={() => setIsOpenCalendar(false)} />
    </div>
  )
}