import {useState, useEffect} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './PhoneCountrySelect.module.scss'
import { ReactComponent as Confirmed } from "@assets/icons/success_small.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { getCode } from 'country-list'

interface IProps {
  label?: string
  getPhoneValue: (phone: string) => void
  confirmed: boolean
  verifyPhone: () => void
}

export default function PhoneCountrySelect({ label, getPhoneValue, confirmed, verifyPhone }: IProps) {
  const { profile } = useSelector((state: any) => state.profile)

  const [value, setValue] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(profile?.workPlace?.country || 'us')
  const { t } = useTranslation()

  useEffect(() => {
    getPhoneValue(value)
  }, [value])

  const getPhoneCode = () => {
    if (selectedCountry !== 'us') {
      if (selectedCountry?.length > 2) {
        return getCode(selectedCountry)?.toLowerCase()
      } else {
        return selectedCountry.toLowerCase()
      }
    } else {
      return 'us'
    }
  }

  useEffect(() => {
    setSelectedCountry(profile?.workPlace?.country || 'us')
    setTimeout(() => {
      setValue(profile?.phone?.slice(1) || '')
    }, 20)
  }, [profile])

  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.select__phone}>
        <PhoneInput
          value={value}
          onChange={setValue}
          containerClass={styles.select}
          inputClass={styles.select__input}
          dropdownClass={styles.select__dropdown}
          country={getPhoneCode()}
        />
        <div className={styles.select__phone__confirmed}>
          {!profile?.phone ? '' : confirmed ? <Confirmed /> : <div onClick={verifyPhone}>{t('editProfileScreen.confirm')}</div>}
        </div>
      </div>
    </div>
  )
}