export enum ENotificationType {
  REPLENISH = 'REPLENISH',
  WITHDRAW = 'WITHDRAW',
  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
  PROFILE_FILLED = 'PROFILE_FILLED',
  ADDRESS_FILLED = 'ADDRESS_FILLED',
  PROMO_REWARD = 'PROMO_REWARD',
  REFERRAL_REGISTER = 'REFERRAL_REGISTER',
  WINE_SCANNED = 'WINE_SCANNED',
  RECEIVE_NFT = 'RECEIVE_NFT',
  SIGN_UP_BONUS = 'SIGN_UP_BONUS'
}

export enum ENotificationDataType {
  CHAT = 'chat',
  TRANSACTION = 'transaction',
}

export interface INotification {
  createdAt: string
  type: ENotificationType
  data: 'type' extends ENotificationType.NEW_CHAT_MESSAGE
    ? INotificationChat
    : INotificationTransaction
  _id: string
}

export interface INotificationTransaction {
  amount: number
  amountUSD: number
  currency: string
  id: string
  to?: string
  from?: string
  type: ENotificationDataType
  wine?: IWine
  hash?: string
}

export interface INotificationChat {
  chatId: string
  conversationId: string
  from: {
    id: string
    name: string
  }
  message: {
    text: string
    type: 'AR_MESSAGE' | 'TEXT_MESSAGE'
  }
  to: {
    id: string
  }
  type: ENotificationDataType
}

export interface INotificationResponse {
  data: {
    countItems: number
    entities: INotification[]
    page: string
    pages: number
  }
}

export interface IWine {
  name: string
  price: number
  year: number
  image: {
    location: string
    variations: Record<string, string>
  }
  bottleType?: {
    id: number
    name: string
    short_name: string
    short_name_plural: string
    volume_ml: number
  }
}

export interface ISocketNotification {
  id: string
  status: boolean
  hash: string
}
