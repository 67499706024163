import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import axiosInstance from "../../api/config";
import { useEffect, useRef } from 'react'
import styles from "../Auth/VerifyEmailScreen/VerifyEmailScreen.module.scss";
import ChangeEmailForm from "../../components/ChangeEmailForm/ChangeEmailForm";
import ConfirmChangeEmail from "../../components/ChangeEmailForm/ConfirmChangeEmail";
import ChangeEmailSuccess from "../../components/ChangeEmailForm/ChangeEmailSuccess";
import { useState } from 'react'
import { useNavigate } from "react-router-dom";


export default function ChangeEmail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const formRef = useRef<any>(null)

  const [step, setStep] = useState(0)
  const [newEmail, setNewEmail] = useState('')
  const [code, setCode] = useState('')
  const [confirmation, setConfirmation] = useState(true)

  const { profile } = useSelector((state: any) => state.profile)

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  const getNewEmail = (email: string, code: string) => {
    setNewEmail(email)
    setCode(code)
  }

  const changeStep = () => {
    setStep(step + 1)
  }

  const changeEmail = async () => {
    setConfirmation(false);

    try {
      await axiosInstance.post('/api/users/change-email-request')
    } catch (e: any) {}
  }

  const onClickBack = () => {
    if (step === 0 && !confirmation) {
      setConfirmation(true)
    } else if (step !== 0) {
      setStep(step - 1);
    } else {
      navigate('/edit-profile')
    }
  }

  return (
    <div className={`page ${styles.page}`}>
      {(step === 0 || step === 1) && (
        <AuthHeader
          title={step === 0 ? t('editProfileScreen.email_title') : t('verifyEmail.confirm_changes')}
          description={
            <Trans
              i18nKey={step === 0 && confirmation ? 'verifyEmail.description_send' : step === 0 && !confirmation ? 'verifyEmail.description' : 'verifyEmail.description_new'}
              values={{ email: step === 0 ? profile?.email : newEmail }}
              components={{ bold: <b /> }}
            />
          }
          navigateOnBack={false}
          onClickBack={onClickBack}
          step={step}
          confirmation={confirmation}
        />
      )}

      {step === 0 && <ChangeEmailForm changeEmail={changeEmail} confirmation={confirmation} getNewEmail={getNewEmail} ref={formRef} email={profile?.email} changeStep={changeStep} />}
      {step === 1 && <ConfirmChangeEmail ref={formRef} email={newEmail} code={code} changeStep={changeStep} />}
      {step === 2 && <ChangeEmailSuccess />}

      {!confirmation && (
        <div className={styles.page__buttons}>
          <button className="button" type="submit" onClick={step === 2 ? () => navigate('/edit-profile') : triggerForm}>
            {step === 2
              ? t('verifyEmail.continue')
              : step === 1
                ? t('verifyEmail.confirm')
                : t('editProfileScreen.change')}
          </button>
        </div>
      )}
    </div>
  )
}