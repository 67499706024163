import { Link } from 'react-router-dom'
import AuthHeader from '../../../components/AuthHeader/AuthHeader'
import styles from './RegistrationScreen.module.scss'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import RegistrationForm from '../../../components/Auth/RegistrationForm/RegistrationForm'

export default function RegistrationScreen() {
  const { t } = useTranslation()
  const formRef = useRef<any>(null)

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isCheckedEmail, setIsCheckedEmail] = useState<boolean>(false)

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }
  const handleCheckbox = (value: boolean) => {
    setIsChecked(value)
  }

  const handleCheckboxEmail = (value: boolean) => {
    setIsCheckedEmail(value)
  }

  return (
    <div className={`page ${styles.page}`}>
      <AuthHeader
        title={t('registerScreen.title')}
        description={t('registerScreen.description')}
      />
      <RegistrationForm ref={formRef} onChangeCheckbox={handleCheckbox} onChangeCheckboxEmail={handleCheckboxEmail} />
      <div className={styles.page__buttons}>
        <button
          className="button"
          type="submit"
          disabled={!isChecked || !isCheckedEmail}
          onClick={triggerForm}
        >
          {t('registerScreen.create_account')}
        </button>
        <p className={styles.page__footer}>
          {t('registerScreen.have_account')}{' '}
          <Link to="/login">{t('registerScreen.login')}</Link>
        </p>
      </div>
    </div>
  )
}
