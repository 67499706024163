import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import styles from '../../pages/EditProfileScreen/EditProfileScreen.module.scss'
import Input from '../Input/Input'
import EditChange from '../EditChange/EditChange'
import CountrySelect from '../CountrySelect/CountrySelect'
import Toggle from '../Toggle/Toggle'
import PhoneCountrySelect from '../PhoneCountrySelect/PhoneCountrySelect'
import axiosInstance from '../../api/config'
import { useNavigate } from 'react-router-dom'
import PlaceInput from '../PlaceInput/PlaceInput'
import { IPlaceInputParams } from '../../types/place.interface'
import { store } from '../../store'
import useNotification from '../../hooks/useNotification'
import CategoryIcon from '../CategoryIcon/CategoryIcon'
import { levelColor } from '../../constants/level'
import RoleModal from '../modals/RoleModal/RoleModal'
import { ECategories } from '../../types/profile.interface'

export default function PersonalDetails() {
  const { profile } = useSelector((state: any) => state.profile)
  const { dispatch } = store

  const [activeViewProfile, setActiveViewProfile] = useState<'everyone' | 'me'>(
    'everyone',
  )
  const [phoneValue, setPhoneValue] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(
    profile?.workPlace?.country || '',
  )
  const [showAchivements, setShowAchievements] = useState(false)
  const [geo, setGeo] = useState<number[]>([])
  const [cityLocation, setCityLocation] = useState<[number, number] | null>(
    null,
  )
  const [text, setText] = useState('')
  const [isOpenRoleModal, setIsOpenRoleModal] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const notification = useNotification()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    formik.setValues({
      name: profile?.name || '',
      about: text || '',
      experience: profile?.yearsExpirience || 0,
      address: profile?.workPlace?.city || '',
      company: profile?.workPlace?.company || '',
      placeId: '',
    })

    setSelectedCountry(profile?.workPlace?.country || '')
    setShowAchievements(profile?.showAchievements || false)
    setActiveViewProfile(profile?.viewable ? 'everyone' : 'me')
  }, [profile])

  useEffect(() => {
    setText(profile?.description || '')
  }, [profile])

  const formik = useFormik({
    initialValues: {
      name: profile?.name || '',
      about: text || '',
      experience: profile?.yearsExpirience || 0,
      address: profile?.workPlace?.city || '',
      company: profile?.workPlace?.company || '',
      placeId: '',
    },
    onSubmit: async (values: any) => {
      handleSubmit(values)
    },
  })

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      const body = {
        name: values.name || '',
        phone: phoneValue ? `+${phoneValue}` : undefined,
        yearsExpirience: values.experience ? +values.experience : undefined,
        showAchievements: showAchivements,
        description: text || '',
        workPlace: {
          country: selectedCountry,
          city: values.address || undefined,
          company: values.company || undefined,
          geo: geo.length ? geo : undefined,
          placeId: values.placeId || undefined,
        },
        viewable: activeViewProfile === 'everyone',
      }

      try {
        await axiosInstance.patch('/api/users/profile', body)

        dispatch({ type: 'profile/getProfile' })

        notification.success(t('editProfileScreen.saved_successfully'))
      } catch (e: any) {
        let errorMessage = t('editProfileScreen.error_message')

        if (
          e?.response?.data?.data?.message?.[0] ===
          'phone must be a valid phone number'
        ) {
          errorMessage = t('editProfileScreen.error_phone')
        } else if (
          typeof e?.response?.data?.data?.message === 'string' &&
          e?.response?.data?.data?.message.includes('Phone number already used')
        ) {
          errorMessage = e?.response?.data?.data?.message
        } else {
          errorMessage = t('editProfileScreen.error_message')
        }
        notification.error(errorMessage)
      }
    } catch (e: any) {}
  }

  const verifyPhone = async () => {
    navigate('/verify-phone')
  }

  const handleNumberKeyDown = (e: any) => {
    if (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleChange = (e: any) => {
    let newValue = e.target.value

    if (newValue.length > 2) {
      newValue = newValue.slice(0, 2)
    }

    newValue = newValue.replace(/\D/g, '')
    formik.setFieldValue('experience', newValue)
  }

  const handleChangeName = (e: any) => {
    let newValue = e.target.value
    if (/^[a-zA-Zа-яА-Я\s-]*$/.test(newValue)) {
      formik.setFieldValue('name', newValue)
    }
  }

  const changeActiveView = (view: 'everyone' | 'me') =>
    setActiveViewProfile(view)

  const getPhoneValue = (phone: string) => setPhoneValue(phone)

  const handleAddressChange = (value: IPlaceInputParams) => {
    formik.setFieldValue('address', value.value)

    if (!value.googleInfo) return

    const lat = value.googleInfo.geometry.location.lat()
    const lng = value.googleInfo.geometry.location.lng()

    if (lat && lng) {
      setCityLocation([lat, lng])
    }
  }

  const handleCompanyChange = (value: IPlaceInputParams) => {
    formik.setFieldValue('company', value.value)

    if (!value.googleInfo) return

    formik.setFieldValue('placeId', value.googleInfo.place_id)

    const lat = value.googleInfo.geometry.location.lat()
    const lng = value.googleInfo.geometry.location.lng()

    if (lat && lng) {
      setGeo([lat, lng])
    }
  }

  useEffect(() => {
    let observe: any = null

    //@ts-ignore
    if (window.attachEvent) {
      //@ts-ignore
      observe = function (element, event, handler) {
        element.attachEvent('on' + event, handler)
      }
    } else {
      //@ts-ignore
      observe = function (element, event, handler) {
        element.addEventListener(event, handler, false)
      }
    }
    function init() {
      function resize() {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto'
          textareaRef.current.style.height =
            textareaRef.current.scrollHeight + 'px'

          let scrollHeight = textareaRef.current.scrollHeight

          if (scrollHeight > 340) {
            textareaRef.current.style.overflow = 'auto'
          } else {
            textareaRef.current.style.overflow = 'hidden'
          }

          if (scrollHeight > 340) {
            scrollHeight = 340
          }

          textareaRef.current.style.maxHeight = scrollHeight + 'px'
        }
      }

      function delayedResize() {
        window.setTimeout(resize, 0)
      }

      observe(textareaRef.current, 'change', resize)
      observe(textareaRef.current, 'cut', delayedResize)
      observe(textareaRef.current, 'paste', delayedResize)
      observe(textareaRef.current, 'drop', delayedResize)
      observe(textareaRef.current, 'keydown', delayedResize)

      resize()
    }

    setTimeout(() => {
      init()
    }, 500)
  }, [])

  return (
    <form className={styles.editProfile__form} onSubmit={formik.handleSubmit}>
      <div className={styles.editProfile__form__row}>
        <Input
          name="name"
          id="name"
          maxLength={50}
          label={t('editProfileScreen.name')}
          placeholder={t('editProfileScreen.name')}
          value={formik.values.name}
          onChange={handleChangeName}
          onBlur={formik.handleBlur}
          errors={formik.errors.name}
          onKeyDown={handleKeyPress}
          isError={formik.touched.name && Boolean(formik.errors.name)}
        />
      </div>

      {profile?.email && !profile?.email.endsWith('@random.email') && (
        <div className={styles.editProfile__form__row}>
          <EditChange
            title={t('editProfileScreen.email')}
            label={profile?.email}
            button={t('editProfileScreen.change_email')}
          />
        </div>
      )}

      <div className={styles.editProfile__form__row}>
        <PhoneCountrySelect
          verifyPhone={verifyPhone}
          confirmed={profile?.phoneConfirmed}
          getPhoneValue={getPhoneValue}
          label={t('editProfileScreen.phone_number')}
        />
      </div>

      <div className={styles.editProfile__form__row}>
        <label className={styles.block__label}>
          {t('editProfileScreen.about')}
        </label>
        <textarea
          name="about"
          id="about"
          ref={textareaRef}
          className={styles.block__input}
          placeholder={t('editProfileScreen.about')}
          value={text}
          onChange={(e) => setText(e.target.value)}
          maxLength={500}
          contentEditable
        />
      </div>

      <div className={styles.editProfile__form__row}>
        <Input
          name="experience"
          id="experience"
          type="number"
          min={0}
          maxLength={2}
          label={t('editProfileScreen.experience')}
          placeholder={'0'}
          value={formik.values.experience}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          onKeyDown={handleNumberKeyDown}
        />
      </div>

      <div className={styles.editProfile__form__row}>
        <Input
          name="role"
          id="role"
          label={t('categories_modal.title')}
          placeholder={t('categories_modal.title')}
          value={t(`categories.${profile?.category || 'sommelier'}.short_title`)}
          editable={false}
          leftContent={
            <div
              className={styles.block__roleIcon}
              style={{ color: levelColor[profile?.level] }}
            >
              <CategoryIcon category={profile?.category} />
            </div>
          }
          rightContent={
            <button
              className={styles.block__roleButton}
              type="button"
              onClick={() => setIsOpenRoleModal(true)}
            >
              {t('categories_modal.confirm')}
            </button>
          }
        />
      </div>

      <RoleModal
        isOpen={isOpenRoleModal}
        onClose={() => setIsOpenRoleModal(false)}
        currentCategory={profile?.category || ECategories.SOMMELIER}
      />

      {!profile?.email?.endsWith('@random.email') && (
        <div className={styles.editProfile__form__row}>
          <EditChange
            password={true}
            title={t('editProfileScreen.password')}
            button={t('editProfileScreen.change_password')}
          />
        </div>
      )}

      <div className={styles.editProfile__form__block}>
        <div
          className={`${styles.editProfile__subtitle} ${styles.editProfile__subtitle__more}`}
        >
          {t('editProfileScreen.view_profile')}
        </div>
        <div className={styles.editProfile__view}>
          <button
            className={`button button--short ${activeViewProfile !== 'everyone' && styles.editProfile__view__notActive}`}
            type="button"
            onClick={() => changeActiveView('everyone')}
          >
            {t('editProfileScreen.view_everyone')}
          </button>
          <button
            className={`button button--short ${activeViewProfile !== 'me' && styles.editProfile__view__notActive}`}
            type="button"
            onClick={() => changeActiveView('me')}
          >
            {t('editProfileScreen.view_only_me')}
          </button>
        </div>
        <div className={styles.editProfile__achievements}>
          {t('editProfileScreen.achievements')}
          <Toggle
            value={showAchivements}
            onChange={() => setShowAchievements(!showAchivements)}
          />
        </div>
      </div>

      <div className={styles.editProfile__work}>
        <div className={styles.editProfile__details}>
          {t('editProfileScreen.work_place')}
        </div>
        <div className={styles.editProfile__work__subtitle}>
          {t('editProfileScreen.work_subtitle')}
        </div>

        <div className={styles.editProfile__form__block}>
          <CountrySelect
            label={t('editProfileScreen.country')}
            defaultValue={selectedCountry}
            onChange={(e) => setSelectedCountry(e.label)}
          />
        </div>

        <div className={styles.editProfile__form__block}>
          <PlaceInput
            onChange={handleAddressChange}
            initialValue={formik.values.address}
            label={t('editProfileScreen.address')}
            placeholder={t('editProfileScreen.address_placeholder')}
            type="address"
            country={selectedCountry}
            onKeyDown={handleKeyPress}
          />
        </div>

        {formik.values.address && (
          <div className={styles.editProfile__form__block}>
            <PlaceInput
              onChange={handleCompanyChange}
              initialValue={formik.values.company}
              label={t('editProfileScreen.company')}
              placeholder={t('editProfileScreen.company_placeholder')}
              type="place"
              location={cityLocation}
              country={selectedCountry}
              onKeyDown={handleKeyPress}
            />
          </div>
        )}

        <div id="places_container"></div>

        <div className={styles.editProfile__form__block}>
          <div className={styles.editProfile__company}>
            {t('editProfileScreen.company_name')}
          </div>
          <div className={styles.editProfile__companyName}>
            {formik.values.company ||
              profile?.workPlace?.company ||
              t('editProfileScreen.not_specified')}
          </div>
        </div>
      </div>

      <div className={styles.editProfile__buttonContainer}>
        <button type="submit" className="button">
          {t('editProfileScreen.save_changes')}
        </button>
      </div>
    </form>
  )
}
