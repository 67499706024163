import { isIOS } from 'react-device-detect'

import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import { ReactComponent as DotsIcon } from '@assets/icons/dots.svg'
import { ReactComponent as PhoneIcon } from '@assets/icons/phone_upload.svg'
import { ReactComponent as SafariShareIcon } from '@assets/icons/safari_share.svg'
import { ReactComponent as PlusIcon } from '@assets/icons/plus_square.svg'

import videoIos from '@assets/video/guide_ios.mp4'
import videoAndroid from '@assets/video/guide_android.mp4'

import styles from './RestrictionMobile.module.scss'
import { useState } from 'react'
import Spinner from '../../Spinner/Spinner'

export default function RestrictionMobile() {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className={styles.block}>
      <LogoIcon className={styles.block__logo} />

      <p className={styles.block__text}>
        Sign up with your email, <br /> we`ll create a base wallet for you
      </p>

      <div className={styles.block__imgContainer}>
        <video
          className={styles.block__img}
          src={isIOS ? videoIos : videoAndroid}
          autoPlay
          muted
          controls={false}
          loop
          playsInline
          preload="auto"
          onPlay={() => setIsLoading(false)}
        ></video>
        {isLoading && (
          <div className={styles.block__loader}>
            <Spinner />
          </div>
        )}
      </div>

      {isIOS ? (
        <>
          <p className={styles.block__description}>
            Tap the <SafariShareIcon /> and choose{' '}
          </p>
          <div
            className={`${styles.block__box} ${styles['block__box--safari']}`}
          >
            {' '}
            <span>Add to Home screen</span> <PlusIcon />
          </div>
          <p className={styles.block__description}>
            to install the app if you are in Safari
          </p>
        </>
      ) : (
        <>
          <p className={styles.block__description}>
            Tap the <DotsIcon className={styles.block__dots} /> button{' '}
          </p>
          <p className={styles.block__description}>
            on your Chrome browser and choose
          </p>
          <div className={styles.block__box}>
            <PhoneIcon /> Add to Home screen
          </div>
          <p className={styles.block__description}>in the options</p>
        </>
      )}
    </div>
  )
}
