import styles from './PhotoPreview.module.scss'

interface IProps {
  dataUri: string
  isFullscreen?: boolean
}

export default function PhotoPreview({ dataUri, isFullscreen }: IProps) {
  return (
    <div
      className={`${styles.preview} ${isFullscreen ? styles['preview--fullscreen'] : ''}`}
    >
      <img className={styles.preview__img} src={dataUri} alt="Preview" />
    </div>
  )
}
