import { IWineSearchInfo } from '../../../types/scan.interface'
import { prepareImageForDisplay } from '../../../utils/scan'
import styles from './WineCard.module.scss'

interface IProps {
  onClick: () => void
  data: IWineSearchInfo
}

export default function WineCard({ onClick, data }: IProps) {
  const imageLink = (): string => {
    const images = data?.image?.variations

    let key = 'bottle_medium_square'

    if ('bottle_medium_square' in images) {
      key = 'bottle_medium_square'
    } else if ('medium_square' in images) {
      key = 'medium_square'
    } else if ('small_square' in images) {
      key = 'small_square'
    } else {
      key = Object.keys(images)[0]
    }

    return prepareImageForDisplay(images[key] || '')
  }

  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.card__imgContainer}>
        <img className={styles.card__img} src={imageLink()} alt="Wine" />
      </div>

      <div className={styles.card__content}>
        <div className={styles.card__titleContainer}>
          <p className={styles.card__title}>{data?.name || ''}</p>
        </div>
        <p className={styles.card__year}>{data?.year || '-'}</p>
        <p className={styles.card__price}>
          ~ {data?.price?.currency?.prefix || ''}{' '}
          {new Intl.NumberFormat('en-US', {
            maximumSignificantDigits: 3,
          }).format(data?.price?.amount || 0)}{' '}
          {data?.price?.currency?.suffix || ''}{' '}
          <span> / {data?.price?.bottle_type?.volume_ml || 0} ml</span>
        </p>
      </div>
    </div>
  )
}
