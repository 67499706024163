import styles from './Toggle.module.scss'

interface IProps {
  onChange: () => void
  value: boolean
}

export default function Toggle({ onChange, value }: IProps) {
  return (
    <label className={styles.toggle}>
      <input type="checkbox" onChange={onChange} checked={value} />
      <span
        className={`${styles.toggle__slider} ${value ? styles['toggle__slider--checked'] : ''} `}
      />
    </label>
  )
}
