import { createModel } from '@rematch/core'
import axiosInstance from '../../api/config'
import { RootModel } from '.'
import { IPosition } from '../../types/map.interface'
import { generateTimezoneToCountryMap } from '../../utils/geo'

interface IGeoState {
  position: IPosition | null
  isEnabled: boolean
  timezoneCountries: Record<string, string> | null
}

export const geo = createModel<RootModel>()({
  state: {
    position: null,
    isEnabled: true,
    timezoneCountries: generateTimezoneToCountryMap() || null,
  } as IGeoState,
  reducers: {
    setPosition(state, payload: any) {
      return {
        ...state,
        position: payload,
        isEnabled: true,
      }
    },
    clearGeo(state) {
      return {
        ...state,
        position: null,
      }
    },
    setIsEnabled(state, payload: boolean) {
      return {
        ...state,
        isEnabled: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async sendPosition(pos) {
      try {
        await axiosInstance.post('/api/users/location', {
          location: [pos.lat, pos.lng],
        })

        dispatch({ type: 'profile/setPosition', payload: pos })
      } catch (e: any) {}
    },
  }),
})
