import { createModel } from '@rematch/core'
import { IFeedPost, IPagination } from '../../types/feed.interface'

export interface IFeedState {
  pagination: IPagination
  posts: IFeedPost[]
}

export const feed = createModel()({
  state: {
    pagination: {
      countItems: 0,
      page: 1,
      pages: 1,
    },
    posts: [],
  } as IFeedState,
  reducers: {
    setFeed(state, payload: IFeedState) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: () => ({}),
})
