import ModalContainer from '../ModalContainer/ModalContainer'

import styles from './ConfirmModal.module.scss'

interface IProps {
  title: string
  confirmButtonTitle: string
  cancelButtonTitle: string
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

export default function ConfirmModal({
  isOpen,
  title,
  confirmButtonTitle,
  cancelButtonTitle,
  onCancel,
  onConfirm,
}: IProps) {
  return (
    <ModalContainer isOpen={isOpen} onClose={onCancel}>
      <div className={styles.block}>
        <p className={styles.block__title}>{title}</p>

        <div className={styles.block__buttons}>
          {confirmButtonTitle && (
            <button className={`button`} type="button" onClick={onConfirm}>
              {confirmButtonTitle}
            </button>
          )}
          {cancelButtonTitle && (
            <button
              className={`button button--white `}
              type="button"
              onClick={onCancel}
            >
              {cancelButtonTitle}
            </button>
          )}
        </div>
      </div>
    </ModalContainer>
  )
}
