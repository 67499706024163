import { useEffect, useState } from 'react'
import ChatEmpty from '../../../components/Chat/ChatEmpty/ChatEmpty'
import ChatItem from '../../../components/Chat/ChatItem/ChatItem'
import WithNavigation from '../../../layouts/WithNavigation/WithNavigation'

import styles from './ChatsScreen.module.scss'
import { useSelector } from 'react-redux'
import { socketChat } from '../../../services/socket'
import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../../../components/Spinner/Spinner'
import { store } from '../../../store'
import { IChatItem } from '../../../types/chat.interface'

export default function ChatsScreen() {
  const {
    me,
    chats,
    chatsPagination: pagination,
  } = useSelector((state: any) => state.chats)
  const { dispatch } = store

  const [isLoading, setIsLoading] = useState(true)
  const [updatedChatItem, setUpdatedChatItem] = useState<IChatItem | null>(null)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  function onChatsUpdates(res: IChatItem[]) {
    setUpdatedChatItem(res[0])
  }

  const updateChats = () => {
    if (updatedChatItem) {
      const item = updatedChatItem
      const allChats = chats.map((chat: IChatItem) => {
        if (chat.id === item.id) {
          return item
        }
        return chat
      })

      dispatch({ type: 'chats/setChats', payload: allChats })
    }
  }

  useEffect(() => {
    initChats()

    return () => {
      socketChat.subscriptionUpdatedInChats(onChatsUpdates, 'unsubscribe')
      socketChat.leftChats()
      socketChat.closeSocket()
    }
  }, [])

  useEffect(() => {
    updateChats()
  }, [updatedChatItem])

  const initChats = async () => {
    await socketChat.initSocket()
    await socketChat.joinToChats()
    await dispatch({ type: 'chats/getMe' })
    await fetchChats()

    socketChat.subscriptionUpdatedInChats(onChatsUpdates)
  }

  const fetchChats = async () => {
    try {
      await dispatch({
        type: 'chats/getChats',
      })

      setIsLoading(false)

      setTimeout(() => {
        setIsLoadingImage(false)
      }, 1000)
    } catch (e: any) {
      setIsLoading(false)
    }
  }

  const loadMore = async () =>
    await dispatch({
      type: 'chats/loadMoreChats',
    })

  return (
    <WithNavigation>
      {isLoading ? (
        <div className={styles.page__loader}>
          <Spinner />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={chats.length}
          next={loadMore}
          hasMore={pagination}
          loader={<Spinner />}
          className={styles.page__feedContainer}
        >
          {chats.length ? (
            <div className={styles.page__list}>
              {chats.map((item: any) => (
                <ChatItem isLoadingImage={isLoadingImage} key={item.id} item={item} me={me} />
              ))}
            </div>
          ) : (
            <ChatEmpty />
          )}
        </InfiniteScroll>
      )}
    </WithNavigation>
  )
}
