import { createModel } from '@rematch/core'
import axiosInstance from '../../api/config'
import { RootModel } from '.'
import { IChatItem, IChatUser, IConversation } from '../../types/chat.interface'

const CHAT_URL = process.env.REACT_APP_CHAT_API_URL
const LIMIT = 20

interface IChatState {
  chats: IChatItem[]
  me: IChatUser | null
  chatsPagination: boolean
  chatsOffset: number
  isLoadingChats: boolean
  lastCompanion: IChatUser | null
  lastMessages: IConversation[]
}

export const chats = createModel<RootModel>()({
  state: {
    chats: [],
    me: null,
    chatsPagination: false,
    chatsOffset: 0,
    isLoadingChats: true,
    lastCompanion: null,
    lastMessages: [],
  } as IChatState,
  reducers: {
    clearChats(state) {
      return {
        ...state,
        chats: [],
        me: null,
        chatsPagination: false,
        chatsOffset: 0,
        isLoadingChats: true,
        lastCompanion: null,
        lastMessages: [],
      }
    },
    setMe(state, payload: IChatUser) {
      return {
        ...state,
        me: payload,
      }
    },
    setChats(state, payload: IChatItem[]) {
      return {
        ...state,
        chats: payload,
      }
    },
    setMoreChats(state, payload: IChatItem[]) {
      return {
        ...state,
        chats: [...state.chats, ...payload],
      }
    },
    setPagination(state, payload: boolean) {
      return {
        ...state,
        chatsPagination: payload,
      }
    },
    setOffset(state, payload: number) {
      return {
        ...state,
        chatsOffset: payload,
      }
    },
    setIsLoading(state, payload: boolean) {
      return {
        ...state,
        isLoadingChats: payload,
      }
    },
    setLastCompanion(state, payload: IChatUser) {
      return {
        ...state,
        lastCompanion: payload,
      }
    },
    setLastMessages(state, payload: IConversation[]) {
      return {
        ...state,
        lastMessages: payload,
      }
    },
  },
  effects: (dispatch) => ({
    async getMe() {
      dispatch({ type: 'chats/setIsLoading', payload: true })

      try {
        const { data } = await axiosInstance.get<IChatUser>(
          `${CHAT_URL}/api/user/me`,
        )

        dispatch({ type: 'chats/setMe', payload: data })
        setTimeout(() => {
          dispatch({ type: 'chats/setIsLoading', payload: false })
        }, 1000)
      } catch (e: any) {
        setTimeout(() => {
          dispatch({ type: 'chats/setIsLoading', payload: false })
        }, 1000)
      }
    },
    async getChats() {
      const { data } = await axiosInstance.get<IChatItem[]>(
        `${CHAT_URL}/api/chat/search`,
        {
          params: {
            limit: LIMIT,
            offset: 0,
          },
        },
      )

      if (data.length >= LIMIT) {
        dispatch({ type: 'chats/setPagination', payload: false })
      }

      dispatch({ type: 'chats/setChats', payload: data })
    },
    async loadMoreChats(_, state) {
      const newOffset = state.chatsOffset + LIMIT

      const { data } = await axiosInstance.get<IChatItem[]>(
        `${CHAT_URL}/api/chat/search`,
        {
          params: {
            limit: LIMIT,
            offset: newOffset,
          },
        },
      )

      dispatch({ type: 'chats/setMoreChats', payload: data })
      dispatch({ type: 'chats/setOffset', payload: newOffset })

      if (data.length < LIMIT) {
        dispatch({ type: 'chats/setPagination', payload: false })
      }
    },
  }),
})
