import AuthHeader from '../../../components/AuthHeader/AuthHeader'
import styles from './VerifyEmailScreen.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useRef } from 'react'
import VerifyEmailForm from '../../../components/Auth/VerifyEmailForm/VerifyEmailForm'
import { useLocation } from 'react-router-dom'

export default function VerifyEmailScreen() {
  const { t } = useTranslation()
  const location = useLocation()

  const email = location.state?.email || 'test@gmailc.com'
  const password = location.state?.password || ''

  const formRef = useRef<any>(null)

  const triggerForm = async () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  return (
    <div className={`page ${styles.page}`}>
      <AuthHeader
        title={t('verifyEmail.title')}
        description={
          <Trans
            i18nKey="verifyEmail.description"
            values={{ email }}
            components={{ bold: <b /> }}
          />
        }
      />
      <VerifyEmailForm ref={formRef} email={email} password={password} />
      <div className={styles.page__buttons}>
        <button className="button" type="submit" onClick={triggerForm}>
          {t('verifyEmail.confirm_email')}
        </button>
      </div>
    </div>
  )
}
