import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom'
import SplashScreen from './pages/Auth/SplashScreen/SplashScreen'
import { store } from './store'
import { Provider, useSelector } from 'react-redux'
import Modal from 'react-modal'
import UIKit from './pages/UIKit/UIKit'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { APIProvider } from '@vis.gl/react-google-maps'

import './i18n'
import LoginScreen from './pages/Auth/LoginScreen/LoginScreen'
import RegistrationScreen from './pages/Auth/RegisterScreen/RegistrationScreen'
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/ForgotPassword'
import RegistrationSocialScreen from './pages/Auth/RegisterSocialScreen/RegistrationSocialScreen'
import VerifyEmailScreen from './pages/Auth/VerifyEmailScreen/VerifyEmailScreen'
import HomeScreen from './pages/HomeScreen/HomeScreen'
import { useEffect, useState } from 'react'
import { initFBSDK } from './utils/FBSDK'
import ChatsScreen from './pages/Chats/ChatsScreen/ChatsScreen'
import ScanScreen from './pages/ScanScreen/ScanScreen'
import MapScreen from './pages/Map/MapScreen/MapScreen'
import ProfileScreen from './pages/ProfileScreen/ProfileScreen'
import PromoScreen from './pages/Promo/PromoScreen/PromoScreen'
import CreatePostScreen from './pages/CreatePostScreen/CreatePostScreen'
import CommentsScreen from './pages/CommentsScreen/CommentsScreen'
import ProfileAbout from './pages/Profile/ProfileAbout/ProfileAbout'
import ProfileDashboard from './pages/Profile/ProfileDashboard/ProfileDashboard'
import ProfileTransactions from './pages/Profile/ProfileTransactions/ProfileTransactions'
import MapPage from './pages/Map/MapPage/MapPage'
import MapRanking from './pages/Map/MapRanking/MapRanking'
import ChangeEmail from './pages/ChangeEmail/ChangeEmail'
import dayjs from 'dayjs'
import { IPosition } from './types/map.interface'
import { getGeoPosition } from './utils/geo'
import EditProfileScreen from './pages/EditProfileScreen/EditProfileScreen'
import ThanksScreen from './pages/ThanksScreen/ThanksScreen'
import TakePhotos from './pages/TakePhotos/TakePhotos'
import VerifyPhoneScreen from './pages/VerifyPhoneScreen/VerifyPhoneScreen'
import ChangePassword from './pages/ChangePassword/ChangePassword'
import PromoDetails from './pages/Promo/PromoDetails/PromoDetails'
import Chat from './pages/Chats/Chat/Chat'
import RestrictionScreen from './pages/RestrictionScreen/RestrictionScreen'
import SommelierLevelScreen from './pages/SommelierLevelScreen/SommelierLevelScreen'
import PromoRules from './pages/Promo/PromoRules/PromoRules'
import { isInStandaloneMode } from './utils/pwa'
import { socketChat } from './services/socket'

import { isMobileOnly } from 'react-device-detect'
import StoreScreen from './pages/StoreScreen/StoreScreen'
import ProfilePosts from './pages/Profile/ProfilePosts/ProfilePosts'
import NotificationsScreen from './pages/Notifications/NotificationsScreen/NotificationsScreen'
import ProfileFollow from './pages/Profile/ProfileFollow/ProfileFollow'

import { useInterval } from 'usehooks-ts'
import axiosInstance from './api/config'
import SelectCategoryScreen from './components/SelectCategoryScreen/SelectCategoryScreen'
import { socketBalance } from './services/socket_balance'
import { socketWithdrawal } from "./services/socket_withdrawal"
import {IPromo} from "./types/promo.interface";

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

Modal.setAppElement('#root')

const GEO_DELAY = 180000

const OnlyForAuthorized = () => {
  const notMobileSize = window.innerWidth > 768
  const { isAuthorized } = useSelector((state: any) => state.auth)
  const { profile } = useSelector((state: any) => state.profile)

  if (!isAuthorized) {
    return <Navigate to="/login" replace />
  }

  if (profile && !profile?.category) {
    return <SelectCategoryScreen />
  }

  if (
    process.env.REACT_APP_DEV_MODE === 'false' &&
    (!isInStandaloneMode() || !isMobileOnly || notMobileSize)
  ) {
    return <RestrictionScreen />
  }

  if (isAuthorized && process.env.REACT_APP_IS_BETA === 'true') {
    return <ThanksScreen />
  }

  return <Outlet />
}

const OnlyForNonAuthorized = () => {
  const [searchParams] = useSearchParams()
  const { isAuthorized } = useSelector((state: any) => state.auth)

  useEffect(() => {
    const refId = searchParams.get('k')

    if (refId) {
      sessionStorage.setItem('refId', refId)
    }
  }, [])

  if (isAuthorized) {
    return <Navigate to="/home" replace />
  }

  return <Outlet />
}

const AppWrapper = ({ children }: any) => {
  const { isAuthorized } = useSelector((state: any) => state.auth)
  const { profile } = useSelector((state: any) => state.profile)
  const { promos } = useSelector((state: any) => state.promo)
  const { dispatch } = store
  const [isDocumentHidden, setIsDocumentHidden] = useState(false)
  const [isSending, setIsSending] = useState(false)

  useInterval(
    async () => {
      await sendGeo()
    },
    !isDocumentHidden && isAuthorized ? GEO_DELAY : null,
  )

  const fetchPromo = async (page: number = 1) => {
    try {
      const { data } =
        await axiosInstance.get('/api/promos', {
          params: {
            page,
          },
        });

      dispatch({
        type: 'promo/setPromo',
        payload: {
          promos:
            page === 1
              ? data?.data[0]?.entities || []
              : [...promos, ...(data?.data[0]?.entities || [])],
          pagination: {
            page: data?.data[0]?.page || 1,
            pages: data?.data[0]?.pages || 1,
            countItems: data?.data[0]?.countItems || 0,
          },
        },
      })
    } catch(e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchPromo()
  }, [])

  const sendFinishedPromo = async (id: string) => {
    await axiosInstance.post(`api/promos/promo-finished/${id}`)
  }

  useEffect(() => {
    if (promos?.length) {
      promos?.map((item: IPromo) => {
        if (item.winners?.length && !item?.isFinished) {
          sendFinishedPromo(item?._id)
        }
      })
    }
  }, [promos]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setIsDocumentHidden(true)
    } else {
      setIsDocumentHidden(false)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (isAuthorized) {
      if (!profile) {
        dispatch({ type: 'profile/getProfile' })
      }

      setTimeout(() => {
        sendGeo()
      }, 5000)

      sessionStorage.removeItem('refId')

      socketChat.initSocket()
      socketBalance.initSocket()
      socketWithdrawal.initSocket()
    }
  }, [isAuthorized])

  const sendGeo = async () => {
    try {
      setIsSending(true)
      const pos: IPosition = await getGeoPosition()

      if (!!pos && !isSending) {
        try {
          await axiosInstance.post('/api/users/location', {
            location: [pos.lat, pos.lng],
          })

          setIsSending(false)
          dispatch({ type: 'profile/setPosition', payload: pos })
        } catch (e: any) {}
      }
    } catch {
      setIsSending(false)
      dispatch({ type: 'geo/setIsEnabled', payload: false })
    }
  }

  return children
}

function App() {
  useEffect(() => {
    initFBSDK()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', calcAppHeight)

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', calcAppHeight)
    }

    calcAppHeight()
    socketChat.initSocket()

    return () => {
      // socketChat.closeSocket()
      window.removeEventListener('resize', calcAppHeight)

      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', calcAppHeight)
      }
    }
  }, [])

  const calcAppHeight = () => {
    const doc = document.documentElement

    let height = window.visualViewport?.height || window.innerHeight
    doc.style.setProperty('--app-height', `${height}px`)
    document.body.style.height = `${height}px`
  }

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ''}
        language="en"
      >
        <Provider store={store}>
          <AppWrapper>
            <BrowserRouter>
              <Routes>
                <Route element={<OnlyForAuthorized />}>
                  <Route path="/home" element={<HomeScreen />} />
                  <Route path="/home/promo" element={<PromoScreen />} />
                  <Route path="/home/promo/:id" element={<PromoDetails />} />
                  <Route
                    path="/home/promo/:id/rules"
                    element={<PromoRules />}
                  />
                  <Route path="/create-post" element={<CreatePostScreen />} />
                  <Route
                    path="/post/:id/comments"
                    element={<CommentsScreen />}
                  />
                  <Route path="/chats" element={<ChatsScreen />} />
                  <Route path="/chats/:id" element={<Chat />} />
                  <Route path="/scan" element={<ScanScreen />} />
                  <Route path="/map" element={<MapScreen />}>
                    <Route index element={<MapPage />} />
                    <Route path="/map/ranking" element={<MapRanking />} />
                  </Route>
                  <Route path="/edit-profile" element={<EditProfileScreen />} />
                  <Route path="/profile/:id" element={<ProfileScreen />}>
                    <Route index element={<ProfileAbout />} />
                    <Route
                      path="/profile/:id/dashboard"
                      element={<ProfileDashboard />}
                    />
                    <Route
                      path="/profile/:id/transactions"
                      element={<ProfileTransactions />}
                    />
                    <Route
                      path="/profile/:id/posts"
                      element={<ProfilePosts />}
                    />
                    <Route
                      path="/profile/:id/followers"
                      element={<ProfileFollow />}
                    />
                    <Route
                      path="/profile/:id/following"
                      element={<ProfileFollow />}
                    />
                  </Route>
                  <Route path="/store/:id" element={<StoreScreen />} />
                  <Route
                    path="/notifications"
                    element={<NotificationsScreen />}
                  ></Route>
                </Route>
                <Route path="/take-photo" element={<TakePhotos />} />

                <Route element={<OnlyForNonAuthorized />}>
                  <Route path="/" element={<SplashScreen />} />
                  <Route path="/login" element={<LoginScreen />} />
                  <Route
                    path="/registration"
                    element={<RegistrationScreen />}
                  />
                  <Route
                    path="/registration/verify"
                    element={<VerifyEmailScreen />}
                  />
                  <Route
                    path="/social-registration"
                    element={<RegistrationSocialScreen />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordScreen />}
                  />
                </Route>
                <Route path="/change-email" element={<ChangeEmail />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/verify-phone" element={<VerifyPhoneScreen />} />
                <Route
                  path="/sommelier-level"
                  element={<SommelierLevelScreen />}
                />
                <Route path="/ui" element={<UIKit />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={
                        store.getState().auth.isAuthorized ? '/home' : '/login'
                      }
                      replace
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </AppWrapper>
        </Provider>
      </APIProvider>
    </GoogleOAuthProvider>
  )
}

export default App
