import { ReactComponent as Success } from "@assets/icons/success.svg";
import styles from './ChangeEmail.module.scss'
import { useTranslation } from "react-i18next";

export default function ChangeEmailSuccess() {
  const { t } = useTranslation()

  return (
    <div className={styles.success}>
      <div className={styles.success__icon}>
        <Success />
      </div>
      <div className={styles.success__title}>{t('verifyEmail.success')}</div>
      <div className={styles.success__subtitle}>{t('verifyEmail.email_changed')}</div>
    </div>
  )
}