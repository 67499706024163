import styles from './ForgotPasswordSetPasswordForm.module.scss'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import Input from '../../../Input/Input'
import { PASSWORD_REGEX } from '../../../../constants/validation'
import axiosInstance from '../../../../api/config'
import ReCAPTCHA from 'react-google-recaptcha'

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .matches(PASSWORD_REGEX, i18next.t('validation.invalid_password')),
  confirmPassword: Yup.string()
    .required(i18next.t('validation.required'))
    .trim()
    .test(
      'passwords-match',
      i18next.t('validation.password_match'),
      function (value) {
        const { password } = this.parent

        return value === password
      },
    ),
})

interface IProps {
  ref?: any
  nextStep: () => void
  code: string
}
interface IForgotPasswordBody {
  password: string
  repeatPassword: string
  onlyCode: boolean
  code: string
}

const ForgotPasswordSetPasswordForm = forwardRef(
  ({ code, nextStep }: IProps, ref) => {
    const { t } = useTranslation()
    const recaptchaRef = useRef<any>(null)

    const [isPending, setIsPending] = useState(false)
    const [serverError, setServerError] = useState('')

    const formik = useFormik({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      validationSchema: SignupSchema,
      onSubmit: async (values: any) => {
        if (isPending) return

        await submitForm(values)
      },
    })

    const submitForm = async (values: any) => {
      setIsPending(true)
      setServerError('')

      try {
        let token = ''

        if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
          recaptchaRef.current?.reset()
          token = await recaptchaRef.current?.executeAsync()
        }

        const body: IForgotPasswordBody = {
          password: values.password.trim().replaceAll(/\s/g,''),
          repeatPassword: values.confirmPassword.trim().replaceAll(/\s/g,''),
          onlyCode: false,
          code: code.replaceAll(/\s/g,''),
        }
        await axiosInstance.post(`/api/auth/forgotten/verify`, body, {
          headers: {
            recaptcha: token,
          },
        })

        setIsPending(false)
        nextStep()
      } catch (e: any) {
        setServerError(e?.response?.data?.data?.message || '')
        setIsPending(false)
      }
    }

    return (
      <form
        ref={ref as any}
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.form__row}>
          <Input
            id="password"
            name="password"
            placeholder={t('resetPasswordScreen.password_placeholder')}
            label={t('registerScreen.password_label')}
            type="password"
            value={formik.values.password.trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.password}
            isPreventScroll={true}
            isError={formik.touched.password && Boolean(formik.errors.password)}
          />
        </div>
        <div className={styles.form__row}>
          <Input
            id="confirmPassword"
            name="confirmPassword"
            placeholder={t('resetPasswordScreen.password_placeholder')}
            label={t('registerScreen.confirm_password_label')}
            type="password"
            value={formik.values.confirmPassword.trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.confirmPassword}
            isPreventScroll={true}
            isError={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
          />
        </div>
        <div className={styles.form__errorContainer}>
          {serverError && <p className="form__error">{serverError}</p>}{' '}
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        />
      </form>
    )
  },
)

export default ForgotPasswordSetPasswordForm
