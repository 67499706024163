import { IMAGE_URL } from '../../../constants'
import Avatar from '../../Avatar/Avatar'
import styles from './Sommerlier.module.scss'

interface IProps {
  avatar: string
  isLoadingImage?: boolean
}

export default function SommelierMarker({ avatar, isLoadingImage }: IProps) {
  return (
    <div className={styles.marker}>
      <Avatar isLoading={isLoadingImage} url={avatar ? `${IMAGE_URL}/${avatar}` : ''} size={30} />
    </div>
  )
}
