import { ReactElement } from 'react'
import styles from './BaseToast.module.scss'

interface IProps {
  message: string
  icon: ReactElement
}

export default function BaseToast({ message, icon }: IProps) {
  return (
    <div className={styles.block}>
      <div className={styles.block__iconContainer}>{icon}</div>
      <div className={styles.block__message__success}>{message}</div>
    </div>
  )
}
