import styles from './ChangeImage.module.scss'
import { useTranslation } from "react-i18next";

import ModalContainer from "../../ModalContainer/ModalContainer"

interface IProps {
  open: boolean
  onClose: () => void
  onAddImage: () => void
  onOpenCamera: () => void
  type: string
}

export default function ChangeImage({ open, onClose, onAddImage, onOpenCamera, type }: IProps) {
  const { t } = useTranslation()
  const isBackground = type === 'background'

  return (
    <ModalContainer isOpen={open} onClose={onClose}>
      <div className={styles.background}>
        <div className={styles.background__title}>{isBackground ? t('editProfileScreen.change_background') : t('editProfileScreen.change_avatar')}</div>
        <button onClick={onOpenCamera} className="button">{t('editProfileScreen.take_photo')}</button>
        <button onClick={onAddImage} className="button button--outline">{t('editProfileScreen.add_photo')}</button>
      </div>
    </ModalContainer>
  )
}