export enum ELevel {
  STANDARD = 'Standard',
  MAGNUM = 'Magnum',
  JEROBOAM = 'Jeroboam',
  REHOBOAM = 'Rehoboam',
  METHUSELAH = 'Methuselah',
  SALMANAZAR = 'Salmanazar',
  BALTHAZAR = 'Balthazar',
  NEBUCHADNEZZAR = 'Nebuchadnezzar',
  MELCHIOR = 'Melchior',
  SOLOMON = 'Solomon',
}

export const levelColor: any = {
  [ELevel.STANDARD]: '#F61D0E',
  [ELevel.MAGNUM]: '#F8601A',
  [ELevel.JEROBOAM]: '#F89E18',
  [ELevel.REHOBOAM]: '#F6CC18',
  [ELevel.METHUSELAH]: '#A8C015',
  [ELevel.SALMANAZAR]: '#63B43D',
  [ELevel.BALTHAZAR]: '#1DA0A6',
  [ELevel.NEBUCHADNEZZAR]: '#2C7EAD',
  [ELevel.MELCHIOR]: '#4F58A7',
  [ELevel.SOLOMON]: '#995BA7',
}
