import styles from './RegistrationSocialForm.module.scss'
import Input from '../../Input/Input'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef } from 'react'
import Checkbox from '../../Checkbox/Checkbox'
import CountrySelect from '../../CountrySelect/CountrySelect'
import { useLocation } from 'react-router-dom'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t('validation.invalid_email'))
    .required(i18next.t('validation.required'))
    .trim(),
  country: Yup.string().required().trim(),
})

interface IProps {
  ref?: any
}

const RegistrationSocialForm = forwardRef(({}: IProps, ref) => {
  const { t } = useTranslation()
  const location = useLocation()

  const email = location.state?.email || ''
  const country = location.state?.country || ''

  const formik = useFormik({
    initialValues: {
      email,
      country,
    },
    validationSchema: SignupSchema,
    onSubmit: async (values: any) => {
      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    console.log('SUBMIT', values)
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form__row}>
        <Input
          id="email"
          name="email"
          type="text"
          placeholder={t('registerSocialScreen.email_placeholder')}
          label={t('registerSocialScreen.email_label')}
          value={formik.values.email.trim()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          isError={formik.touched.email && Boolean(formik.errors.email)}
          disabled={true}
          requiredFlag={true}
        />
      </div>
      <div className={styles.form__row}>
        <CountrySelect
          label={t('registerSocialScreen.country_label')}
          defaultValue={country}
          requiredFlag={true}
        />
      </div>
      <p className={styles.form__message}>
        {t('registerSocialScreen.message')}
      </p>
      <div className={styles.form__checkbox}>
        <Checkbox>
          <p>
            I agree with <a href="#1">Terms</a> &{' '}
            <a href="#2">Privacy Policy</a>{' '}
          </p>
        </Checkbox>
      </div>
    </form>
  )
})

export default RegistrationSocialForm
