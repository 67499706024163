import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { store } from "../../store";
import { useSelector } from 'react-redux'

import DashboardChart from "../DashboardChart/DashboardChart";
import axiosInstance from "../../api/config";
import { IProfit, IWinesSale } from "../../types/profile.interface";

export default function DashboardChartWrapper() {
  const { t } = useTranslation()
  const { dispatch } = store

  const [keysProfit, setKeysProfit] = useState<string[]>([])
  const [valuesProfit, setValuesProfit] = useState<number[]>([])

  const [keysWines, setKeysWines] = useState<string[]>([])
  const [valuesWines, setValuesWines] = useState<number[]>([])

  const { profit, winesSales } = useSelector((state: any) => state.profile)

  const getDataProfit = async (typeTitle: string, type: number, range: string) => {
    const response = await axiosInstance.get('/api/users/profile/sale-amounts', { params: { type: typeTitle !== t('dashboard.all_uppercase') ? type : undefined, dateRange: range}})

    dispatch({ type: 'profile/getProfit', payload: response.data.data })
  }

  const getDataSaleOfWines = async (typeTitle: string, type: number, range: string) => {
    const response = await axiosInstance.get('/api/users/profile/sale-quantities', { params: { type: typeTitle !== t('dashboard.all_uppercase') ? type : undefined, dateRange: range}})

    dispatch({ type: 'profile/getWinesSales', payload: response.data.data })
  }

  useEffect(() => {
    if (profit?.length) {
      const keys = profit?.map((item: IProfit) => item.date)
      setKeysProfit(keys)
      const values = profit?.map((item: IProfit) => item.totalAmount)
      setValuesProfit(values)
    } else {
      setKeysProfit([])
      setValuesProfit([])
    }
  }, [profit])

  useEffect(() => {
    if (winesSales?.length) {
      const keys = winesSales?.map((item: IWinesSale) => item.date)
      setKeysWines(keys)
      const values = winesSales?.map((item: IWinesSale) => item.saleCount)
      setValuesWines(values)
    } else {
      setKeysWines([])
      setValuesWines([])
    }
  }, [winesSales])

  return (
    <>
      <DashboardChart types="profit" keys={keysProfit} values={valuesProfit} getData={getDataProfit} title={t('dashboard.profit')} />
      <DashboardChart keys={keysWines} values={valuesWines} getData={getDataSaleOfWines} title={t('dashboard.wine_sale')} />
    </>
  )
}