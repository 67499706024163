import styles from './VerifyEmailForm.module.scss'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import i18next from 'i18next'
import { useFormik } from 'formik'
import { forwardRef, useRef, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import axiosInstance from '../../../api/config'
import { useNavigate } from 'react-router-dom'
import { store } from '../../../store'
import ReCAPTCHA from 'react-google-recaptcha'
import CodeVerify from "../../CodeVerify/CodeVerify";

const CodeSchema = Yup.object().shape({
  code: Yup.string().required(i18next.t('validation.required')).trim(),
})

interface IProps {
  ref?: any
  email: string
  password: string
}

interface IVerifyEmailBody {
  code: string
}

const EXPIRED_TIME = 60 * 5

const VerifyEmailForm = forwardRef(({ email, password }: IProps, ref) => {
  const { dispatch } = store
  const navigate = useNavigate()
  const recaptchaRef = useRef<any>(null)

  const [isCanResend, setIsCanResend] = useState<boolean>(false)
  const [isPending, setIsPending] = useState(false)
  const [serverError, setServerError] = useState('')

  const expiryTimestamp = new Date()
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRED_TIME)

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsCanResend(true),
  })

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: CodeSchema,
    onSubmit: async (values: any) => {
      if (isPending) return

      await submitForm(values)
    },
  })

  const submitForm = async (values: any) => {
    setIsPending(true)

    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body: IVerifyEmailBody = {
        code: values.code.replaceAll(/\s/g,''),
      }

      const response = await axiosInstance.post(
        '/api/auth/register/verify',
        body,
        {
          headers: {
            recaptcha: token,
          },
        },
      )

      setIsPending(false)
      dispatch({ type: 'auth/login', payload: response.data.data.token })
      navigate('/home')
    } catch (e: any) {
      setServerError(e?.response?.data?.data?.message || '')
      setIsPending(false)
    }
  }

  const resendCode = async () => {
    try {
      let token = ''

      if (process.env.REACT_APP_WITH_CAPTCHA === 'true') {
        recaptchaRef.current?.reset()
        token = await recaptchaRef.current?.executeAsync()
      }

      const body = {
        email,
        password,
      }

      await axiosInstance.post('/api/auth/register/resend', body, {
        headers: {
          recaptcha: token,
        },
      })
      const expiryTimestamp = new Date()
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + EXPIRED_TIME)

      restart(expiryTimestamp)
      setIsCanResend(false)
    } catch (e: any) {}
  }

  return (
    <form
      ref={ref as any}
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <CodeVerify
        resendCode={resendCode}
        isCanResend={isCanResend}
        serverError={serverError}
        setIsCanResend={setIsCanResend}
        formik={formik}
        seconds={seconds}
        minutes={minutes}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      />
    </form>
  )
})

export default VerifyEmailForm
