import { useTranslation } from 'react-i18next'
import styles from './ChatImageViewer.module.scss'
import { ReactComponent as BackIcon } from '@assets/icons/chevron.svg'

interface IProps {
  onClose: () => void
  onSend: () => void
  image: string
}

export function ChatImageViewer({ image, onClose, onSend }: IProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <div className={styles.block__container}>
        <button className={styles.block__back} type="button" onClick={onClose}>
          <BackIcon />
        </button>

        <div className={styles.block__gradient_1}></div>
        <div className={styles.block__gradient_2}></div>
        <button
          className={`button button--white ${styles.block__button}`}
          type="button"
          onClick={onSend}
        >
          {t('chat.send')}
        </button>

        <div className={styles.block__imageContainer}>
          <img className={styles.block__image} src={image} alt="Chat" />
        </div>
      </div>
    </div>
  )
}
