import { ReactComponent as ArrowIcon } from '@assets/icons/chevron.svg'
import styles from './AuthHeader.module.scss'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IProps {
  title?: string
  description?: string | ReactNode
  isMinimized?: boolean
  navigateOnBack?: boolean
  onClickBack?: () => void
  step?: number
  confirmation?: boolean
}

export default function AuthHeader({
  title,
  description,
  isMinimized = false,
  navigateOnBack = true,
  onClickBack = () => {},
  step = 2,
  confirmation = false
}: IProps) {
  const location = useLocation()
  const navigate = useNavigate()

  const back = () => {
    if (!navigateOnBack) {
      onClickBack()

      return
    }

    if (location.key === 'default') navigate('/')
    else navigate(-1)
  }

  const setStep = () => {
    if (step === 0 && confirmation) {
      return 'Step 1'
    } else if (step === 0 && !confirmation) {
      return 'Step 2'
    } else {
      return 'Step 3'
    }
  }

  return (
    <div
      className={`${styles.block} ${isMinimized ? styles['block--min'] : ''}`}
    >
      <div className={styles.block__step}>
        <button className={styles.block__button} onClick={back}>
          <ArrowIcon />
        </button>
        {step !== 2 && (
          <div>{setStep()}</div>
        )}
      </div>
      <div className={styles.block__title}>{title}</div>
      {!isMinimized && (
        <p className={styles.block__description}>{description}</p>
      )}
    </div>
  )
}
