import { Link } from 'react-router-dom'
import ModalContainer from '../../ModalContainer/ModalContainer'
import styles from './GeoRestrictedModal.module.scss'
import { useTranslation } from 'react-i18next'

export default function GeoRestrictedModal() {
  const { t } = useTranslation()

  return (
    <ModalContainer isOpen={true} onClose={() => {}}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>{t('map.geo_modal_title')}</p>
        <p className={styles.modal__description}>
          {t('map.geo_modal_description')}
        </p>
        <Link to="/home" className="button">
          OK
        </Link>
      </div>
    </ModalContainer>
  )
}
