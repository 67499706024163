import { ReactNode } from 'react'
import Modal from 'react-modal'

interface IProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  afterOpenModal?: () => void
}

export default function ModalContainer({
  children = null,
  isOpen = false,
  onClose = () => {},
  afterOpenModal = () => {},
}: IProps) {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
    >
      {children}
    </Modal>
  )
}
